import React from 'react';
import PropTypes from 'prop-types';

export default function WrapByDataAttributes(props) {
  return <div {...props.dataAttributes}>{props.children}</div>;
}

WrapByDataAttributes.propTypes = {
  dataAttributes: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }).isRequired,
  children: PropTypes.element.isRequired,
};
