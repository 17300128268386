import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { ListGroupStyles } from '@vfuk/core-list-group'
import ListItem from '@vfuk/core-list-item'
import { advancedSpacing, typography } from '@vfuk/core-mixins'
import Interaction from '@vfuk/core-interaction'

import type { StyledPrimaryNavProps } from './PrimaryNav.style.types'

type LinkProps = Pick<StyledPrimaryNavProps, 'theme' | 'selected' | 'animationDuration' | 'appearance' | 'topNavigationTheme'>
export const PrimaryNavLink = styled(Interaction)<StyledPrimaryNavProps>(
  (props: LinkProps): FlattenSimpleInterpolation => css`
    ${advancedSpacing('padding', [1, 0])};
    position: relative;
    transition: ${props.animationDuration / 1000}s all ease;
    overflow: hidden;
    color: ${props.topNavigationTheme.appearance[props.appearance].mainBar.linkColor};

    &::before {
      transition: ${props.animationDuration / 1000}s all ease;
      content: '';
      width: 100%;
      height: ${props.theme.border.width[2]};
      background: ${props.topNavigationTheme.appearance[props.appearance].mainBar.activeBorderColor};
      display: block;
      position: absolute;
      top: calc(100% - ${props.theme.border.width[2]});
      left: 0;
      transform: translateX(-100%);
    }

    ${props.selected &&
    css`
      &::before {
        transform: translateX(0);
      }

      font-weight: ${props.theme.fontWeight[3]};
    `}
  `,
)

type ItemProps = Pick<StyledPrimaryNavProps, 'theme' | 'text'>
export const PrimaryNavItem = styled(ListItem)<ItemProps>(
  (props: ItemProps): FlattenSimpleInterpolation => css`
    ${advancedSpacing('margin', [0, 4])};
    text-align: center;
    position: relative;

    &::after {
      display: block;
      content: '${props.text}';
      font-weight: ${props.theme.fontWeight[3]};
      color: transparent;
      overflow: hidden;
      visibility: hidden;
      height: 0;

      @media speech {
        display: none;
      }
    }
  `,
)

export const PrimaryNav = styled.div(
  (): FlattenSimpleInterpolation => css`
    ${typography.heading(1, false)};

    ${ListGroupStyles.ListGroup} {
      display: flex;
    }
  `,
)

export const NumberBadgeWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    position: absolute;
    left: 100%;
    bottom: 100%;
    transform: translate(0%, 75%);
  `,
)
