import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { TextInputWithLabel } from 'r10-source-library';

import {
  updatePhoneNumber,
  updatePhoneNumberErrorStatus,
  updatePhoneNumberNotFoundStatus,
} from '../../../actions/Actions';
import useTranslation from '../../../hooks/useTranslation';
import {
  phoneNumberErrorStatusSelector,
  phoneNumberNotFoundSelector,
  phoneNumberSelector,
  propSelector,
} from '../../../selectors/selector';

const NumberInput = ({
  validationRegex,
  disable,
  phoneNumber,
  phoneNumberErrorStatus,
  phoneNumberNotFound,
  updatePhoneNum,
  updatePhoneNumErrorStatus,
  updatePhoneNumNotFoundStatus,
  phoneNumberLength,
}) => {
  const TEXT_INPUT_ID = 'number-input';
  const t = useTranslation();
  useEffect(() => {
    const input = document.getElementById(TEXT_INPUT_ID);
    input.maxLength = phoneNumberLength;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnChange = useCallback(
    newValue => {
      updatePhoneNumErrorStatus(null);
      updatePhoneNumNotFoundStatus(null);
      updatePhoneNum(newValue.target.value.replace(/[\D]/g, ''));
    },
    [updatePhoneNum, updatePhoneNumErrorStatus, updatePhoneNumNotFoundStatus]
  );

  const handleOnBlur = useCallback(() => {
    if (!phoneNumber) updatePhoneNumErrorStatus(null);

    const isPhoneNumberValid = phoneNumber.match(validationRegex);
    if (phoneNumber && !isPhoneNumberValid) {
      updatePhoneNumErrorStatus(true);
    }
    if (phoneNumber && isPhoneNumberValid) {
      updatePhoneNumErrorStatus(false);
    }
  }, [phoneNumber, validationRegex, updatePhoneNumErrorStatus]);

  const phoneNumberStatus = () => {
    if (phoneNumberErrorStatus === true || phoneNumberNotFound === true) return 'error';
    if (phoneNumberErrorStatus === false || phoneNumberNotFound === false) return 'success';

    return '';
  };

  const phoneNumberErrorMessage = () => {
    if (phoneNumberErrorStatus) return t('bodyTopupInput');
    if (phoneNumberNotFound) return t('phoneNumberIsNotFound');
    return '';
  };

  return (
    <TextInputWithLabel
      state={phoneNumberStatus()}
      fieldWrapper={{
        stateText: phoneNumberErrorMessage(),
        width: 'full',
      }}
      textInput={{
        id: TEXT_INPUT_ID,
        value: phoneNumber,
        placeholder: t('bodyTopupTextfield'),
        onChange: handleOnChange,
        onBlur: handleOnBlur,
        disabled: disable,
      }}
    />
  );
};
const mapStateToProps = state => ({
  phoneNumber: phoneNumberSelector(state),
  phoneNumberErrorStatus: phoneNumberErrorStatusSelector(state),
  phoneNumberNotFound: phoneNumberNotFoundSelector(state),
  phoneNumberLength: propSelector(state, 'phoneNumberLength'),
});
const mapDispatchToProps = dispatch => ({
  updatePhoneNum: newPhoneNumber => dispatch(updatePhoneNumber(newPhoneNumber)),
  updatePhoneNumErrorStatus: isError => dispatch(updatePhoneNumberErrorStatus(isError)),
  updatePhoneNumNotFoundStatus: isError => dispatch(updatePhoneNumberNotFoundStatus(isError)),
});

NumberInput.propTypes = {
  validationRegex: PropTypes.any,
  disable: PropTypes.bool,
  phoneNumber: PropTypes.string,
  phoneNumberErrorStatus: PropTypes.bool,
  phoneNumberNotFound: PropTypes.bool,
  updatePhoneNum: PropTypes.func,
  phoneNumberLength: PropTypes.number,
  updatePhoneNumErrorStatus: PropTypes.func,
  updatePhoneNumNotFoundStatus: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(NumberInput);
