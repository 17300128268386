import { name } from '../package.json';

export const pkgName = name;
export const REGEX = /^[67][1-9]{8}$/g;

export const SCREENS = {
  TOP_UP: 'TOP_UP',
  SUCCESS: 'SUCCESS',
  GenericError: 'GenericError',
  CONFIRM: 'CONFIRM',
  PAYMENT_METHOD_SELECTION: 'PAYMENT_METHOD_SELECTION',
  PAYMENT_METHOD_CONFIRM: 'PAYMENT_METHOD_CONFIRM',
  LOADING: 'LOADING',
  LOW_STORE_BUCKET: 'LOW_STORE_BUCKET',
};

export const serviceDetailsHeaderWidget = 'service-details-header';
