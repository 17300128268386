import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ModalContent } from 'r10-common-components';

import useTranslation from '../../../hooks/useTranslation';
import { setStoreBucket, updateCurrentScreen } from '../../../actions/Actions';
import { SCREENS } from '../../../appConstants';
import { utils } from 'r10-common-components';

const LowStoreBucket = ({ storeBucket, updateCurrtScreen, dispatchsetStoreBucket }) => {
  const { money, currencySymbol } = utils.formatMoney(storeBucket?.value, storeBucket?.unit, true);
  useEffect(() => {
    return () => {
      dispatchsetStoreBucket(null);
    };
  }, []);

  const t = useTranslation();
  return (
    <ModalContent
      header={t('headerTopUp')}
      icon="warning"
      title={t('lowStoreBucket')}
      titleTestId="low-store-bucket"
      subtitles={[
        { regular: t('storeBucketSubtitle'), regularTestId: 'low-store-bucket-subtitle' },
        ...(storeBucket !== null
          ? [
              {
                regular: `${t('storeBucket')}: `,
                regularTestId: 'low-store-bucket-label',
                bold: `${money}${currencySymbol || ''}`,
                boldTestId: 'low-store-bucket-amount',
                marginBottom: 9,
              },
            ]
          : [{}]),
      ]}
      primaryButton={{
        text: t('failureTryAgainButton'),
        onClick: () => updateCurrtScreen(SCREENS.TOP_UP),
        dataTestId: 'backToTopUp-button',
      }}
    />
  );
};

const mapDispatchToProps = dispatch => ({
  updateCurrtScreen: currentScreen => dispatch(updateCurrentScreen(currentScreen)),
  dispatchsetStoreBucket: () => dispatch(setStoreBucket()),
});
LowStoreBucket.propTypes = {
  storeBucket: PropTypes.number,
  updateCurrtScreen: PropTypes.func,
  dispatchsetStoreBucket: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(LowStoreBucket);
