import type { Theme } from '@vfuk/core-themes'

import type { TopNavigationTheme } from './TopNavigation.theme.types'

const defaultTheme = (theme: Theme): TopNavigationTheme => {
  return {
    appearance: {
      primary: {
        topBar: {
          backgroundColor: theme.color.monochrome6.default,
          links: {
            color: theme.inverseColor.monochrome6.default,
            iconAppearance: 'primary',
            inverseIcon: true,
            backgroundColor: 'none',
            dividerAppearance: 'secondary',
            inverseDivider: true,
            active: {
              color: theme.color.monochrome6.default,
              iconAppearance: 'primary',
              inverseIcon: false,
              backgroundColor: theme.color.monochrome1.default,
              dividerAppearance: 'secondary',
              inverseDivider: false,
            },
            subLink: {
              color: theme.color.monochrome6.default,
              iconAppearance: 'primary',
              inverseIcon: false,
              backgroundColor: theme.color.monochrome2.default,
              dividerAppearance: 'secondary',
              inverseDivider: false,
            },
          },
        },
        mainBar: {
          backgroundColor: theme.color.monochrome1.default,
          linkColor: theme.color.monochrome6.default,
          activeBorderColor: theme.color.primary1.default,
          inverseLogo: false,
          inverseText: false,
          interactiveIconAppearance: 'primary',
          iconAppearance: 'primary',
          inverseIcon: false,
          numberBadgeAppearance: 'primary',
        },
        sideTray: {
          closeButtonAppearance: 'secondary',
          activeBorderColor: theme.color.primary1.default,
          level: {
            1: {
              backgroundColor: theme.color.monochrome1.default,
              textColor: theme.color.monochrome6.default,
              iconAppearance: 'secondary',
              inverseIcon: false,
              numberBadgeAppearance: 'primary',
              linkWithIconAppearance: 'primary',
              linkAppearance: 'primary',
              inverseLink: false,
            },
            2: {
              backgroundColor: theme.color.monochrome2.default,
              textColor: theme.color.monochrome6.default,
              iconAppearance: 'primary',
              inverseIcon: false,
              numberBadgeAppearance: 'primary',
              linkWithIconAppearance: 'secondary',
              linkAppearance: 'primary',
              inverseLink: false,
            },
          },
        },
      },
    },
  }
}

export default defaultTheme
