/* eslint-disable complexity, sonarjs/cognitive-complexity */
import { useEffect } from 'react'

import useKeyPress from '../useKeyPress'

import focusOnElement from '../../helpers/focusOnElement'
import backwardNavigation from '../../helpers/backwardNavigation'

import type { TopNavigationContextProps } from '../../context/TopNavigationContext.types'

function setFocus(
  logo: TopNavigationContextProps['logo'],
  navLevel: TopNavigationContextProps['navLevel'],
  setNavLevel: TopNavigationContextProps['setNavLevel'],
  showSearch: TopNavigationContextProps['showSearch'],
  setShowSearch: TopNavigationContextProps['setShowSearch'],
  activeNav: TopNavigationContextProps['activeNav'],
  activeSubNav: TopNavigationContextProps['activeSubNav'],
  sideNavigationCloseButton: TopNavigationContextProps['sideNavigationCloseButton'],
  navTree: TopNavigationContextProps['navTree'],
  setNavTree: TopNavigationContextProps['setNavTree'],
  setIsTransitioning: TopNavigationContextProps['setIsTransitioning'],
  setActiveSubNav: TopNavigationContextProps['setActiveSubNav'],
  animationDuration: TopNavigationContextProps['animationDuration'],
  secondaryNav: TopNavigationContextProps['secondaryNav'],
): void {
  const keyPress = useKeyPress()

  useEffect(() => {
    if (activeNav) {
      focusOnElement(activeNav.links[0].id)
    }
  }, [activeNav])

  useEffect(() => {
    if (activeSubNav) {
      focusOnElement(activeSubNav.links[0].id)
    }
  }, [activeSubNav])

  useEffect(() => {
    if (!keyPress) return

    // Reset everything on Escape
    if (keyPress.key === 'Escape') {
      focusOnElement(logo.id)

      if (showSearch) {
        setNavLevel(0)
        setShowSearch(false)
        setTimeout(() => {
          focusOnElement(secondaryNav!.search!.id)
        }, animationDuration / 2)
      }

      if (navLevel > 0) {
        setNavLevel(0)
        setShowSearch(false)
        focusOnElement(activeNav!.parentId)
      }
    }

    if (
      keyPress.key === 'Tab' &&
      !keyPress?.shiftKey &&
      sideNavigationCloseButton &&
      document.activeElement?.id === sideNavigationCloseButton.id
    ) {
      keyPress?.preventDefault()
      focusOnElement(activeNav!.parentId)
      setNavLevel(0)
    }

    // Shift + Tab on the top item
    if (keyPress.shiftKey && keyPress.key === 'Tab') {
      if (activeNav && document.activeElement?.id === activeNav.links[0].id) {
        keyPress?.preventDefault()
        focusOnElement(activeNav.parentId)
        setNavLevel(0)
      }

      if (activeSubNav && document.activeElement?.id === activeSubNav.links[0].id) {
        if (activeSubNav.level === 2) {
          keyPress?.preventDefault()
          focusOnElement(activeSubNav.parentId)
          setNavLevel(1)
        }

        if (activeSubNav.level > 2) {
          keyPress?.preventDefault()
          backwardNavigation(navLevel, navTree, setIsTransitioning, setNavLevel, setActiveSubNav, setNavTree, animationDuration)
          focusOnElement(activeSubNav.parentId)
        }
      }
    }
  }, [keyPress])
}

export default setFocus
