import axios from 'axios';

async function validateMSISDN(validationUrl, queryParam, payload, accessToken) {
  let isPhoneNumberFound, errorObj;
  const { response, error } = await genericValidationService(
    validationUrl,
    queryParam,
    payload,
    accessToken
  );

  if (response && response.status === 200) return response.data[0].party.fullName;

  if (error && error.response?.status === 404) isPhoneNumberFound = true;

  errorObj = { ...new Error(), isPhoneNumberFound };
  throw errorObj;
}

export { validateMSISDN };

const genericValidationService = async (validationUrl, queryParam, payload, accessToken) => {
  try {
    const response = await axios({
      method: 'get',
      url: validationUrl,
      params: {
        filter: queryParam.replace('<<VALUE>>', payload),
      },
      paramsSerializer: ({ filter }) => {
        return `filter=${encodeURIComponent(filter)}`;
      },
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return { response, error: undefined };
  } catch (error) {
    return { response: undefined, error };
  }
};
