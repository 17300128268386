import * as actions from '../actions';
import * as selector from '../selectors/selector';

import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';

import { getAgentInfo } from '../services/getAgentInfoApi';
import getMappedData from '../utils/getMappedData';
import { JSONPath } from 'jsonpath-plus';

function* getAgentInfoReq(action, userInfo) {
  if (!userInfo) {
    userInfo = yield select(selector.getAgentInfo);
    if (!userInfo) return;
  }
  try {
    const { orgUrl, agentPathsData } = action.payload;
    const shopIds = `${JSONPath({ path: agentPathsData.shopIds, json: userInfo })}`;

    const organizationUrl = orgUrl + shopIds;
    const response = yield call(getAgentInfo, organizationUrl);
    const mappedData = getMappedData(response, userInfo, agentPathsData);

    yield put(actions.getAgentInfoSuccess(mappedData));
  } catch (error) {
    yield put(actions.getAgentInfoFailure());
  }
}

// sc2: when getting the successful response from the token exchange
export function* watchTokenSuccess() {
  while (true) {
    const allAction = yield all([
      take(actions.GET_TOKEN_SUCCESS),
      take(actions.GET_AGENT_INFO_REQUEST),
    ]);
    const isRefreshTokenFlow = yield select(selector.isRefreshToken);
    const allAgentAction = allAction && allAction[1];
    const payload = allAgentAction?.payload;
    const tokenAction = allAction && allAction[0];
    const userInfo = tokenAction?.payload.user_info;

    if (payload && userInfo && !isRefreshTokenFlow)
      yield call(getAgentInfoReq, allAgentAction, userInfo);
  }
}

// sc1: when refreshing the page
export function* watchGetAgentInfo() {
  yield takeLatest(actions.GET_AGENT_INFO_REQUEST, getAgentInfoReq);
}

export function* getBucketRequest() {
  try {
    const storeBucketURL = yield select(selector.storeBucketUrl);
    if (!storeBucketURL) return '';

    const storeID = yield select(selector.storeId);
    const response = yield call(getAgentInfo, storeBucketURL.replace('{storeId}', storeID));

    yield put(actions.getStoreBucketSuccess(response[0].accountBalance[0]));
  } catch (error) {
    yield put(actions.getStoreBucketFailure(error));
  }
}

export function* watchGetStoreBucket() {
  yield takeLatest(actions.GET_STORE_BUCKET_REQUEST, getBucketRequest);
}

export function* watchTopupSuccess() {
  const topupSuccessActionName = yield select(selector.topupSuccessActionName);
  yield takeLatest(topupSuccessActionName, getBucketRequest);
}

function* setDealerCodes({ payload }) {
  const { primaryStoreId, storesInfo } = payload;
  const storeID = yield select(selector.storeId);
  const existedStore = storesInfo?.find(el => el.id === storeID);

  let targetStore;

  if (existedStore) {
    targetStore = existedStore;
  } else {
    const defaultStore = storesInfo?.find(el => el.id === primaryStoreId);
    targetStore = defaultStore;
  }

  const { id, dealerCodes, primaryDealerCode } = targetStore || {};

  yield put(
    actions.setProps('storeDetails', {
      storeId: id,
      dealerCodes,
      primaryDealerCode,
    })
  );
}
export function* watchAgentInfoSuccess() {
  yield takeLatest(actions.GET_AGENT_INFO_SUCCESS, setDealerCodes);
}
