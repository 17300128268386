/* eslint-disable sonarjs/cognitive-complexity */
import type { ReactElement, FC } from 'react'
import React, { useState, useContext, memo } from 'react'

import SimpleGrid from '@vfuk/core-simple-grid'
import Animate from '@vfuk/core-animate'
import { useBreakpoints } from '@vfuk/core-match-media'
import IconButton from '@vfuk/core-icon-button'
import { getDataSelector } from '@vfuk/core-base-props'

import type { InteractionEvent } from '@vfuk/core-interaction'
import type { BaseProps } from '@vfuk/core-base-props'

import * as Styled from './styles/SideNavigation.style'

import SideNavigationList from './components/SideNavigationList'
import SideNavigationSecondaryList from './components/SideNavigationSecondaryList'

import type { SideNavigationState } from './SideNavigation.types'

import type { Link } from '../../TopNavigation.types'

import { DataKeys } from '../../constants'

import focusOnElement from '../../helpers/focusOnElement'
import forwardNavigation from '../../helpers/forwardNavigation'
import backwardNavigation from '../../helpers/backwardNavigation'

import TopNavigationContext from '../../context/TopNavigationContext'
import type { TopNavigationContextProps } from '../../context/TopNavigationContext.types'

const SideNavigation: FC<BaseProps> = ({ dataSelectorPrefix }: BaseProps): ReactElement => {
  const { sm, md, mdAndAbove, lgAndAbove } = useBreakpoints()

  const {
    activeNav,
    setActiveNav,
    navLevel,
    setNavLevel,
    activeSubNav,
    setActiveSubNav,
    topNavHeight,
    animationDuration,
    sideNavigationCloseButton,
    navTree,
    setNavTree,
    isTransitioning,
    setIsTransitioning,
    zIndex,
    interactionHandler,
    appearance,
    topNavigationTheme,
  } = useContext(TopNavigationContext) as TopNavigationContextProps

  const [activeLinkId, setActiveLinkId] = useState<SideNavigationState['activeLinkId']>()

  const handlePrimaryClick = (link: Link): void => {
    if (sm) {
      forwardNavigation(link, setIsTransitioning, setNavLevel, setActiveNav, setNavTree, animationDuration, navTree, 1, activeNav)
      return
    }
    setActiveLinkId(link.id)
    forwardNavigation(link, setIsTransitioning, setNavLevel, setActiveSubNav, setNavTree, animationDuration, navTree, 1, activeNav)
  }

  const handleSecondaryClick = (link: Link): void => {
    forwardNavigation(link, setIsTransitioning, setNavLevel, setActiveSubNav, setNavTree, animationDuration, navTree, navLevel)
  }

  const handleBackClick = (): void => {
    if (sm) {
      backwardNavigation(navLevel, navTree, setIsTransitioning, setNavLevel, setActiveNav, setNavTree, animationDuration)
      return
    }
    backwardNavigation(navLevel, navTree, setIsTransitioning, setNavLevel, setActiveSubNav, setNavTree, animationDuration)
  }

  const handleCloseButtonClick = (event: InteractionEvent): void => {
    interactionHandler(event, DataKeys.CloseButton)
    setNavLevel(1)
    focusOnElement(activeNav!.parentId)

    if (navLevel > 1) {
      setTimeout(() => {
        setNavLevel(0)
      }, animationDuration)
      return
    }

    setNavLevel(0)
  }

  const { closeButtonAppearance } = topNavigationTheme.appearance[appearance].sideTray

  return (
    <Styled.SideNavigation topOffset={topNavHeight} zIndex={zIndex!} data-selector={getDataSelector(dataSelectorPrefix)}>
      <Styled.Container>
        <SimpleGrid layout={{ sm: [12], md: [5, 5], lg: [1, 5, 3] }} verticalAlign='stretch'>
          {lgAndAbove && <Styled.Spacer />}
          <Styled.PrimaryNavContainer zIndex={zIndex!} appearance={appearance} topNavigationTheme={topNavigationTheme}>
            <Styled.NavContents animationDuration={animationDuration} isTransitioning={sm ? isTransitioning : false}>
              <SideNavigationList
                backLinkClick={handleBackClick}
                links={activeNav!.links}
                onClick={handlePrimaryClick}
                activeLinkId={activeLinkId}
                navLevel={sm ? navLevel : 1}
                dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'list')}
              />
            </Styled.NavContents>
            {((sm && navLevel === 1) || mdAndAbove) && (
              <SideNavigationSecondaryList dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'secondary-list')} />
            )}
          </Styled.PrimaryNavContainer>
          {mdAndAbove && (
            <Animate
              show={navLevel > 1}
              enter={{
                animations: ['slideFromLeft'],
                duration: animationDuration,
              }}
              exit={{
                animations: ['slideToLeft'],
                duration: animationDuration,
              }}
            >
              {activeSubNav && (
                <Styled.SecondaryNavContainer zIndex={zIndex!} appearance={appearance} topNavigationTheme={topNavigationTheme}>
                  <Styled.NavContents animationDuration={animationDuration} isTransitioning={isTransitioning}>
                    <SideNavigationList
                      backLinkClick={handleBackClick}
                      links={activeSubNav!.links}
                      onClick={handleSecondaryClick}
                      navLevel={navLevel}
                      dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'list')}
                    />
                  </Styled.NavContents>
                </Styled.SecondaryNavContainer>
              )}
            </Animate>
          )}
        </SimpleGrid>
        <Styled.CloseButton closeButtonPosition={navLevel} animationDuration={animationDuration} zIndex={zIndex!}>
          <IconButton
            id={sideNavigationCloseButton!.id}
            icon={{ name: 'close' }}
            appearance={closeButtonAppearance}
            size={4}
            srText={sideNavigationCloseButton!.srText}
            onClick={handleCloseButtonClick}
            dataSelectorPrefix={getDataSelector(dataSelectorPrefix, DataKeys.CloseButton)}
          />
        </Styled.CloseButton>
      </Styled.Container>
    </Styled.SideNavigation>
  )
}

export default memo(SideNavigation)
