import axios from 'axios';

export const getAgentInfo = async orgUrl => {
  try {
    const orgResponse = await axios({
      url: orgUrl,
      method: 'GET'
    });
    return orgResponse.data;
  } catch (e) {
    throw e;
  }
};
