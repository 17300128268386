import type { Theme } from '@vfuk/core-themes'

import type { SideNavigationCardTheme } from './SideNavigationCard.theme.types'

const sideNavigationCardLocal = (theme: Theme): SideNavigationCardTheme => {
  return {
    appearance: {
      primary: {
        backgroundColor: `linear-gradient(${theme.color.gradient1.default})`,
        inverseText: true,
      },
      secondary: {
        backgroundColor: `linear-gradient(${theme.color.gradient2.default})`,
        inverseText: true,
      },
    },
    size: {
      sm: {
        height: 84,
      },
      md: {
        height: 92,
      },
      lg: {
        height: 100,
      },
      xl: {
        height: 100,
      },
    },
  }
}

export default sideNavigationCardLocal
