import type { ReactElement, FC } from 'react'
import React, { useContext, useState, useEffect } from 'react'

import SearchInput from '@vfuk/core-search-input'
import InteractiveIcon from '@vfuk/core-interactive-icon'
import Animate from '@vfuk/core-animate'
import type { InteractionEvent } from '@vfuk/core-interaction'
import type { BaseProps } from '@vfuk/core-base-props'
import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/SearchBar.style'

import focusOnElement from '../../../../helpers/focusOnElement'

import { DataKeys } from '../../../../constants'

import TopNavigationContext from '../../../../context/TopNavigationContext'
import type { TopNavigationContextProps } from '../../../../context/TopNavigationContext.types'

const SearchBar: FC<BaseProps> = ({ dataSelectorPrefix }: BaseProps): ReactElement | null => {
  const { secondaryNav, setShowSearch, showSearch, animationDuration, interactionHandler, appearance, topNavigationTheme } = useContext(
    TopNavigationContext,
  ) as TopNavigationContextProps
  const [searchValue, setSearchValue] = useState('')

  if (!secondaryNav?.search) return null

  const { search } = secondaryNav

  useEffect(() => {
    const searchInput = document.getElementById(search.searchInput.id)
    if (searchInput) searchInput.focus()
  }, [showSearch])

  const closeButtonHandler = (event: InteractionEvent): void => {
    setShowSearch(false)
    setTimeout(() => {
      focusOnElement(search.id)
    }, animationDuration)
    interactionHandler(event, DataKeys.CloseSearch)
  }

  const { interactiveIconAppearance, inverseIcon } = topNavigationTheme.appearance[appearance].mainBar

  if (!showSearch) return null

  return (
    <Styled.SearchBar data-selector={getDataSelector(dataSelectorPrefix)}>
      <Animate
        show={showSearch}
        enter={{
          animations: ['fadeIn', 'slideFromBottom'],
          duration: animationDuration,
        }}
        exit={{
          animations: ['fadeOut', 'slideToBottom'],
          duration: animationDuration,
        }}
      >
        <Styled.Search>
          <Styled.SearchInputWrapper>
            <SearchInput
              textInput={{
                id: search.searchInput.id,
                value: searchValue,
                placeholder: search.searchInput.placeholder,
                onChange: (e): void => setSearchValue(e.target.value),
              }}
              fieldWrapper={{
                label: search.searchInput.srText,
                width: 'full',
                showLabel: false,
              }}
              searchIconButton={{
                srText: search.searchInput.searchIconButton.srText,
                onClick: (): void => search.searchInput.onSearch(searchValue),
              }}
              onClear={(): void => setSearchValue('')}
              dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'search-input')}
            />
          </Styled.SearchInputWrapper>
          <Styled.CloseButtonWrapper>
            <InteractiveIcon
              appearance={interactiveIconAppearance}
              inverse={inverseIcon}
              srText={search.closeButton.srText}
              name='close'
              onClick={(event): void => {
                closeButtonHandler(event)
              }}
              dataSelectorPrefix={getDataSelector(dataSelectorPrefix, DataKeys.CloseSearch)}
            />
          </Styled.CloseButtonWrapper>
        </Styled.Search>
      </Animate>
    </Styled.SearchBar>
  )
}

export default SearchBar
