import type { FC, ReactElement } from 'react'
import React from 'react'

import { useLocalTheme } from '@vfuk/core-themes'
import type { InteractionEvent } from '@vfuk/core-interaction'
import { getDataSelector } from '@vfuk/core-base-props'

import defaultTheme from './themes/SkipLink.theme'
import * as Styled from './styles/SkipLink.style'

import type { SkipLinkProps } from './SkipLink.types'

const SkipLink: FC<SkipLinkProps> = ({
  appearance = 'primary',
  id,
  text = 'Skip to main content',
  skipLinkId = '#main',
  dataAttributes,
  onClick,
  localTheme,
  dataSelectorPrefix,
}: SkipLinkProps): ReactElement => {
  const componentName = 'SkipLink'
  const skipLinkTheme = useLocalTheme(componentName, defaultTheme, localTheme)

  const interactionHandler = (event: InteractionEvent): void => {
    if (onClick) {
      onClick(event, 'skip-link')
    }
  }

  return (
    <Styled.SkipLink
      id={id}
      skipLinkTheme={skipLinkTheme}
      appearance={appearance}
      label={text}
      href={skipLinkId}
      onClick={interactionHandler}
      componentName={componentName}
      dataAttributes={dataAttributes}
      dataSelectorPrefix={getDataSelector(dataSelectorPrefix)}
    >
      {text}
    </Styled.SkipLink>
  )
}

export default SkipLink
