//wrap basket index here
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal } from 'r10-source-library';
import { useHistory } from 'react-router-dom';

import { TranslationProvider } from './context/TranslationContext';
import reducer from './reducers';
import Root from './components/Root';
import {
  commonTopUpValueSelector,
  isOpenSelector,
  propSelector,
  currentScreensSelector,
} from './selectors/selector';
import sagas from './sagas';
import './index.css';
import { closeTopup } from './actions/Actions';
import { SCREENS } from './appConstants';

const Container = ({
  i18n,
  language,
  baseUrl,
  queryParam,
  validationRegex,
  phoneNumberLength,
  commonTopUpValues,
  step,
  minTopUpValue,
  maxTopUpValue,
  currency,
  defaultTopUpValue,
  isFastTopupFlow,
  isOpen,
  closeTopupModal,
  currentScreen,
}) => {
  const history = useHistory();
  const t = i18n
    ? (key, options) => {
        const tr = i18n(key, options);
        return tr || key;
      }
    : key => key;

  if (!language) return null;

  return (
    <Modal
      id="topup-modal"
      srName="topup"
      isOpen={isOpen}
      size={3}
      animate
      isClosable={currentScreen !== SCREENS.LOADING}
      overlayBlur
      onCloseCb={() => {
        closeTopupModal();
      }}
    >
      <TranslationProvider t={t}>
        <Root
          validationRegex={new RegExp(validationRegex)}
          minTopUpValue={minTopUpValue}
          maxTopUpValue={maxTopUpValue}
          step={step}
          commonTopUpValues={commonTopUpValues}
          currency={currency}
          phoneNumberLength={phoneNumberLength}
          defaultTopUpValue={defaultTopUpValue}
          isFastTopupFlow={isFastTopupFlow}
          baseUrl={baseUrl}
          queryParam={queryParam}
          history={history}
        />
      </TranslationProvider>
    </Modal>
  );
};
const appProps = [
  'currency',
  'phoneNumberLength',
  'minTopUpValue',
  'maxTopUpValue',
  'step',
  'validationRegex',
  'redirectUrl',
  'queryParam',
  'baseUrl',
  'defaultTopUpValue',
];
const mapStateToProps = state => ({
  language: state.language?.language,
  ...appProps.reduce(
    (props, prop) => ({
      ...props,
      [prop]: propSelector(state, prop),
      commonTopUpValues: commonTopUpValueSelector(state),
    }),
    {}
  ),
  isOpen: isOpenSelector(state),
  currentScreen: currentScreensSelector(state),
});

const mapDispatchToProps = dispatch => ({
  closeTopupModal: () => dispatch(closeTopup()),
});

Container.propTypes = {
  i18n: PropTypes.func,
  language: PropTypes.string,
  __instanceId: PropTypes.string,
  __pageId: PropTypes.string,
  baseUrl: PropTypes.string,
  queryParam: PropTypes.string,
  validationRegex: PropTypes.string,
  phoneNumberLength: PropTypes.number,
  commonTopUpValues: PropTypes.array,
  step: PropTypes.number,
  minTopUpValue: PropTypes.number,
  maxTopUpValue: PropTypes.number,
  defaultTopUpValue: PropTypes.number,
  currency: PropTypes.string,
  isFastTopupFlow: PropTypes.bool,
  isOpen: PropTypes.bool,
  closeTopupModal: PropTypes.func,
  currentScreen: PropTypes.string,
};
export const ConnectedContainer = connect(mapStateToProps, mapDispatchToProps)(Container);
const exported = ConnectedContainer;

export default exported;

exported.reducer = reducer;

exported.sagas = sagas;

// middleware example
const logger = store => next => action => {
  console.log('dispatching', action);
  let result = next(action);
  console.log('next state', store.getState());
  return result;
};

exported.middlewares = [logger];
