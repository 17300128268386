import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import Interaction from '@vfuk/core-interaction'
import { spacing, typography } from '@vfuk/core-mixins'

import type { StyledMobileNavTriggerProps } from './MobileNavTrigger.style.types'

export const MobileNavTrigger = styled(Interaction)(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    align-items: center;
    ${spacing('gap', 3)};
    ${typography.heading(1, false)};
    position: relative;
  `,
)

export const MobileNavTriggerText = styled.span<StyledMobileNavTriggerProps>(
  (props: StyledMobileNavTriggerProps): FlattenSimpleInterpolation => css`
    border-bottom: ${props.theme.border.width[2]} ${props.theme.border.style[1]} transparent;

    ${props.isActive &&
    css`
      border-bottom: ${props.theme.border.width[2]} ${props.theme.border.style[1]}
        ${props.topNavigationTheme.appearance[props.appearance].mainBar.activeBorderColor};
      font-weight: ${props.theme.fontWeight[3]};
    `}
  `,
)

export const NumberBadgeWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    position: absolute;
    left: 100%;
    bottom: 100%;
    transform: translate(0%, 75%);
  `,
)
