import { pkgName } from '../appConstants';

export const GET_AGENT_INFO_REQUEST = `@${pkgName}/GET_AGENT_INFO_REQUEST`;
export const GET_AGENT_INFO_SUCCESS = `@${pkgName}/GET_AGENT_INFO_SUCCESS`;
export const GET_AGENT_INFO_FAILURE = `@${pkgName}/GET_AGENT_INFO_FAILURE`;
export const RESET_AGENT_INFO = `@${pkgName}/RESET_AGENT_INFO `;

export const GET_TOKEN_SUCCESS = 'GET_TOKEN_SUCCESS';
export const SET_PROPS = `SET_PROPS`;
export const GET_STORE_BUCKET_REQUEST = `@${pkgName}/GET_STORE_BUCKET_REQUEST`;
export const GET_STORE_BUCKET_SUCCESS = `@${pkgName}/GET_STORE_BUCKET_SUCCESS`;
export const GET_STORE_BUCKET_FAILURE = `@${pkgName}/GET_STORE_BUCKET_FAILURE `;
export const SET_STORE_BUCKET_URL = `@${pkgName}/SET_STORE_BUCKET_URL`;
export const SET_TOPUP_SUCCESS = `@${pkgName}/SET_TOPUP_SUCCESS`;
export const SEND_TAGS = 'SEND_TAGS';
export const SET_AGENT_CONFIG = `@${pkgName}/SET_AGENT_CONFIG`;
export const REFRESH_TOKEN = 'REFRESH_TOKEN';


export const sendTags = payload => ({ type: SEND_TAGS, payload });

export const getAgentInfoRequest = payload => ({
  type: GET_AGENT_INFO_REQUEST,
  payload
});

export const getAgentInfoSuccess = payload => ({
  type: GET_AGENT_INFO_SUCCESS,
  payload: payload
});

export const getAgentInfoFailure = () => ({
  type: GET_AGENT_INFO_FAILURE
});

export const setProps = (comp, props) => ({
  type: SET_PROPS,
  payload: { comp, props }
});
export const setStoreBucketURL = url => ({
  type: SET_STORE_BUCKET_URL,
  payload: url
});

export const getStoreBucketRequest = () => ({
  type: GET_STORE_BUCKET_REQUEST
});

export const getStoreBucketSuccess = payload => ({
  type: GET_STORE_BUCKET_SUCCESS,
  payload: payload
});

export const getStoreBucketFailure = error => ({
  type: GET_STORE_BUCKET_FAILURE,
  error: error
});

export const setSuccessTopupActionName = actionName => ({
  type: SET_TOPUP_SUCCESS,
  payload: actionName
});
