import type { ReactElement, FC } from 'react'
import React, { useContext, useState } from 'react'

import ListGroup from '@vfuk/core-list-group'
import NumberBadge from '@vfuk/core-number-badge'
import type { InteractionEvent } from '@vfuk/core-interaction'
import type { BaseProps } from '@vfuk/core-base-props'
import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/PrimaryNav.style'

import type { PrimaryNavState } from './PrimaryNav.types'

import type { Link } from '../../../../TopNavigation.types'

import { DataKeys } from '../../../../constants'

import TopNavigationContext from '../../../../context/TopNavigationContext'
import type { TopNavigationContextProps } from '../../../../context/TopNavigationContext.types'
import registerAriaTree from '../../../../helpers/registerAriaTree'

const PrimaryNav: FC<BaseProps> = ({ dataSelectorPrefix }: BaseProps): ReactElement => {
  const {
    primaryNav,
    navLevel,
    setNavLevel,
    setActiveNav,
    animationDuration,
    setNavTree,
    interactionHandler,
    appearance,
    topNavigationTheme,
  } = useContext(TopNavigationContext) as TopNavigationContextProps

  const [activeLink, setActiveLink] = useState<PrimaryNavState['activeLink']>()

  const clickHandler = (link: Link, event: InteractionEvent): void => {
    interactionHandler(event, getDataSelector(dataSelectorPrefix, link.id) ?? '')

    if (link.links) {
      setNavLevel(1)
      setActiveNav({ parentId: link.id, links: link.links })
      setActiveLink(link.id)
      setNavTree([
        {
          level: 1,
          links: link.links,
          parentId: link.id,
        },
      ])
      return
    }

    link.onClick?.()
  }

  const { numberBadgeAppearance } = topNavigationTheme.appearance[appearance].mainBar

  return (
    <Styled.PrimaryNav aria-label={primaryNav.srText} data-selector={getDataSelector(dataSelectorPrefix)}>
      <ListGroup>
        {primaryNav.links.map((link) => {
          return (
            <Styled.PrimaryNavItem key={link.id} text={link.text}>
              <Styled.PrimaryNavLink
                selected={navLevel > 0 && activeLink === link.id}
                id={link.id}
                label={link.srText}
                href={link.href}
                text={link.text}
                customRouterProps={link.customRouterProps}
                onClick={(event: InteractionEvent): void => clickHandler(link, event)}
                dataAttributes={registerAriaTree(link)}
                data-selector={getDataSelector(dataSelectorPrefix, `${DataKeys.PrimaryNav}-${link.id}`)}
                animationDuration={animationDuration}
                appearance={appearance}
                topNavigationTheme={topNavigationTheme}
              >
                {link.text}
              </Styled.PrimaryNavLink>
              {link.numberBadge && (
                <Styled.NumberBadgeWrapper>
                  <NumberBadge
                    number={link.numberBadge!}
                    appearance={numberBadgeAppearance}
                    data-selector={getDataSelector(dataSelectorPrefix, `${DataKeys.PrimaryNav}-badge-${link.id}`)}
                  />
                </Styled.NumberBadgeWrapper>
              )}
            </Styled.PrimaryNavItem>
          )
        })}
      </ListGroup>
    </Styled.PrimaryNav>
  )
}

export default PrimaryNav
