import jp from 'jsonpath';

const getValue = (obj, path) => {
  try {
    return jp.value(obj, path);
  } catch (error) {
    return;
  }
};

const getFullAddress = (path, obj) => {
  const firstStreet = getValue(obj, path.address.firstStreet);
  const secondStreet = getValue(obj, path.address.secondStreet);
  const city = getValue(obj, path.address.city);
  const state = getValue(obj, path.address.state);
  return `${`
    ${firstStreet ? `${firstStreet} ` : ''}
    ${secondStreet ? `${secondStreet}, ` : ''}
    ${city ? `${city}, ` : ''}
    ${state ? `${state}` : ''}`}
  `.trim();
};

export default getFullAddress;
