import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { ListGroupStyles } from '@vfuk/core-list-group'
import { ListItemStyles } from '@vfuk/core-list-item'
import { advancedSpacing } from '@vfuk/core-mixins'

import type { StyledTopBarProps } from './TopBar.style.types'

export const TopBar = styled.nav<StyledTopBarProps>(
  (props: StyledTopBarProps): FlattenSimpleInterpolation => css`
    position: relative;
    z-index: ${props.zIndex + 2};
    background-color: ${props.topNavigationTheme.appearance[props.appearance].topBar.backgroundColor};
  `,
)

type ContainerProps = Pick<StyledTopBarProps, 'theme'>
export const Container = styled.div<ContainerProps>(
  (props: ContainerProps): FlattenSimpleInterpolation => css`
    width: 100%;
    max-width: ${props.theme.gridConfig.springWidth}px;
    margin: 0 auto;
  `,
)

export const LinksWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    justify-content: space-between;

    ${ListItemStyles.ListItem} {
      display: flex;
    }

    ${ListGroupStyles.ListGroup} {
      display: flex;
    }
  `,
)

export const DividerWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    ${advancedSpacing('padding', [2, 0])}
  `,
)
