import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import ListItem from '@vfuk/core-list-item'

export const IconLinkWrapper = styled(ListItem)(
  (): FlattenSimpleInterpolation => css`
    display: flex;
  `,
)

export const NumberBadgeWrapper = styled.div((): FlattenSimpleInterpolation => [])
