import type { FC, ReactElement } from 'react'
import React, { useContext } from 'react'

import SimpleGrid from '@vfuk/core-simple-grid'
import { useBreakpoints } from '@vfuk/core-match-media'
import Animate from '@vfuk/core-animate'
import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/MainBar.style'

import Logo from './components/Logo'
import PrimaryNav from './components/PrimaryNav'
import SecondaryNav from './components/SecondaryNav'
import SearchBar from './components/SearchBar'
import MobileNavTrigger from './components/MobileNavTrigger'

import type { MainBarProps } from './MainBar.types'

import TopNavigationContext from '../../context/TopNavigationContext'
import type { TopNavigationContextProps } from '../../context/TopNavigationContext.types'
import { DataKeys } from '../../constants'

const MainBar: FC<MainBarProps> = React.forwardRef(({ zIndex, dataSelectorPrefix }: MainBarProps, ref): ReactElement => {
  const { showSearch, animationDuration, primaryNav, secondaryNav, appearance, topNavigationTheme } = useContext(
    TopNavigationContext,
  ) as TopNavigationContextProps
  const { mdAndBelow } = useBreakpoints()

  return (
    <Styled.MainBar
      zIndex={zIndex}
      ref={ref}
      appearance={appearance}
      topNavigationTheme={topNavigationTheme}
      data-selector={getDataSelector(dataSelectorPrefix)}
    >
      <Styled.Container>
        <SimpleGrid layout={{ sm: [2, 10], lg: [1, 11] }} justify='left' verticalAlign='center'>
          <Logo dataSelectorPrefix={getDataSelector(dataSelectorPrefix, DataKeys.Logo)} />
          <Styled.NavWrapper>
            <SearchBar dataSelectorPrefix={getDataSelector(dataSelectorPrefix, DataKeys.SearchBar)} />
            <Animate
              show={!showSearch}
              enter={{
                animations: ['fadeIn'],
                duration: animationDuration,
              }}
              exit={{
                animations: ['fadeOut'],
                duration: animationDuration,
              }}
            >
              <Styled.MainBarContents aria-label={primaryNav.srText}>
                {primaryNav && (
                  <>
                    {mdAndBelow ? (
                      <MobileNavTrigger dataSelectorPrefix={getDataSelector(dataSelectorPrefix, DataKeys.MobileNavTrigger)} />
                    ) : (
                      <PrimaryNav dataSelectorPrefix={getDataSelector(dataSelectorPrefix, DataKeys.PrimaryNav)} />
                    )}
                  </>
                )}
                {secondaryNav && <SecondaryNav dataSelectorPrefix={getDataSelector(dataSelectorPrefix, DataKeys.SecondaryNav)} />}
              </Styled.MainBarContents>
            </Animate>
          </Styled.NavWrapper>
        </SimpleGrid>
      </Styled.Container>
    </Styled.MainBar>
  )
})

export default MainBar
