import { JSONPath } from 'jsonpath-plus';

/**
 * Returns agent name and store name of primaryPosition
 * @param {Record<string, any>} individual - getIndividual api response
 * @param {Record<string, string>} paths - fields json path
 */
const getIndividualData = (individual, paths) => {
  const agentName = `${JSONPath(paths.name, individual)}, ${JSONPath(paths.surname, individual)}`;
  const primaryPositionId = individual.userAssets.find(el => el.role === 'primaryPosition').id;
  const primaryStoreId =
    JSONPath(
      paths.primaryStore,
      individual.userAssets.find(
        el => el.role !== 'primaryPosition' && el.id === primaryPositionId
      ) ?? {}
    )[0] ?? '';

  return { agentName, primaryStoreId };
};

/**
 * Returns array of agent stores details
 * @param {Record<string, any>} organization - getOrganization api response
 * @param {Record<string, string>} paths - fields json path
 */
const getOrganizationData = (organization, paths) => {
  function location(store) {
    const storeLocationPaths = paths.storeLocation.split(',');
    return storeLocationPaths.reduce((loc, path) => {
      loc += JSONPath(path, store) || '';
      return loc;
    }, '');
  }

  return organization.map(el => ({
    id: JSONPath(paths.storeId, el)[0],
    location: location(el),
    phoneNumber: JSONPath(paths.storePhoneNumber, el)[0],
    primaryDealerCode: JSONPath(paths.primaryDealerCode, el)[0],
    dealerCodes: JSONPath(paths.dealerCodes, el),
    header: JSONPath(paths.storeHeading, el)[0],
  }));
};

/**
 * map data of getIndividual and getOrganization
 */
const getMappedData = (organization, individual, paths) => {
  const { agentName, primaryStoreId } = getIndividualData(individual, paths);
  const storesInfo = getOrganizationData(organization, paths);

  return { agentName, primaryStoreId, storesInfo };
};

export default getMappedData;
