import {
  COLLAPSE_TOP_UP_INPUT,
  RESET_TOP_UP,
  UPDATE_CURRENT_SCREEN,
  UPDATE_PHONE_NUMBER,
  UPDATE_PHONE_NUMBER_ERROR_STATUS,
  UPDATE_PHONE_NUMBER_NOT_FOUND_STATUS,
  UPDATE_TOP_UP_VALUE,
  UPDATE_TOP_UP_MIN_VALUE,
  TOPUP_REQUEST,
  TOPUP_SUCCESS,
  TOPUP_FAILURE,
  RESET_PHONE_NUMBER,
  SET_CUSTOMER_FULL_NAME,
  OPEN_TOPUP,
  CLOSE_TOPUP,
  UPDATE_PAYMENT_METHOD,
  SET_STORE_BUCKET,
  UPDATE_TOP_UP_ERROR_SUCCESS,
} from '../actions/ActionType';
import { SCREENS } from '../appConstants';
import initialState from './initialState';
import API_RESPONSE from '../constants/APIResponse';

const rootReducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case UPDATE_PHONE_NUMBER:
      return {
        ...state,
        phoneNumber: payload,
      };
    case UPDATE_PHONE_NUMBER_ERROR_STATUS:
      return {
        ...state,
        phoneNumberErrorStatus: payload,
      };
    case UPDATE_PHONE_NUMBER_NOT_FOUND_STATUS:
      return {
        ...state,
        phoneNumberNotFound: payload,
      };
    case COLLAPSE_TOP_UP_INPUT:
      return {
        ...state,
        topUpInputIsCollapsed: payload,
      };
    case UPDATE_TOP_UP_VALUE:
      return {
        ...state,
        topUpValue: payload,
      };
    case UPDATE_TOP_UP_MIN_VALUE:
      return {
        ...state,
        minTopUpValue: payload,
      };
    case UPDATE_TOP_UP_ERROR_SUCCESS:
      return {
        ...state,
        topUpErrorStatus: payload,
      };
    case UPDATE_CURRENT_SCREEN:
      return {
        ...state,
        currentScreen: payload,
      };
    case SET_CUSTOMER_FULL_NAME:
      return {
        ...state,
        customerFullName: payload,
      };
    case RESET_TOP_UP:
      return {
        ...initialState,
        isOpen: state.isOpen,
      };
    case TOPUP_REQUEST:
      return {
        ...state,
        currentScreen: SCREENS.LOADING,
      };
    case TOPUP_SUCCESS:
      return {
        ...state,
        currentScreen: SCREENS.SUCCESS,
      };
    case TOPUP_FAILURE: {
      const currentScreen =
        payload === API_RESPONSE.LOW_STORE_BUCKET ? SCREENS.LOW_STORE_BUCKET : SCREENS.GenericError;
      return {
        ...state,
        currentScreen,
      };
    }
    case SET_STORE_BUCKET:
      return {
        ...state,
        storeBucket: payload,
      };
    case RESET_PHONE_NUMBER:
      return {
        ...state,
        phoneNumber: '',
        phoneNumbers: [],
      };
    case OPEN_TOPUP:
      return {
        ...state,
        isOpen: true,
      };
    case CLOSE_TOPUP:
      return {
        ...state,
        isOpen: false,
      };
    case UPDATE_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: payload,
      };
    default:
      return state;
  }
};

export default rootReducer;
