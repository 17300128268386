/* eslint-disable sonarjs/no-identical-functions */
import type { FC } from 'react'
import React, { useContext } from 'react'

import InteractiveIcon from '@vfuk/core-interactive-icon'
import type { BaseProps } from '@vfuk/core-base-props'
import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/IconNav.style'

import simpleClickHandler from '../../../../../../helpers/simpleClickHandler'
import TopNavigationContext from '../../../../../../context/TopNavigationContext'
import type { TopNavigationContextProps } from '../../../../../../context/TopNavigationContext.types'

import { DataKeys } from '../../../../../../constants'

const IconNav: FC<BaseProps> = ({ dataSelectorPrefix }) => {
  const { secondaryNav, interactionHandler, appearance, topNavigationTheme } = useContext(TopNavigationContext) as TopNavigationContextProps

  const { interactiveIconAppearance, inverseIcon } = topNavigationTheme.appearance[appearance].mainBar

  return (
    <>
      {secondaryNav?.iconNavLinks?.map((iconLink) => {
        return (
          <Styled.IconLinkWrapper key={iconLink.id}>
            <InteractiveIcon
              appearance={interactiveIconAppearance}
              inverse={inverseIcon}
              srText={iconLink.text}
              name={iconLink.icon!}
              onClick={(event): void => {
                simpleClickHandler(event, interactionHandler, dataSelectorPrefix, iconLink)
              }}
              href={iconLink.href}
              customRouterProps={iconLink.customRouterProps}
              numberBadge={iconLink.numberBadge ? { number: iconLink.numberBadge!, appearance: 'primary' } : undefined}
              dataSelectorPrefix={getDataSelector(dataSelectorPrefix, `${DataKeys.IconNav}-${iconLink.id}`)}
              dataAttributes={iconLink.dataAttributes}
            />
          </Styled.IconLinkWrapper>
        )
      })}
    </>
  )
}

export default IconNav
