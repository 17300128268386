import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

import { TranslationProvider } from './context/TranslationContext';
import reducer from './reducers';
import { PropsProvider } from './context/PropsContext';
import sagas from './sagas/index';
import App from './containers/App';
import { pkgName } from './constants/appConstants';

const CustomerInformation = ({
  i18n,
  language,
  __instanceId,
  widgetData,
  customerSearchComponent,
}) => {
  const t = i18n
    ? (key, options) => {
        const tr = i18n(key, options);
        return tr || key;
      }
    : key => key;

  if (!language) return null;

  return (
    <TranslationProvider t={t}>
      <PropsProvider
        widgetProps={{
          __instanceId,
          widgetData,
          customerSearchComponent,
        }}
      >
        <App config={widgetData} customerSearchComponent={customerSearchComponent} />
      </PropsProvider>
    </TranslationProvider>
  );
};

CustomerInformation.propTypes = {
  i18n: PropTypes.func,
  language: PropTypes.string,
  __instanceId: PropTypes.string,
  __pageId: PropTypes.string,

  widgetData: PropTypes.shape({
    title: PropTypes.string.isRequired,
    agentNamePath: PropTypes.string.isRequired,
    address: PropTypes.shape({
      addressTitlePath: PropTypes.string.isRequired,
      addressDefault: PropTypes.string.isRequired,
      firstStreet: PropTypes.string.isRequired,
      secondStreet: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
    }),

    showEdit: PropTypes.bool.isRequired,
    showChevron: PropTypes.bool.isRequired,
    customerEditNavigationURL: PropTypes.string.isRequired,
  }),

  customerSearchComponent: PropTypes.string,
};

const mapStateToProps = state => ({
  language: state.language.language,
});
export const ConnectedWidget = connect(mapStateToProps)(CustomerInformation);

const exported = ConnectedWidget;

const persistConfig = {
  key: pkgName,
  storage: storageSession
};

exported.reducer = persistReducer(persistConfig, reducer);

exported.sagas = sagas;

export default exported;
