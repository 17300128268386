import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Mixins } from 'r10-source-library';

import { SCREENS } from '../appConstants';
import {
  updateTopUpValue,
  updateTopUpMinValue,
  resetPhoneNumber,
  updatePhoneNumber,
  resetTopUp,
} from '../actions/Actions';
import {
  currentScreensSelector,
  getTopupInfoSelector,
  storeBucketSelector,
  minTopUpValueSelector,
  topUpValueSelector,
  storeDetailsSelector,
} from '../selectors/selector';
import TopUpConfirm from './Confirm/Confirm';
import PaymentMethodSelection from './Payment/PaymentMethodSelection';
import PaymentMethodConfirm from './Payment/PaymentMethodConfirm';
import GenericError from './Fail/GenericError/GenericError';
import LowStoreBucket from './Fail/LowStoreBucket/LowStoreBucket';
import Loading from './Loading/Loading';
import Success from './Success/Success';
import TopUp from './TopUp/TopUp';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 428px;
  ${Mixins.respondTo.md('min-height: 504px')};
`;

const Root = ({
  queryParam,
  currency,
  validationRegex,
  minTopUpValue,
  maxTopUpValue,
  step,
  updateTopUpVal,
  updateTopUpMinVal,
  commonTopUpValues,
  currentScreen,
  resetNumber,
  isFastTopupFlow,
  storeBucket,
  topupListInfo,
  topupValue,
  updPhoneNumber,
  history,
  resetTopUpState,
  storeDetails,
}) => {
  const [topupMinValuePreUpdate, setTopupMinValuePreUpdate] = useState(0);

  useEffect(() => {
    if (isFastTopupFlow) resetNumber();
    setTopupMinValuePreUpdate(minTopUpValue);

    const minValue = topupListInfo?.phoneNumbers?.find(
      elem => elem.phoneNumber === topupListInfo?.phoneNumber
    )?.minValue;

    updPhoneNumber(topupListInfo.phoneNumber);
    updateTopUpMinVal(minValue > minTopUpValue ? minValue : minTopUpValue);
    updateTopUpVal(topupValue);

    if (minTopUpValue === 0) updateTopUpVal(minValue);

    return () => {
      resetTopUpState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const RenderCurrentScreen = useCallback(
    currScreen => {
      switch (currScreen) {
        case SCREENS.TOP_UP:
          return (
            <TopUp
              currency={currency}
              validationRegex={validationRegex}
              defaultMinTopUpValue={minTopUpValue}
              maxTopUpValue={maxTopUpValue}
              step={step}
              commonTopUpValues={commonTopUpValues}
              isFastTopupFlow={isFastTopupFlow}
              queryParam={queryParam}
              topupListInfo={topupListInfo}
              topupMinValuePreUpdate={topupMinValuePreUpdate}
            />
          );
        case SCREENS.CONFIRM:
          return (
            <TopUpConfirm
              currency={currency}
              isFastTopupFlow={isFastTopupFlow}
              customerFullName={topupListInfo.customerFullName}
            />
          );
        case SCREENS.PAYMENT_METHOD_SELECTION:
          return <PaymentMethodSelection />;
        case SCREENS.PAYMENT_METHOD_CONFIRM:
          return (
            <PaymentMethodConfirm
              currency={currency}
              customerFullName={topupListInfo.customerFullName}
            />
          );
        case SCREENS.SUCCESS:
          return (
            <Success
              currency={currency}
              isFastTopupFlow={isFastTopupFlow}
              customerFullName={topupListInfo.customerFullName}
              history={history}
              storeId={storeDetails.storeId}
            />
          );
        case SCREENS.GenericError:
          return <GenericError isFastTopupFlow={isFastTopupFlow} />;
        case SCREENS.LOW_STORE_BUCKET:
          return <LowStoreBucket storeBucket={storeBucket} />;
        case SCREENS.LOADING:
          return <Loading />;
        default:
          return <div>NO SCREEN SELECTED</div>;
      }
    },
    [
      currency,
      validationRegex,
      minTopUpValue,
      maxTopUpValue,
      step,
      commonTopUpValues,
      isFastTopupFlow,
      queryParam,
      topupListInfo,
      storeBucket,
      topupListInfo,
    ]
  );

  return <Wrapper>{RenderCurrentScreen(currentScreen)}</Wrapper>;
};

const mapStateToProps = state => ({
  currentScreen: currentScreensSelector(state),
  storeBucket: storeBucketSelector(state),
  topupListInfo: getTopupInfoSelector(state),
  minTopUpValue: minTopUpValueSelector(state),
  topupValue: topUpValueSelector(state),
  storeDetails: storeDetailsSelector(state),
});

const mapDispatchToProps = dispatch => ({
  updateTopUpVal: value => dispatch(updateTopUpValue(value)),
  updateTopUpMinVal: value => dispatch(updateTopUpMinValue(value)),
  resetNumber: () => dispatch(resetPhoneNumber()),
  updPhoneNumber: newPhoneNumber => dispatch(updatePhoneNumber(newPhoneNumber)),
  resetTopUpState: () => dispatch(resetTopUp()),
});

Root.propTypes = {
  queryParam: PropTypes.string,
  currency: PropTypes.string,
  validationRegex: PropTypes.any,
  minTopUpValue: PropTypes.number,
  maxTopUpValue: PropTypes.number,
  step: PropTypes.number,
  commonTopUpValues: PropTypes.array,
  currentScreen: PropTypes.string,
  isFastTopupFlow: PropTypes.bool,
  defaultTopUpValue: PropTypes.number,
  updateTopUpVal: PropTypes.func,
  updateTopUpMinVal: PropTypes.func,
  resetNumber: PropTypes.func,
  storeBucket: PropTypes.number,
  topupListInfo: PropTypes.object,
  topupValue: PropTypes.number,
  updPhoneNumber: PropTypes.func,
  history: PropTypes.object,
  resetTopUpState: PropTypes.func,
  storeDetails: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
