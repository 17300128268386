import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ModalContent } from 'r10-common-components';

import useTranslation from '../../hooks/useTranslation';
import { phoneNumberSelector, topUpValueSelector } from '../../selectors/selector';
import { updateCurrentScreen } from '../../actions/Actions';
import { SCREENS } from '../../appConstants';

const TopUpConfirm = ({
  phoneNumber,
  topUpAmount,
  currency,
  updateCurrScreen,
  customerFullName,
  isFastTopupFlow,
}) => {
  const t = useTranslation();

  const handleConfirm = useCallback(() => {
    updateCurrScreen(SCREENS.PAYMENT_METHOD_SELECTION);
  }, [updateCurrScreen]);

  const handleEdit = useCallback(() => {
    updateCurrScreen(SCREENS.TOP_UP);
  }, [isFastTopupFlow, updateCurrScreen]);

  return (
    <ModalContent
      header={t('headerTopUp')}
      icon="top-up"
      title={t('confirmText')}
      titleMarginBottom={0}
      subtitles={[
        {
          regular: `${customerFullName} - `,
          bold: `${phoneNumber}`,
          marginBottom: 4,
        },
        {
          regular: `${t('confirmAmount')}: `,
          bold: `${topUpAmount}${currency || ''}`,
          marginBottom: 7,
        },
      ]}
      primaryButton={{
        text: t('confirmTopUp'),
        onClick: handleConfirm,
        dataTestId: 'confirm-topup',
      }}
      secondaryButton={{
        text: t('confirmEdit'),
        onClick: handleEdit,
        dataTestId: 'edit-button',
      }}
    />
  );
};

const mapStateToProps = state => ({
  phoneNumber: phoneNumberSelector(state),
  topUpAmount: topUpValueSelector(state),
});
const mapDispatchToProps = dispatch => ({
  updateCurrScreen: currentScreen => dispatch(updateCurrentScreen(currentScreen)),
});
TopUpConfirm.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  topUpAmount: PropTypes.number.isRequired,
  customerFullName: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  updateCurrScreen: PropTypes.func.isRequired,
  isFastTopupFlow: PropTypes.bool,
};
export default connect(mapStateToProps, mapDispatchToProps)(TopUpConfirm);
