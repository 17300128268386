import type { Link } from '../../TopNavigation.types'
import type { TopNavigationContextProps } from '../../context/TopNavigationContext.types'

function forwardNavigation(
  link: Link,
  setIsTransitioning: TopNavigationContextProps['setIsTransitioning'],
  setNavLevel: TopNavigationContextProps['setNavLevel'],
  setNextNav: TopNavigationContextProps['setActiveSubNav'] | TopNavigationContextProps['setActiveNav'],
  setNavTree: TopNavigationContextProps['setNavTree'],
  animationDuration: TopNavigationContextProps['animationDuration'],
  navTree: TopNavigationContextProps['navTree'],
  navLevel: TopNavigationContextProps['navLevel'],
  activeNav?: TopNavigationContextProps['activeNav'],
): void {
  if (!link.links) return

  setIsTransitioning(true)
  setNavLevel(navLevel + 1)
  setNextNav({
    parentId: link.id,
    level: navLevel + 1,
    links: link.links,
  })
  setTimeout(() => {
    if (navLevel > 1) {
      setNavTree([
        ...navTree!,
        {
          level: navLevel + 1,
          links: link.links,
          parentId: link.id,
        },
      ])
    } else {
      setNavTree([
        {
          level: 1,
          links: activeNav!.links,
          parentId: activeNav!.parentId,
        },
        {
          level: 2,
          links: link.links,
          parentId: link.id,
        },
      ])
    }
    setIsTransitioning(false)
  }, animationDuration / 2)
}

export default forwardNavigation
