import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Loader } from 'r10-source-library';

import useTranslation from '../../hooks/useTranslation';

const Wrapper = styled.div`
  margin: auto;
`;

const Loading = () => {
  let t = useTranslation();
  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      t = key => key;
    };
  }, []);
  return (
    <Wrapper data-testid="topup-loader">
      <Loader text={t('loadingProcessing')} size={2} srText={t('loadingProcessing')} />
    </Wrapper>
  );
};

export default Loading;
