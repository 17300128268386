import type { ReactElement, FC } from 'react'
import React, { useContext } from 'react'

import Icon from '@vfuk/core-icon'

import type { InteractionEvent } from '@vfuk/core-interaction'
import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/TopLink.style'

import type { TopLinkProps } from './TopLink.types'

import TopNavigationContext from '../../../../context/TopNavigationContext'
import type { TopNavigationContextProps } from '../../../../context/TopNavigationContext.types'
import simpleClickHandler from '../../../../helpers/simpleClickHandler'

import type { TopBarLink } from '../../../../themes/TopNavigation.theme.types'

const TopLink: FC<TopLinkProps> = ({ link, active = false, dataSelectorPrefix }: TopLinkProps): ReactElement => {
  const { interactionHandler, appearance, topNavigationTheme } = useContext(TopNavigationContext) as TopNavigationContextProps

  const { id, srText, href, customRouterProps, dataAttributes, icon, text } = link

  let topLinkAppearance = topNavigationTheme.appearance[appearance].topBar.links
  if (active) {
    topLinkAppearance = topLinkAppearance.active as TopBarLink
  }

  const { iconAppearance, inverseIcon } = topLinkAppearance

  return (
    <Styled.TopLink
      id={id}
      label={srText}
      href={href}
      customRouterProps={customRouterProps}
      onClick={(event: InteractionEvent): void => simpleClickHandler(event, interactionHandler, dataSelectorPrefix, link)}
      dataSelectorPrefix={getDataSelector(dataSelectorPrefix)}
      dataAttributes={dataAttributes}
      active={active}
      appearance={appearance}
      topNavigationTheme={topNavigationTheme}
    >
      {icon && (
        <Icon
          name={icon}
          size={1}
          inverse={inverseIcon}
          appearance={iconAppearance}
          dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'icon')}
        />
      )}
      {text}
    </Styled.TopLink>
  )
}

export default TopLink
