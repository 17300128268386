import type { BaseImageProps, ImageSrc } from '../Image.types'

export const mockImageExamples: BaseImageProps = {
  alt: 'image',
  sm: {
    src: './assets/image-library/london.jpg',
    srcSet: {
      x1: './assets/image-library/london.jpg',
      x2: './assets/image-library/london.jpg',
      x3: './assets/image-library/london.jpg',
    },
  },
  md: {
    src: './assets/image-library/lighthouse.jpg',
    srcSet: {
      x1: './assets/image-library/lighthouse.jpg',
      x2: './assets/image-library/lighthouse.jpg',
      x3: './assets/image-library/lighthouse.jpg',
    },
  },
  lg: {
    src: './assets/image-library/eclipse.jpg',
    srcSet: {
      x1: './assets/image-library/eclipse.jpg',
      x2: './assets/image-library/eclipse.jpg',
    },
  },
  xl: {
    src: './assets/image-library/skyscraper.jpg',
    srcSet: {
      x1: './assets/image-library/skyscraper.jpg',
      x2: './assets/image-library/skyscraper.jpg',
      x3: './assets/image-library/skyscraper.jpg',
    },
  },
}

export const mockImageExamplesAlt: BaseImageProps = {
  alt: 'image',
  sm: {
    src: './assets/image-library/ny.jpg',
    srcSet: {
      x1: './assets/image-library/ny.jpg',
      x2: './assets/image-library/ny.jpg',
      x3: './assets/image-library/ny.jpg',
    },
  },
  md: {
    src: './assets/image-library/zhangjiajie.jpg',
    srcSet: {
      x2: './assets/image-library/zhangjiajie.jpg',
      x3: './assets/image-library/zhangjiajie.jpg',
      x1: './assets/image-library/zhangjiajie.jpg',
    },
  },
  lg: {
    src: './assets/image-library/iphone.jpg',
    srcSet: {
      x1: './assets/image-library/iphone.jpg',
      x2: './assets/image-library/iphone.jpg',
    },
  },
  xl: {
    src: './assets/image-library/barcelona.jpg',
    srcSet: {
      x1: './assets/image-library/barcelona.jpg',
      x2: './assets/image-library/barcelona.jpg',
      x3: './assets/image-library/barcelona.jpg',
    },
  },
}
