import type { FC, ReactElement } from 'react'
import React, { useContext } from 'react'

import SimpleGrid from '@vfuk/core-simple-grid'
import { getDataSelector } from '@vfuk/core-base-props'

import LinkList from './components/LinkList'

import * as Styled from './styles/TopBar.style'

import type { TopBarProps } from './TopBar.types'

import { DataKeys } from '../../constants'
import TopNavigationContext from '../../context/TopNavigationContext'
import type { TopNavigationContextProps } from '../../context/TopNavigationContext.types'

const TopBar: FC<TopBarProps> = React.forwardRef(({ zIndex, dataSelectorPrefix }: TopBarProps, ref): ReactElement | null => {
  const { topNav, appearance, topNavigationTheme } = useContext(TopNavigationContext) as TopNavigationContextProps

  if (!topNav) return null

  const { leftLinks, rightLinks, srText } = topNav

  return (
    <Styled.TopBar
      aria-label={srText}
      ref={ref}
      zIndex={zIndex}
      appearance={appearance}
      topNavigationTheme={topNavigationTheme}
      data-selector={getDataSelector(dataSelectorPrefix)}
    >
      <Styled.Container>
        <SimpleGrid layout={{ sm: [11] }} justify='right'>
          <Styled.LinksWrapper>
            {leftLinks && (
              <LinkList links={leftLinks!} dataSelectorPrefix={getDataSelector(dataSelectorPrefix, `left-${DataKeys.LinkList}`)} />
            )}
            {rightLinks && (
              <LinkList links={rightLinks!} dataSelectorPrefix={getDataSelector(dataSelectorPrefix, `right-${DataKeys.LinkList}`)} />
            )}
          </Styled.LinksWrapper>
        </SimpleGrid>
      </Styled.Container>
    </Styled.TopBar>
  )
})

export default TopBar
