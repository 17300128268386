import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  TextInputWithLabel,
  CollapsibleContainer,
  CollapsibleContainerBody,
  Heading,
  GridRow,
  GridColumn,
} from 'r10-source-library';

import { updateTopError, updateTopUpValue, sendTags } from '../../../actions/Actions';
import useTranslation from '../../../hooks/useTranslation';
import {
  topUpErrorStatusSelector,
  topUpInputIsCollapsedSelector,
  topUpValueSelector,
} from '../../../selectors/selector';
import AmountButtons from './AmountButtons/AmountButtons';

const Root = styled.div`
  & #collapsible-container {
    padding: 2px;
  }
`;

const renderInputState = topUpErrorStatus => {
  switch (topUpErrorStatus) {
    case false:
      return 'success';

    case true:
      return 'error';

    default:
      return '';
  }
};

const renderInputStateText = (topUpErrorStatus, min, max, currency, t) =>
  topUpErrorStatus
    ? t('bodyTopupAmountError', { minNumber: min, maxNumber: max, currency: currency })
    : '';

export const isValidTopUpValue = (topUpValue, minValue, maxValue, step) =>
  topUpValue >= minValue && topUpValue <= maxValue && topUpValue % step === 0;

const Amount = ({
  currency,
  commonTopUpValues,
  minValue,
  maxValue,
  step,
  topUpInputIsCollapsed,
  topUpValue,
  topUpErrorStatus,
  updateTopUpError,
  updateTopUpVal,
  isFastTopupFlow,
  disableButtons,
  sendTags,
}) => {
  const TEXT_INPUT_ID = 'topup-amount';
  const t = useTranslation();
  useEffect(() => {
    const input = document.getElementById(TEXT_INPUT_ID);
    input.maxLength = maxValue.toString().length + 1;
  }, [maxValue]);

  useEffect(() => {
    if (!topUpInputIsCollapsed) document.getElementById(TEXT_INPUT_ID).focus();
  }, [topUpInputIsCollapsed]);

  const handleOnChange = useCallback(
    e => {
      const newValue = e.target.value;
      if (!newValue.match(currency)) {
        if (newValue.length === 1) {
          updateTopUpVal(0);
        } else {
          updateTopUpVal(newValue.substring(0, newValue.length - 1));
        }
      } else {
        updateTopUpVal(newValue);
      }
    },
    [currency, updateTopUpVal]
  );

  useEffect(() => {
    if (!isFastTopupFlow &&topUpValue !== 0) {
      updateTopUpError(!isValidTopUpValue(topUpValue, minValue, maxValue, step));
      if (!isValidTopUpValue(topUpValue, minValue, maxValue, step)) {
        sendTags({
          taggingId: 'CUSTOMER_TOPUP_6',
          taggingType: 'view',
          taggingValue: {},
        });
      }else{
        sendTags({
          taggingId: 'CUSTOMER_TOPUP_4',
          taggingType: 'link',
          taggingValue: {
            event_value: topUpValue,
          },
        });
      }
    } else {
      updateTopUpError(null);
    }
  }, [maxValue, minValue, step, topUpValue, updateTopUpError,isFastTopupFlow]);


  return (
    <Root>
      <GridRow justify="center" align="center" marginBottom={4} noGutters>
        <GridColumn>
          <Heading size={1} text={t('bodyTopupSelectAmount')} weight={3} justify="center" />
        </GridColumn>
      </GridRow>
      <GridRow justify="center" align="center" noGutters>
        <GridColumn>
          <AmountButtons
            minValue={minValue}
            step={step}
            currency={currency}
            commonTopUpValues={commonTopUpValues}
            isFastTopupFlow={isFastTopupFlow}
            disableButtons={disableButtons}
            isOtherValueInputOpen={!topUpInputIsCollapsed}
          />
        </GridColumn>
      </GridRow>
      <GridRow justify="center" align="center" marginTop={3} noGutters>
        <GridColumn>
          <CollapsibleContainer id="collapsible-container" isActive={!topUpInputIsCollapsed}>
            <CollapsibleContainerBody>
              <TextInputWithLabel
                state={renderInputState(topUpErrorStatus)}
                fieldWrapper={{
                  stateText: renderInputStateText(
                    topUpErrorStatus,
                    minValue,
                    maxValue,
                    currency,
                    t
                  ),
                  width: 'full',
                }}
                textInput={{
                  id: TEXT_INPUT_ID,
                  value: `${topUpValue || ''}${currency}`,
                  placeholder: currency,
                  onChange: handleOnChange,
                  disabled: disableButtons,
                }}
              />
            </CollapsibleContainerBody>
          </CollapsibleContainer>
        </GridColumn>
      </GridRow>
    </Root>
  );
};
const mapStateToProps = state => ({
  topUpInputIsCollapsed: topUpInputIsCollapsedSelector(state),
  topUpValue: topUpValueSelector(state),
  topUpErrorStatus: topUpErrorStatusSelector(state),
});

const mapDispatchToProps = dispatch => ({
  updateTopUpError: topUpHasError => dispatch(updateTopError(topUpHasError)),
  updateTopUpVal: topUpValue => dispatch(updateTopUpValue(topUpValue)),
  sendTags: tagsObject => dispatch(sendTags(tagsObject)),
});
Amount.propTypes = {
  currency: PropTypes.string,
  commonTopUpValues: PropTypes.array,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  step: PropTypes.number,
  topUpInputIsCollapsed: PropTypes.bool,
  topUpValue: PropTypes.number,
  topUpErrorStatus: PropTypes.bool,
  updateTopUpError: PropTypes.func,
  updateTopUpVal: PropTypes.func,
  isFastTopupFlow: PropTypes.bool,
  disableButtons: PropTypes.bool,
  sendTags: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(Amount);
