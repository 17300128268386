import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Heading,
  LinkWithIcon,
  Span,
  Container,
  GridColumn,
  GridRow,
  Icon,
  Spacing,
  Interaction,
  Paragraph,
  useBreakpoints,
} from 'r10-source-library';
import { AddressTray } from 'r10-common-components';
import jp from 'jsonpath';

import useTranslation from '../hooks/useTranslation';
import { setAddressID } from '../actions/Actions';
import { getAddressId } from '../selectors/selector';
import getTrayData from '../utils/getTrayData';
import useProps from '../hooks/useProps';
import getFullAddress from '../utils/getFullAddress';

const IconWrapper = styled(Interaction)`
  align-items: center;
  display: flex;
`;

const Wrapper = styled.div`
  & .gap {
    gap: 8px;
  }
`;

const TextWrapper = styled.div`
  & p {
    @media only screen and (max-width: 480px) {
      white-space: pre-line;
    }
  }
`;

/**
 * get the current add ID and Put ists product in the state
 * get all product under this billing account
 */
export default function App({
  config: {
    title,
    agentNamePath,
    address: { addressDefault },
    showEdit,
    showChevron,
    customerEditNavigationURL,
  },
  customerSearchComponent,
}) {
  const history = useHistory();
  const t = useTranslation();
  const dispatch = useDispatch();
  const { widgetData } = useProps();
  const breakPoint = useBreakpoints();
  const [isTrayOpen, setIsTrayOpen] = useState(false);
  const { customerDetails } = useSelector(state => state[customerSearchComponent] || {});
  const billingAccounts = customerDetails ? customerDetails[0]?.billingAccount : null;
  const addressId = useSelector(getAddressId) || 'All';

  const [selectedAddress, setSelectedAddress] = useState(addressDefault);
  const [selectedAddressId, setSelectedAddressId] = useState(addressId);

  const renderSelectedAddress = selectedAddressId => {
    let selectedAdd = '';
    if (selectedAddressId !== 'All') {
      selectedAdd = getFullAddress(
        widgetData,
        customerDetails && billingAccounts?.find(e => e.id === selectedAddressId)
      );
    } else selectedAdd = addressDefault;

    setSelectedAddress(selectedAdd);
  };

  useEffect(() => {
    renderSelectedAddress(selectedAddressId);
  }, [selectedAddressId]);

  useEffect(() => {
    setSelectedAddressId(addressId);
  }, [addressId]);

  useEffect(() => {
    if (billingAccounts && billingAccounts.length == 1) {
      dispatch(setAddressID(billingAccounts[0].id));
    }
  }, [billingAccounts]);

  const handleAddressChange = activeAddress => {
    setSelectedAddressId(activeAddress);
    dispatch(setAddressID(activeAddress));
    setIsTrayOpen(false);
  };
  const agentName = jp.value(customerDetails && customerDetails[0], agentNamePath);

  const renderBillingSelector = billingAccountsLength => {
    if (billingAccountsLength > 1) {
      return (
        <LinkWithIcon
          appearance="secondary"
          text={
            selectedAddressId !== 'All'
              ? t(`selectedBillingId`, { id: selectedAddressId })
              : t(selectedAddress)
          }
          dataAttributes={{ 'data-testid': 'addressSelector' }}
          icon={{
            name: selectedAddressId !== 'All' ? 'bill' : 'location',
            justify: 'left',
          }}
          state={billingAccounts.length === 1 && 'disabled'}
          {...(billingAccounts.length > 1 ? { onClick: () => setIsTrayOpen(true) } : {})}
          size={2}
        />
      );
    } else {
      return (
        <div data-testid="address">
          <GridRow noGutters>
            <Spacing spacingLevel={{ right: 2 }}>
              <Icon name={selectedAddressId !== 'All' ? 'bill' : 'location'} size={2} />
            </Spacing>
            <Paragraph size={2}>
              {selectedAddressId !== 'All'
                ? t(`selectedBillingId`, { id: selectedAddressId })
                : t(selectedAddress)}
            </Paragraph>
          </GridRow>
        </div>
      );
    }
  };

  return (
    <Wrapper>
      <Container paddingLevel={4} appearance="primary" elevation>
        <GridRow noGutters>
          <GridColumn col={12}>
            {title && (
              <Span appearance="alt2" weight={1} dataAttributes={{ 'data-testid': 'title' }}>
                {t(title)}
              </Span>
            )}
          </GridColumn>
        </GridRow>

        <GridRow noGutters justify="between" className={breakPoint.mdAndAbove && 'gap'}>
          <GridColumn col={breakPoint.sm ? 12 : 'auto'}>
            {agentName && (
              <Heading
                weight={1}
                size={3}
                text={agentName}
                dataAttributes={{ 'data-testid': 'agentNamePath' }}
              />
            )}
            {showEdit && (
              <LinkWithIcon
                appearance="primary"
                text={`${t('customerDetailsBtn')}`}
                dataAttributes={{ 'data-testid': 'editClick' }}
                icon={{
                  name: 'edit',
                  justify: 'right',
                }}
                onClick={() => history.push(customerEditNavigationURL)}
                size={2}
              />
            )}
          </GridColumn>
          <GridColumn col={breakPoint.sm ? 12 : 'auto'} alignSelf="center">
            <GridRow
              justify={breakPoint.sm ? 'start' : 'end'}
              noGutters
              marginTop={breakPoint.sm ? 4 : 0}
            >
              <GridColumn col={'auto'}>
                <Spacing
                  responsiveSizing={true}
                  spacingLevel={{
                    right: 3,
                  }}
                >
                  {billingAccounts?.length ? renderBillingSelector(billingAccounts.length) : null}
                </Spacing>
                {selectedAddressId !== 'All' && !!selectedAddress && (
                  <div data-testid="full-address">
                    <GridRow noGutters marginTop={2}>
                      <Spacing spacingLevel={{ right: 2 }}>
                        <Icon name={'location'} size={2} />
                      </Spacing>
                      <TextWrapper>
                        <Paragraph size={2}>{selectedAddress}</Paragraph>
                      </TextWrapper>
                    </GridRow>
                  </div>
                )}
              </GridColumn>

              <GridColumn col={'auto'}>
                {showChevron && customerDetails && billingAccounts?.length > 1 && (
                  <IconWrapper
                    onClick={() => setIsTrayOpen(true)}
                    dataAttributes={{ 'data-testid': 'addressSelector-icon' }}
                  >
                    <Icon name="chevron-right" group="system" appearance="secondary" />
                  </IconWrapper>
                )}
              </GridColumn>
            </GridRow>
          </GridColumn>
        </GridRow>
      </Container>
      <AddressTray
        isOpen={isTrayOpen}
        setIsOpen={setIsTrayOpen}
        data={getTrayData(billingAccounts, widgetData, t)}
        heading={t('switchBetweenAccounts')}
        handleChange={activeAddress => handleAddressChange(activeAddress)}
        selectedRowId={selectedAddressId}
      />
    </Wrapper>
  );
}

App.propTypes = {
  config: PropTypes.shape({
    title: PropTypes.string.isRequired,
    agentNamePath: PropTypes.string.isRequired,
    address: PropTypes.shape({
      addressTitlePath: PropTypes.string.isRequired,
      addressDefault: PropTypes.string.isRequired,
      firstStreet: PropTypes.string.isRequired,
      secondStreet: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
    }),
    showEdit: PropTypes.bool.isRequired,
    showChevron: PropTypes.bool.isRequired,
    customerEditNavigationURL: PropTypes.string.isRequired,
  }),
  addressId: PropTypes.string,
  customerSearchComponent: PropTypes.string,
};

App.defaultProps = {
  config: null,
};
