import type { ReactElement, FC } from 'react'
import React, { useContext, Fragment } from 'react'

import Link from '@vfuk/core-link'
import ListItem from '@vfuk/core-list-item'
import type { BaseProps } from '@vfuk/core-base-props'
import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/SideNavigationSecondaryList.style'

import TopNavigationContext from '../../../../context/TopNavigationContext'
import type { TopNavigationContextProps } from '../../../../context/TopNavigationContext.types'
import simpleClickHandler from '../../../../helpers/simpleClickHandler'

import { DataKeys } from '../../../../constants'

const SideNavigationSecondaryList: FC<BaseProps> = ({ dataSelectorPrefix }): ReactElement | null => {
  const { topNav, interactionHandler, appearance, topNavigationTheme } = useContext(TopNavigationContext) as TopNavigationContextProps

  if (!topNav) return null

  const { leftLinks, rightLinks } = topNav

  const { linkAppearance, inverseLink } = topNavigationTheme.appearance[appearance].sideTray.level[1]

  return (
    <Styled.SideNavigationSecondaryList data-selector={getDataSelector(dataSelectorPrefix)}>
      <>
        {leftLinks?.map((link) => {
          return (
            <Fragment key={link.id}>
              <ListItem>
                <Link
                  appearance={linkAppearance}
                  inverse={inverseLink}
                  text={link.text}
                  customRouterProps={link.customRouterProps}
                  href={link.href}
                  onClick={(event): void => simpleClickHandler(event, interactionHandler, dataSelectorPrefix, link)}
                  dataSelectorPrefix={getDataSelector(dataSelectorPrefix, `${DataKeys.SecondarySideNavigation}-${link.id}`)}
                />
              </ListItem>
              {link.active &&
                link.links &&
                link.links?.map((subLink) => {
                  return (
                    <ListItem key={subLink.id}>
                      <Link
                        appearance={linkAppearance}
                        inverse={inverseLink}
                        text={subLink.text}
                        customRouterProps={subLink.customRouterProps}
                        href={subLink.href}
                        onClick={(event): void => simpleClickHandler(event, interactionHandler, dataSelectorPrefix, subLink)}
                        dataSelectorPrefix={getDataSelector(dataSelectorPrefix, `${DataKeys.SecondarySideNavigation}-${link.id}`)}
                      />
                    </ListItem>
                  )
                })}
            </Fragment>
          )
        })}
        {rightLinks?.map((link) => {
          return (
            <ListItem key={link.id}>
              <Link
                appearance={linkAppearance}
                inverse={inverseLink}
                text={link.text}
                customRouterProps={link.customRouterProps}
                href={link.href}
                onClick={(event): void => simpleClickHandler(event, interactionHandler, dataSelectorPrefix, link)}
                dataSelectorPrefix={getDataSelector(dataSelectorPrefix, `${DataKeys.SecondarySideNavigation}-${link.id}`)}
              />
            </ListItem>
          )
        })}
      </>
    </Styled.SideNavigationSecondaryList>
  )
}

export default SideNavigationSecondaryList
