import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ModalContent } from 'r10-common-components';

import useTranslation from '../../hooks/useTranslation';
import {
  phoneNumberSelector,
  topUpValueSelector,
  paymentMethodSelector,
  propSelector,
} from '../../selectors/selector';
import { closeTopup, resetTopUp, sendTags } from '../../actions/Actions';

const Success = ({
  phoneNumber,
  topUpValue,
  currency,
  dispatchResetTopUp,
  isFastTopupFlow,
  closeTopupOverlay,
  paymentMethod,
  customerFullName,
  successRedirectUrl,
  showSuccessScreenRedirectButton,
  history,
  storeId,
  sendTags,
}) => {
  const t = useTranslation();
  const handleClick = () => {
    dispatchResetTopUp();
    closeTopupOverlay();
    history.push(successRedirectUrl);
  };
  useEffect(() => {
    if (!isFastTopupFlow)
      sendTags({
        taggingId: 'CUSTOMER_TOPUP_11',
        taggingType: 'view',
        taggingValue: {
          transaction_store_id: storeId,
          transaction_total: topUpValue,
        },
      });
  }, [isFastTopupFlow, storeId, topUpValue, sendTags]);
  return (
    <ModalContent
      header={t('headerTopUp')}
      icon="tick-outline"
      iconMarginBottom={4}
      title={t('successMessage')}
      titleTestId={t('success-topup-title')}
      subtitles={[
        {
          regular: `${customerFullName} - `,
          bold: `${phoneNumber}`,
          marginBottom: 4,
          regularTestId: 'success-topup-customer-name',
          boldTestId: 'success-topup-phone-number',
        },
        {
          regular: `${t('successAmount')}: `,
          bold: `${topUpValue}${currency || ''}`,
          regularTestId: 'success-topup-amount-label',
          boldTestId: 'success-topup-amount',
        },
        {
          regular: `${t('paymentMethod')}: `,
          bold: t(paymentMethod),
          marginBottom: 7,
          regularTestId: 'success-topup-payment-method-label',
          boldTestId: 'success-topup-payment-method',
        },
      ]}
      primaryButton={{
        text: t('successDone'),
        onClick: () => {
          closeTopupOverlay();
        },
        dataTestId: 'done-button',
      }}
      secondaryButton={
        showSuccessScreenRedirectButton && {
          text: t('buyBundles'),
          onClick: handleClick,
          dataTestId: 'buy-bundles-button',
        }
      }
    />
  );
};

const mapStateToProps = state => ({
  topUpValue: topUpValueSelector(state),
  phoneNumber: phoneNumberSelector(state),
  paymentMethod: paymentMethodSelector(state),
  successRedirectUrl: propSelector(state, 'successScreenRedirectUrl'),
  showSuccessScreenRedirectButton: propSelector(state, 'showSuccessScreenRedirectButton'),
});
const mapDispatchToProps = dispatch => ({
  dispatchResetTopUp: () => dispatch(resetTopUp()),
  closeTopupOverlay: () => dispatch(closeTopup()),
  sendTags: tagsObject => dispatch(sendTags(tagsObject)),
});
Success.propTypes = {
  phoneNumber: PropTypes.string,
  topUpValue: PropTypes.number,
  currency: PropTypes.string,
  paymentMethod: PropTypes.string,
  dispatchResetTopUp: PropTypes.func,
  closeTopupOverlay: PropTypes.func,
  isFastTopupFlow: PropTypes.bool,
  customerFullName: PropTypes.string.isRequired,
  successRedirectUrl: PropTypes.string.isRequired,
  showSuccessScreenRedirectButton: PropTypes.bool,
  history: PropTypes.func,
  storeId: PropTypes.string,
  sendTags: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Success);
