import type { InteractionEvent } from '@vfuk/core-interaction'
import { getDataSelector } from '@vfuk/core-base-props'

import type { Link } from '../../TopNavigation.types'
import type { TopNavigationContextProps } from '../../context/TopNavigationContext.types'

function simpleClickHandler(
  event: InteractionEvent,
  interactionHandler: TopNavigationContextProps['interactionHandler'],
  dataSelectorPrefix: TopNavigationContextProps['dataSelectorPrefix'],
  link: Link,
): void {
  interactionHandler(event, getDataSelector(dataSelectorPrefix, link.id) ?? '')
  if (!link.onClick) return
  link.onClick()
}

export default simpleClickHandler
