import { Heading, Spacing } from 'r10-source-library';
import React, { useEffect, useState } from 'react';
import { getSelectedAddressID, getServices } from '../selectors/selector';
import { useDispatch, useSelector } from 'react-redux';

import CardsWrapper from './CardsWrapper';
import Filters from './Filters';
import PropTypes from 'prop-types';
import ShowMore from './ShowMore';
import manipulateResponse from '../utils/manipulateResponse';
import { setAddressID } from '../actions/Actions';
import EmptyProducts from './EmptyProducts';
import useTranslation from '../hooks/useTranslation';

// Objective: handling the prepaid product redirection
// Use the productSpecification instead of prod

// idea1: search in the productSpecification for the product type => just add a util function
// idea2: change the implementation => check all the implementation

export default function App(props) {
  const t = useTranslation();
  const dispatch = useDispatch();
  const availableServices = useSelector((state) => getServices(state));
  const selectedAddressID = useSelector((state) => getSelectedAddressID(state));
  const [maxCardsShouldAppear, setMaxCardsShouldAppear] = useState(
    props.maxCardsShouldAppear,
  );
  const [data, setData] = useState({ bundles: [], services: [] });
  const [filteredData, setFilteredData] = useState({
    bundles: [],
    services: [],
  });
  const { MinProductsNumber, MultiSelect, filtersArray, defaultFilterIndex } =
    props.filters;
  const [selectedFilters, setSelectedFilters] = useState([]);

  const totalProductsCount = [...data.bundles, ...data.services].length;

  const showFilters = MinProductsNumber <= totalProductsCount;

  const [noProductsFlag, setNoProductsFlag] = useState(false);

  /**
   * Check if the service should be shown
   * @param {string} type
   * @returns {boolean}
   */
  const serviceShouldVisible = (type) =>
    selectedFilters.some((filter) => filter === type || filter === 'all');

  /**
   * Check if filters have products if not removed the tag
   * @returns {string[]}
   */

  const getFilteredArray = filtersArray.filter((el) =>
    data.services.findIndex((service) => service.planType === el.key) !== -1 ||
    (el.key === 'packages' && data.bundles.length > 0) ||
    el.key === 'all'
      ? el
      : null,
  );

  /**
   * Filter data according to the selected filters
   */
  const filterData = () => {
    const showPackage =
      selectedFilters.includes('packages') || selectedFilters.includes('all');

    const bundles = showPackage ? data.bundles : [];
    const services = data.services.filter((service) =>
      serviceShouldVisible(service.planType),
    );

    setFilteredData({ bundles, services });
  };
  useEffect(() => {
    if (selectedAddressID === null || selectedAddressID === 'All')
      dispatch(setAddressID(''));
  }, []);

  useEffect(() => {
    if (availableServices.length === 0) {
      setNoProductsFlag(true);
      return;
    }
    setNoProductsFlag(false);
    const { bundles, services } = manipulateResponse(
      availableServices,
      props.icons,
    );
    setData({ bundles, services });
    setSelectedFilters([filtersArray[defaultFilterIndex].key]);
  }, [selectedAddressID, availableServices.length]);

  useEffect(() => {
    filterData();
  }, [selectedFilters]);

  return (
    <>
      <Spacing spacingLevel={{ bottom: 8 }}>
        <Heading
          dataAttributes={{ 'data-testid': 'products-and-services-title' }}
          weight={3}
          level={4}
          text={t('title')}
        />
      </Spacing>

      {noProductsFlag ? (
        <EmptyProducts />
      ) : (
        <>
          <Filters
            show={showFilters}
            isMultiSelect={MultiSelect}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            filteredOptions={getFilteredArray}
          />
          <CardsWrapper
            maxCardsShouldAppear={maxCardsShouldAppear}
            bundles={filteredData.bundles}
            services={filteredData.services}
            showServiceLabel={props.visibility.serviceLabel}
            redirection={props.redirection}
            selectedFilters={selectedFilters}
            serviceURL={props.redirection.serviceURL}
          />

          <ShowMore
            listAllCards={() => setMaxCardsShouldAppear(Infinity)}
            show={
              [...filteredData.bundles, ...filteredData.services].length >
              maxCardsShouldAppear
            }
          />
        </>
      )}
    </>
  );
}

App.propTypes = {
  icons: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,

  redirection: PropTypes.shape({
    prepaidService: PropTypes.bool.isRequired,
    postpaidService: PropTypes.bool.isRequired,
    serviceURL: PropTypes.string.isRequired,
  }).isRequired,

  visibility: PropTypes.shape({
    serviceLabel: PropTypes.bool.isRequired,
  }).isRequired,

  filters: PropTypes.shape({
    MinProductsNumber: PropTypes.number.isRequired,
    MultiSelect: PropTypes.bool.isRequired,
    defaultFilterIndex: PropTypes.number.isRequired,
    filtersArray: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
      }),
    ).isRequired,
  }),

  maxCardsShouldAppear: PropTypes.number.isRequired,
};
