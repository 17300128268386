export default function isRedirectionActive(redirectionFlags, serviceType) {
  const redirectionNames = {
    prepaid: 'prepaidService',
    PrePay: 'prepaidService',
    postpaid: 'postpaidService',
    PostPay: 'postpaidService',
  };

  const redirectionName = redirectionNames[serviceType];

  return redirectionFlags[redirectionName];
}
