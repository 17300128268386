import type { FC } from 'react'
import React, { useContext } from 'react'

import type { BaseProps } from '@vfuk/core-base-props'
import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/SecondaryNav.style'

import IconNav from './components/IconNav'
import UserIcon from './components/UserIcon'
import SearchIcon from './components/SearchIcon'

import TopNavigationContext from '../../../../context/TopNavigationContext'
import type { TopNavigationContextProps } from '../../../../context/TopNavigationContext.types'

const SecondaryNav: FC<BaseProps> = ({ dataSelectorPrefix }: BaseProps) => {
  const { secondaryNav } = useContext(TopNavigationContext) as TopNavigationContextProps

  return (
    <Styled.SecondaryNav data-selector={getDataSelector(dataSelectorPrefix)}>
      <>{secondaryNav?.iconNavLinks && <IconNav dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'icon-nav')} />}</>
      <>{secondaryNav?.search && <SearchIcon dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'search-icon')} />}</>
      <>{secondaryNav?.user && <UserIcon dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'user-icon')} />}</>
    </Styled.SecondaryNav>
  )
}

export default SecondaryNav
