import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { elevation } from '@vfuk/core-mixins'

import type { StyledTopNavigationProps } from './TopNavigation.style.types'

export const TopNavigation = styled.header(
  (props: StyledTopNavigationProps): FlattenSimpleInterpolation => css`
    ${elevation(true)};
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: ${props.zIndex + 1};
  `,
)
