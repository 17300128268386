import { SCREENS } from '../appConstants';

const initialState = {
  phoneNumber: '',
  phoneNumbers: [],
  phoneNumberErrorStatus: null,
  phoneNumberNotFound: null,
  topUpInputIsCollapsed: true,
  topUpValue: 0,
  minTopUpValue: 0,
  customerFullName: '',
  topUpErrorStatus: null,
  currentScreen: SCREENS.TOP_UP,
  storeBucket: null,
  isOpen: false,
  paymentMethod: '',
};

export default initialState;
