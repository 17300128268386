import type { FC } from 'react'
import React, { useContext } from 'react'

import InteractiveIcon from '@vfuk/core-interactive-icon'
import type { InteractionEvent } from '@vfuk/core-interaction'
import type { BaseProps } from '@vfuk/core-base-props'
import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/SearchIcon.style'

import { DataKeys } from '../../../../../../constants'

import TopNavigationContext from '../../../../../../context/TopNavigationContext'
import type { TopNavigationContextProps } from '../../../../../../context/TopNavigationContext.types'

const SearchIcon: FC<BaseProps> = ({ dataSelectorPrefix }) => {
  const { secondaryNav, setShowSearch, setNavLevel, interactionHandler, appearance, topNavigationTheme } = useContext(
    TopNavigationContext,
  ) as TopNavigationContextProps

  const clickHandler = (event: InteractionEvent): void => {
    setNavLevel(0)
    setShowSearch(true)
    interactionHandler(event, DataKeys.OpenSearch)
  }

  const { interactiveIconAppearance, inverseIcon } = topNavigationTheme.appearance[appearance].mainBar

  return (
    <Styled.SearchIcon data-selector={getDataSelector(dataSelectorPrefix)}>
      <InteractiveIcon
        appearance={interactiveIconAppearance}
        inverse={inverseIcon}
        id={secondaryNav!.search!.id}
        srText={secondaryNav!.search!.srText}
        name='search'
        onClick={clickHandler}
        dataSelectorPrefix={getDataSelector(dataSelectorPrefix, DataKeys.OpenSearch)}
      />
    </Styled.SearchIcon>
  )
}

export default SearchIcon
