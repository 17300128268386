import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { SimpleGridStyles } from '@vfuk/core-simple-grid'
import { iconButtonTheme } from '@vfuk/core-icon-button'
import { advancedSpacing, elevation, respondTo, responsiveCalc, responsivePosition, spacing } from '@vfuk/core-mixins'

import type { StyledSideNavigationProps } from './SideNavigation.style.types'

type SideNavigationTheme = Pick<StyledSideNavigationProps, 'theme'>

export const Spacer = styled.div((): FlattenSimpleInterpolation => [])

type SideNavigationProps = Pick<StyledSideNavigationProps, 'topOffset' | 'zIndex'>
export const SideNavigation = styled.div(
  (props: SideNavigationProps): FlattenSimpleInterpolation => css`
    position: fixed;
    top: ${props.topOffset}px;
    left: 0;
    bottom: 0;
    display: flex;
    width: 100%;
    overflow: hidden;
    pointer-events: none;
    z-index: ${props.zIndex};
  `,
)

export const Container = styled.div((props: SideNavigationTheme): FlattenSimpleInterpolation => {
  const iconButtonLocal = iconButtonTheme(props.theme)
  return css`
    width: calc((100% - ${iconButtonLocal.size[4].sm.buttonSize}px) - ${props.theme.spacing.responsive[2].sm * 2}px);
    max-width: ${props.theme.gridConfig.springWidth}px;
    display: flex;
    position: relative;
    ${elevation(true)};

    ${respondTo.md(css`
      margin: 0 auto;
      width: 100%;
      ${elevation(false)};
    `)}

    ${SimpleGridStyles.SimpleGrid} {
      width: 100%;
      position: relative;
      overflow: hidden;

      ${respondTo.md(css`
        overflow: unset;
      `)}
    }

    ${SimpleGridStyles.SimpleGridColumn} {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;

      ${respondTo.md(css`
        position: relative;
        display: flex;
        height: 100%;
      `)}
    }
  `
})

type PrimaryNavContainerProps = Pick<StyledSideNavigationProps, 'theme' | 'zIndex' | 'topNavigationTheme' | 'appearance'>
export const PrimaryNavContainer = styled.div<PrimaryNavContainerProps>(
  (props: PrimaryNavContainerProps): FlattenSimpleInterpolation => css`
    background: ${props.topNavigationTheme.appearance[props.appearance].sideTray.level[1].backgroundColor};
    position: relative;
    ${elevation(true)};
    border-radius: 0 0 ${props.theme.border.radius[2]} 0;
    ${spacing('padding', 4)};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    pointer-events: auto;
    z-index: ${props.zIndex + 1};

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 100%;
      left: -10000px;
      bottom: 0;
      background: #fff;
    }
  `,
)

type SecondaryNavContainerProps = Pick<StyledSideNavigationProps, 'theme' | 'zIndex' | 'appearance' | 'topNavigationTheme'>
export const SecondaryNavContainer = styled.div<SecondaryNavContainerProps>(
  (props: SecondaryNavContainerProps): FlattenSimpleInterpolation => {
    return css`
      position: relative;
      display: flex;
      align-items: stretch;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      background: ${props.topNavigationTheme.appearance[props.appearance].sideTray.level[2].backgroundColor};
      ${advancedSpacing('padding', [4, 4, 4, 8])};
      ${elevation(true)};
      ${spacing('margin-left', -3)};
      ${responsiveCalc('width', '100%', '+', 3)};
      border-radius: 0 0 ${props.theme.border.radius[2]} 0;
      pointer-events: auto;
      z-index: ${props.zIndex};
    `
  },
)

type NavContentsProps = Pick<StyledSideNavigationProps, 'isTransitioning' | 'animationDuration'>
export const NavContents = styled.div(
  (props: NavContentsProps): FlattenSimpleInterpolation => css`
    ${spacing('gap', 4)};
    transition: ${props.animationDuration / 1000}s all ease;
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: column;
    flex-grow: 1;

    ${props.isTransitioning &&
    css`
      opacity: 0;
      transform: translateX(50px);

      ${respondTo.md(css`
        transform: translateX(-50px);
      `)}
    `}
  `,
)

type CloseButtonProps = Pick<StyledSideNavigationProps, 'theme' | 'closeButtonPosition' | 'animationDuration' | 'zIndex'>
export const CloseButton = styled.div<CloseButtonProps>(
  (props: CloseButtonProps): FlattenSimpleInterpolation => css`
    position: absolute;
    ${responsivePosition('top', 2)};
    z-index: ${props.zIndex + 2};
    pointer-events: auto;
    transition: ${props.animationDuration / 1000}s all ease;
    left: calc(100% + ${props.theme.spacing.responsive[2].sm}px);

    ${respondTo.md(css`
      left: calc(((100% / 12) * 5) + ${props.theme.spacing.responsive[2].md}px);
    `)}

    ${respondTo.lg(css`
      left: calc(((100% / 12) * 6) + ${props.theme.spacing.responsive[2].lg}px);
    `)}

  ${props.closeButtonPosition > 1 &&
    css`
      ${respondTo.md(css`
        left: calc(((100% / 12) * 10) + ${props.theme.spacing.responsive[2].md}px);
      `)}

      ${respondTo.lg(css`
        left: calc(((100% / 12) * 9) + ${props.theme.spacing.responsive[2].lg}px);
      `)}

    transform: rotate(180deg);
    `}
  `,
)
