import React from 'react';
import { Spacing, Heading, Icon, SimpleGrid } from 'r10-source-library';
import styled from 'styled-components';
import useTranslation from '../hooks/useTranslation';

const ContainerWrapper = styled.div`
  width: 100%;
  margin-top: 30px;
`;

const EmptyProducts = () => {
  const t = useTranslation();
  return (
    <ContainerWrapper data-testid="no-products">
      <SimpleGrid justify="center" spacing={0}>
        <Icon
          dataAttributes={{ 'data-testid': 'tablet-mobile-icon' }}
          name={'tablet-mobile'}
          group="hifiLight"
          size={5}
        />
      </SimpleGrid>

      <Spacing spacingLevel={{ top: 8 }}>
        <SimpleGrid
          spacing={0}
          layout={{ sm: [10], md: [12] }}
          justify="center"
        >
          <Heading size={3} weight={2} justify="center">
            <span data-testid="no-products-title">{t('noProducts')}</span>
          </Heading>
        </SimpleGrid>
      </Spacing>
    </ContainerWrapper>
  );
};
export default EmptyProducts;
