import React from 'react';
import useTranslation from '../hooks/useTranslation';
import { GridRow, Spacing, Span, Link } from 'r10-source-library';
import PropTypes from 'prop-types';

export default function ShowMore(props) {
  const t = useTranslation();

  if (!props.show) return <></>;

  const onClickHandler = () => {
    props.listAllCards();
  };

  return (
    <GridRow justify="center">
      <Spacing spacingLevel={{ top: 4 }}>
        <Link
          onClick={onClickHandler}
          dataAttributes={{ 'data-testid': 'show-more-button' }}
        >
          <Span appearance="secondary">{t('showMore')}</Span>
        </Link>
      </Spacing>
    </GridRow>
  );
}

ShowMore.propTypes = {
  listAllCards: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};
