import type { Theme } from '@vfuk/core-themes'

import type { SkipLinkTheme } from './SkipLink.theme.types'

const defaultTheme = (theme: Theme): SkipLinkTheme => {
  return {
    appearance: {
      primary: {
        backgroundColor: theme.color.primary1.default,
        color: theme.color.monochrome1.default,
      },
    },
  }
}

export default defaultTheme
