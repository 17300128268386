import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { spacing } from '@vfuk/core-mixins'

import ListGroup from '@vfuk/core-list-group'

export const SideNavigationSecondaryList = styled(ListGroup)(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    flex-direction: column;
    ${spacing('gap', 2)};
  `,
)
