import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { AnimateStyles } from '@vfuk/core-animate'
import { advancedSpacing, respondTo, elevation } from '@vfuk/core-mixins'

import type { StyledMainBarProps } from './MainBar.style.types'

export const MainBar = styled.header<StyledMainBarProps>(
  (props: StyledMainBarProps): FlattenSimpleInterpolation => css`
    background-color: ${props.topNavigationTheme.appearance[props.appearance].mainBar.backgroundColor};
    ${advancedSpacing('padding', [3, 0])};
    ${elevation(true)};
    overflow: hidden;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: ${props.zIndex + 1};
  `,
)

type ContainerProps = Pick<StyledMainBarProps, 'theme'>
export const Container = styled.div(
  (props: ContainerProps): FlattenSimpleInterpolation => css`
    width: 100%;
    max-width: ${props.theme.gridConfig.springWidth}px;
    margin: 0 auto;
  `,
)

export const NavWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    position: relative;
    display: flex;
    align-items: center;
    height: 44px;

    ${respondTo.md(css`
      height: 46px;
    `)}

    ${AnimateStyles.Animate} {
      flex-grow: 1;
    }
  `,
)

export const MainBarContents = styled.nav(
  (): FlattenSimpleInterpolation => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
)
