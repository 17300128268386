import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { advancedSpacing } from '@vfuk/core-mixins'

import ListGroup from '@vfuk/core-list-group'
import { ListItemStyles } from '@vfuk/core-list-item'

export const SecondaryNav = styled(ListGroup)(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    align-items: center;
    margin-left: auto;

    ${ListItemStyles.ListItem} {
      ${advancedSpacing('padding', [0, 4])};
      display: flex;
    }
  `,
)
