import axios from 'axios';

const storeBucketAPI = async (url) => {
  try {
    const { data } = await axios.get(url);
    return data;
  } catch (error) {
    throw new Error(error.response?.message);
  }
};

export default storeBucketAPI;
