let bundledServices;
let icons;

function getIcon(key) {
  return icons.find((icon) => icon.key === key)?.value;
}

// productCharacteristic
function getProductCharacteristic(data, name) {
  return data.productCharacteristic.find(
    (item) => item.name.toLocaleLowerCase() === name.toLocaleLowerCase(),
  )?.value;
}

function manipulateService(service) {
  const type = getProductCharacteristic(service, 'type');
  const planType = service.productSpecification.name;
  return {
    id: service.id,
    name: service.productOffering.name,
    icon: getIcon(type?.toLocaleLowerCase()),
    type,
    status: service.status,
    planType,
    msisdn: service.id,
    isVisible: service.isCustomerVisible || true,
  };
}

function getBundleServices(bundle, data) {
  bundledServices = bundle.productRelationship.map((item) => item.product.id);
  const type = bundle.isBundle
    ? 'bundle'
    : getProductCharacteristic(bundle, 'type');
  const services = data.reduce((acc, service) => {
    const inBundle = bundledServices.includes(service.id);
    const isVisible = service.isCustomerVisible || true;
    if (inBundle && isVisible) acc.push(manipulateService(service));

    return acc;
  }, []);

  return {
    id: bundle.id,
    name: bundle.productOffering.name,
    icon: getIcon(type?.toLocaleLowerCase()),
    type,
    isBundle: bundle.isBundle,
    isVisible: bundle.isCustomerVisible,
    services,
  };
}

function getUnbundledServices(services) {
  return services.reduce((acc, service) => {
    const unBundle = !bundledServices?.includes(service.id);
    const isVisible = service.isCustomerVisible || true;

    if (unBundle && !service.isBundle && isVisible)
      acc.push(manipulateService(service));

    return acc;
  }, []);
}

function getBundles(data) {
  return data.reduce((acc, item) => {
    if (item.isBundle && item.productRelationship?.length > 0) {
      acc.push(getBundleServices(item, data));
    }

    return acc;
  }, []);
}

export default function manipulateResponse(data, iconsMap) {
  icons = iconsMap;

  const bundles = getBundles(data);
  const services = getUnbundledServices(data);

  return { bundles, services };
}
