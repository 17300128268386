import {
  GET_CUSTOMER_SEARCH_DATA_SUCCESS,
  setAddressID,
  DEFAULT_BILLING_ADDRESS_CHANGE,
} from '../actions/Actions';
import { takeEvery, put } from 'redux-saga/effects';

function* onSuccessCustomerSearch() {
  try {
    yield put(setAddressID(''));
  } catch (e) {
    console.log(e);
  }
}

export function* watchOnSuccessCustomerSearch() {
  yield takeEvery(GET_CUSTOMER_SEARCH_DATA_SUCCESS, onSuccessCustomerSearch);
}

function* onDefaultAddressChange({ payload }) {
  try {
    yield put(setAddressID(payload.id));
  } catch (e) {
    console.log(e);
  }
}

export function* watchDefaultAddressChange() {
  yield takeEvery(DEFAULT_BILLING_ADDRESS_CHANGE, onDefaultAddressChange);
}
