import React from 'react';
import PropTypes from 'prop-types';

import {
  Grid,
  GridRow,
  Icon,
  Span,
  Spacing,
  SimpleGrid,
  Paragraph,
} from 'r10-source-library';

import styled from 'styled-components';
import { useHistory } from 'react-router';

const ServiceInfo = styled.div`
  flex: 1;
`;

export default function PackageService(props) {
  const history = useHistory();

  const redirectionHandle = () => {
    if (props.isRedirectionActive) {
      history.push(props.serviceURL);
    }
  };

  return (
    <div onClick={redirectionHandle}>
      <Spacing spacingLevel={{ bottom: 2 }}>
        <Grid>
          <GridRow>
            <SimpleGrid verticalAlign="center">
              <Icon
                dataAttributes={{ 'data-testid': 'package-service-icon' }}
                name={props.icon}
                size={3}
                group="system"
              />
            </SimpleGrid>

            <ServiceInfo>
              <Spacing spacingLevel={{ left: 4 }}>
                <Grid>
                  <GridRow>
                    <Paragraph
                      dataAttributes={{
                        'data-testid': 'package-service-name',
                      }}
                      size={1}
                      appearance="secondary"
                    >
                      {props.name}
                    </Paragraph>
                  </GridRow>
                  <GridRow>
                    <Span
                      dataAttributes={{
                        'data-testid': 'package-service-msisdn',
                      }}
                      appearance="alt2"
                      weight={2}
                    >
                      {props.msisdn}
                    </Span>
                  </GridRow>
                </Grid>
              </Spacing>
            </ServiceInfo>

            {props.isRedirectionActive ? (
              <SimpleGrid verticalAlign="center">
                <Icon name="chevron-right" state="error" size={3} />
              </SimpleGrid>
            ) : null}
          </GridRow>
        </Grid>
      </Spacing>
    </div>
  );
}

PackageService.propTypes = {
  id: PropTypes.string.isRequired,
  isRedirectionActive: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  msisdn: PropTypes.string.isRequired,
  serviceURL: PropTypes.string.isRequired,
};
