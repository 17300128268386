import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ModalContent } from 'r10-common-components';

import useTranslation from '../../../hooks/useTranslation';
import {
  closeTopup,
  sendTags,
  updateCurrentScreen,
  updatePhoneNumberErrorStatus,
} from '../../../actions/Actions';
import { connect } from 'react-redux';
import { SCREENS } from '../../../appConstants';
import { propSelector } from '../../../selectors/selector';

const GenericError = ({ updateCurrScreen, updateNumberErrorStatus, isFastTopupFlow, sendTags }) => {
  const t = useTranslation();
  useEffect(() => {
    if (!isFastTopupFlow)
      sendTags({
        taggingId: 'CUSTOMER_TOPUP_12',
        taggingType: 'view',
        taggingValue: {
          event_value: t('failureWrong'),
        },
      });
  }, [isFastTopupFlow]);
  const onTryAgain = useCallback(() => {
    updateNumberErrorStatus(false);
    updateCurrScreen(SCREENS.TOP_UP);
  }, [updateCurrScreen, updateNumberErrorStatus]);
  return (
    <ModalContent
      header={t('headerTopUp')}
      icon="warning"
      title={t('failureWrong')}
      titleTestId="topup-failure-title"
      subtitles={[
        { regular: t('failureTryRequest'), regularTestId: 'topup-failure-try-request-text' },
        {
          regular: t('failureTryAgain'),
          marginBottom: 9,
          regularTestId: 'topup-failure-try-again-recommended-action',
        },
      ]}
      primaryButton={{
        text: t('failureTryAgainButton'),
        onClick: onTryAgain,
        dataTestId: 'tryAgain-button',
      }}
    />
  );
};
const mapStateToProps = state => ({
  redirectUrl: propSelector(state, 'redirectUrl'),
});
const mapDispatchToProps = dispatch => ({
  updateCurrScreen: currentScreen => dispatch(updateCurrentScreen(currentScreen)),
  updateNumberErrorStatus: phoneNumberErrorStatus =>
    dispatch(updatePhoneNumberErrorStatus(phoneNumberErrorStatus)),
  closeTopup: () => dispatch(closeTopup()),
  sendTags: tagsObject => dispatch(sendTags(tagsObject)),
});
GenericError.propTypes = {
  updateCurrScreen: PropTypes.func,
  updateNumberErrorStatus: PropTypes.func,
  closeTopup: PropTypes.func,
  isFastTopupFlow: PropTypes.bool,
  sendTags: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(GenericError);
