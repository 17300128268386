import React from 'react';
import PropTypes from 'prop-types';
import { Spacing, Grid, GridRow, themeWS10 } from 'r10-source-library';
import styled from 'styled-components';
import FilterTag from './FilterTag';

const FiltersWrapper = styled(GridRow)`
  @media (max-width: ${themeWS10.breakpoints.md}px) {
    overflow: scroll;
    flex-wrap: nowrap;
  }
`;

export default function Filters(props) {
  if (!props.show) return <></>;

  /**
   * Update the selected filters according to the filter key
   *
   * @param {string} filterKey
   * @returns
   */
  const handleSelectedFilterChange = (filterKey) => {
    if (props.selectedFilters.includes(filterKey)) return;

    if (props.isMultiSelect) {
      props.setSelectedFilters(props.selectedFilters.concat(filterKey));
    } else {
      props.selectedFilters.splice(0, 1, filterKey);
      props.setSelectedFilters([...props.selectedFilters]);
    }
  };

  return (
    <Spacing spacingLevel={{ bottom: 5 }}>
      <Grid dataAttributes={{ 'data-testid': `filters` }}>
        <FiltersWrapper align="start">
          {props.filteredOptions.map((filter) => (
            <Spacing spacingLevel={{ right: 2 }} key={filter.key}>
              <FilterTag
                key={filter.key}
                title={filter.key}
                isActive={props.selectedFilters.includes(filter.key)}
                handleChange={() => handleSelectedFilterChange(filter.key)}
              />
            </Spacing>
          ))}
        </FiltersWrapper>
      </Grid>
    </Spacing>
  );
}

Filters.propTypes = {
  show: PropTypes.bool.isRequired,
  isMultiSelect: PropTypes.bool.isRequired,
  selectedFilters: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  filteredOptions: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.string, value: PropTypes.string }),
  ).isRequired,
};
