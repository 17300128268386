import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Container, Grid, GridRow, Heading, Spacing, Loader } from 'r10-source-library';
import styled from 'styled-components';

import LeftSection from './LeftSection';
import RightSection from './RightSection';
import useTranslation from '../../hooks/useTranslation';
import { isFetching, error } from '../../selectors/selector';
import { getAgentInfoRequest, sendTags } from '../../actions';
import useProps from '../../hooks/useProps';
import { ErrorContent } from 'r10-common-components';

const AgentInfoCardWrapper = styled.div`
  > div {
    padding: 0;
  }
`;

const ContentCard = styled.div`
  min-height: 120px;
`;

export default function AgentInfoCard(props) {
  const t = useTranslation();
  const isLoading = useSelector(isFetching);
  const failureError = useSelector(error);
  const dispatch = useDispatch();
  const { organizationUrl, agentPathsData } = useProps();

  const renderCardContent = () => {
    if (failureError) {
      return (
        <div data-testid="error">
          <ErrorContent
            icon="clock"
            title={t('timeOut')}
            description={t('errorMessage')}
            buttonText={t('actionButtonText')}
            iconSize={1}
            buttonAction={() =>{
              dispatch(
                getAgentInfoRequest({
                  orgUrl: organizationUrl,
                  agentPathsData: agentPathsData,
                })
              );
              dispatch(
                sendTags({
                  taggingId: 'AGENT_BASIC_INFO_3',
                  taggingType: 'link',
                  taggingValue: {},
                })
              );
            }
          }
          />
        </div>
      );
    }
    return (
      <GridRow>
        <LeftSection />
        <RightSection
          selectedStore={props.selectedStore}
          openTray={props.openTray}
          showChangeLocation={props.showChangeLocation}
          bucketAmount={props.bucketAmount}
        />
      </GridRow>
    );
  };
  return (
    <AgentInfoCardWrapper data-testid="agent-info-card">
      <Grid>
        <Spacing spacingLevel={{ bottom: 10 }}>
          <Heading size={3} dataAttributes={{ 'data-testid': 'agent-info-heading' }}>
            {t('heading')}
          </Heading>
        </Spacing>
        <Spacing spacingLevel={{ bottom: 6 }}>
          <Heading size={2} weight={3} dataAttributes={{ 'data-testid': 'agent-info-title' }}>
            {t('title')}
          </Heading>
        </Spacing>
        <Container elevation paddingLevel={6}>
          <ContentCard>
            {isLoading ? (
              <Loader
                text={t('loading')}
                size={1}
                srText="Loading"
                dataAttributes={{ 'data-testid': 'agentBasicInfo-loader' }}
              />
            ) : (
              renderCardContent()
            )}
          </ContentCard>
        </Container>
      </Grid>
    </AgentInfoCardWrapper>
  );
}
