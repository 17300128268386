import { pkgName } from '../appConstants';

const getPkgSlice = state => state[pkgName];

export const phoneNumberSelector = state => getPkgSlice(state)?.phoneNumber;

export const phoneNumbersSelector = state =>
  state?.props?.topupList?.phoneNumbers.map(item => ({
    text: item.phoneNumber,
    value: item.phoneNumber,
  }));

export const phoneNumberErrorStatusSelector = state => getPkgSlice(state)?.phoneNumberErrorStatus;

export const customerFullNameSelector = state => getPkgSlice(state)?.customerFullName;

export const phoneNumberNotFoundSelector = state => getPkgSlice(state)?.phoneNumberNotFound;

export const accessTokenSelector = state => state.authentication?.agentTokenDetails?.access_token;

export const topUpInputIsCollapsedSelector = state => getPkgSlice(state)?.topUpInputIsCollapsed;

export const topUpValueSelector = state => getPkgSlice(state)?.topUpValue;

export const minTopUpValueSelector = state => getPkgSlice(state)?.minTopUpValue;

export const topUpErrorStatusSelector = state => getPkgSlice(state)?.topUpErrorStatus;

export const currentScreensSelector = state => getPkgSlice(state)?.currentScreen;

export const isOpenSelector = state => getPkgSlice(state)?.isOpen;

export const paymentMethodSelector = state => getPkgSlice(state)?.paymentMethod;

export const storeBucketSelector = state => getPkgSlice(state)?.storeBucket;

export const paymentNextButtonIsDisabled = state => !getPkgSlice(state)?.paymentMethod;

export const nextButtonIsDisabled = state =>
  topUpErrorStatusSelector(state) ||
  phoneNumberErrorStatusSelector(state) ||
  !phoneNumberSelector(state) ||
  !topUpValueSelector(state);
export const configSelector = state => {
  const myWidget = state.config?.data?.widgets[pkgName];
  if (myWidget) {
    const insID = Object.keys(myWidget)[0];
    return myWidget[insID];
  }
  return null;
};
export const propSelector = (state, prop) => {
  const WidgetConf = configSelector(state);
  if (WidgetConf) {
    return WidgetConf[prop];
  }
  return null;
};

export const commonTopUpValueSelector = state => {
  const commonTopUpValue = propSelector(state, 'commonTopUpValues');
  return commonTopUpValue?.map(topUpValue => topUpValue?.value);
};

export const getTopupInfoSelector = state => ({
  phoneNumber: state.props?.topupList?.phoneNumber,
  customerFullName: state.props?.topupList?.customerFullName,
  phoneNumbers: state.props?.topupList?.phoneNumbers,
});

export const storeDetailsSelector = state => state?.props?.storeDetails;

export const storeBucketUrlSelector = state => propSelector(state, 'storeBucketUrl');

export const getAgentName = state => state?.authentication?.agentTokenDetails?.user_info?.sub;
