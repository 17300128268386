import type { FC } from 'react'
import React, { useContext } from 'react'

import ListItem from '@vfuk/core-list-item'
import Divider from '@vfuk/core-divider'
import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/LinkList.style'

import type { LinkListProps } from './LinkList.types'

import TopLink from '../TopLink'

import { DataKeys } from '../../../../constants'

import TopNavigationContext from '../../../../context/TopNavigationContext'
import type { TopNavigationContextProps } from '../../../../context/TopNavigationContext.types'

const LinkList: FC<LinkListProps> = ({ links, dataSelectorPrefix }: LinkListProps) => {
  const { appearance, topNavigationTheme } = useContext(TopNavigationContext) as TopNavigationContextProps

  const topBarAppearance = topNavigationTheme.appearance[appearance].topBar.links

  const { dividerAppearance, inverseDivider } = topBarAppearance

  return (
    <Styled.LinkList level={1} appearance={appearance} topNavigationTheme={topNavigationTheme}>
      {links!.map((link, index) => {
        return (
          <ListItem
            key={`top-navigation-link-${link.id}-${index}`}
            dataSelectorPrefix={getDataSelector(dataSelectorPrefix, `link-${index}`)}
          >
            <TopLink link={link} active={link.active} />
            {!link.active && index !== links!.length - 1 && (
              <Styled.DividerWrapper>
                <Divider orientation='vertical' appearance={dividerAppearance} level={1} inverse={inverseDivider} noMargin />
              </Styled.DividerWrapper>
            )}
            {link.active && link.links && (
              <Styled.LinkList level={2} appearance={appearance} topNavigationTheme={topNavigationTheme}>
                {link!.links!.map((secondaryLink, secondaryIndex) => {
                  const activeDividerAppearance = topBarAppearance.active.dividerAppearance
                  const activeInverseDivider = topBarAppearance.active.inverseDivider
                  const subLinkdividerAppearance = topBarAppearance.subLink.dividerAppearance
                  const subLinkInverseDivider = topBarAppearance.active.inverseDivider
                  return (
                    <ListItem
                      key={`top-navigation-secondary-link-${secondaryLink.id}-${secondaryIndex}`}
                      dataSelectorPrefix={getDataSelector(dataSelectorPrefix, `secondary-link-${index}`)}
                    >
                      {secondaryIndex === 0 && (
                        <Styled.DividerWrapper>
                          <Divider
                            orientation='vertical'
                            appearance={activeDividerAppearance}
                            inverse={activeInverseDivider}
                            level={1}
                            noMargin
                          />
                        </Styled.DividerWrapper>
                      )}
                      <Styled.SecondaryLink
                        id={secondaryLink.id}
                        href={secondaryLink.href}
                        label={secondaryLink.srText}
                        customRouterProps={secondaryLink.customRouterProps}
                        onClick={secondaryLink.onClick}
                        appearance={appearance}
                        topNavigationTheme={topNavigationTheme}
                        dataSelectorPrefix={getDataSelector(dataSelectorPrefix, `${DataKeys.TopBar}-${secondaryLink.id}`)}
                        dataAttributes={secondaryLink.dataAttributes}
                      >
                        {secondaryLink.text}
                      </Styled.SecondaryLink>
                      {secondaryIndex !== link.links!.length - 1 && (
                        <Styled.DividerWrapper>
                          <Divider
                            orientation='vertical'
                            appearance={subLinkdividerAppearance}
                            inverse={subLinkInverseDivider}
                            level={1}
                            noMargin
                          />
                        </Styled.DividerWrapper>
                      )}
                    </ListItem>
                  )
                })}
              </Styled.LinkList>
            )}
          </ListItem>
        )
      })}
    </Styled.LinkList>
  )
}

export default LinkList
