import jp from 'jsonpath';
import getFullAddress from './getFullAddress';

const getAddressDetails = (billingAddresses, paths, t) =>
  billingAddresses?.map(el => {
    let id, addressHeader;
    try {
      id = jp.value(el, paths?.addressId);
      addressHeader = jp.value(el, paths?.address?.addressTitlePath);
    } catch (error) {
      return;
    }
    return {
      id: id,
      addressHeader: addressHeader
        ? `${addressHeader} (${t('BillingId', { id: el.id })})`
        : t('BillingId', { id: el.id }),
      fullAddress: getFullAddress(paths, el),
    };
  });

const getTrayData = (billingAddresses, paths, t) => {
  let sectionDetails = [{ id: 'All', addressHeader: t('allBillingAccounts') }];
  const addresses =
    billingAddresses && billingAddresses?.length
      ? getAddressDetails(billingAddresses, paths, t)
      : [];
  sectionDetails = [...sectionDetails, ...addresses];
  return [
    {
      sectionTitle: t('billingAccounts'),
      sectionDetails,
    },
  ];
};

export default getTrayData;
