import { name } from '../../package.json';
import { customerPckgName } from '../appConstants';

export const CALL_TEALIUM = 'CALL_TEALIUM';
export const SET_ADDRESS_ID = `@${name}/SET_ADDRESS_ID`;
/* eslint max-len: "off" */
export const GET_CUSTOMER_SEARCH_DATA_SUCCESS = `@${customerPckgName}/GET_CUSTOMER_SEARCH_DATA_SUCCESS`;
export const DEFAULT_BILLING_ADDRESS_CHANGE = `@${customerPckgName}/DEFAULT_BILLING_ADDRESS_CHANGE`;

export const sendTags = (taggingValue, taggingType) => ({
  type: CALL_TEALIUM,
  payload: {
    taggingValue: taggingValue,
    taggingType: taggingType,
  },
});

//Search for customers action creators
export const setAddressID = id => ({
  type: SET_ADDRESS_ID,
  selectedAddress: id,
});
