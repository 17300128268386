const API_RESPONSE = {
  CREATED: 'created',
  MSISDN_NOT_FOUND: 'MSISDN_NOT_FOUND',
  MSISDN_NOT_PREPAID_VODAFONE_NUMBER: 'MSISDN_NOT_PREPAID_VODAFONE_NUMBER',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  LOW_STORE_BUCKET: 'E_105 - Insufficient topUp amount.',
  FAIL_NOT_PREPAID:'FAIL_NOT_PREPAID'
};

export default API_RESPONSE;
