export function getPhoneNumbersMinTopupValues(
  data,
  allowedTopupService,
  minTopUpOtherPlans,
  topUpPlans,
) {
  return data.reduce((acc, item) => {
    const service = item.productSpecification;
    if (service?.name?.toLowerCase() === allowedTopupService.toLowerCase()) {
      const phoneNumber = item.id;
      const planData = topUpPlans.filter(
        (plan) => plan.indicator === item.productOffering.id,
      );

      const palnMinimumValue = planData.length
        ? planData[0].minValue
        : minTopUpOtherPlans;

      acc.push({
        phoneNumber,
        minValue: palnMinimumValue,
      });
    }

    return acc;
  }, []);
}
