import {
  Container,
  Grid,
  GridRow,
  Heading,
  Icon,
  Pill,
  Spacing,
  Span,
  Tooltip,
} from 'r10-source-library';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import WrapByDataAttributes from '../utils/WrapByDataAttributes';
import useTranslation from '../hooks/useTranslation';
import useProps from '../hooks/useProps';
import { getPhoneNumbersMinTopupValues } from '../utils/getPhoneNumbers';
import { setProps } from '../actions/Actions';
import { getTopupInfo } from '../selectors/selector';

const CardName = styled.div`
  flex: 1;
  width: 50px;
  margin-top: -2px;
  min-height: 70px;
  text-align: left;
`;

const HeadingWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const Card = styled.div`
  & > button {
    width: 100%;
  }
  height: 130px;
`;

export default function ServiceCard(props) {
  const t = useTranslation();
  const dispatch = useDispatch();
  const headingRef = useRef(null);
  const history = useHistory();
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const { allowedTopupService, minTopUpOtherPlans, topUpPlans } = useProps();
  const showPlanType = props.planType && props.showLabel;
  const serviceURL = props.serviceURL;
  const isCardClickable = props.isRedirectionActive && serviceURL;
  const { customerFullName, services } = useSelector(getTopupInfo);

  const goToServicePage = () => {
    const msisdnList = getPhoneNumbersMinTopupValues(
      services,
      allowedTopupService,
      minTopUpOtherPlans,
      topUpPlans,
    );

    dispatch(
      setProps('topupList', {
        customerFullName,
        phoneNumber: props.msisdn,
        phoneNumbers: msisdnList,
      }),
    );
    history.push(serviceURL);
  };

  useEffect(() => {
    const { scrollHeight, offsetHeight } = headingRef.current;
    setIsTooltipVisible(scrollHeight > offsetHeight);
  }, []);

  return (
    <Card className="test-service-card">
      <Container elevation onClick={isCardClickable && goToServicePage}>
        <Grid>
          <GridRow>
            <WrapByDataAttributes
              dataAttributes={{ 'data-testid': 'service-icon' }}
            >
              {props.icon && <Icon name={props.icon} size={3} group="system" />}
            </WrapByDataAttributes>

            <CardName>
              <Spacing spacingLevel={{ left: props.icon ? 2 : 0 }}>
                <HeadingWrapper
                  dataAttributes={{ 'data-testid': 'service-name' }}
                  ref={headingRef}
                >
                  {isTooltipVisible ? (
                    <Tooltip text={props.name} appearance="secondary">
                      <Heading level={5} weight={3} noMargin={true}>
                        {props.name}
                      </Heading>
                    </Tooltip>
                  ) : (
                    <Heading level={5} weight={3} noMargin={true}>
                      {props.name}
                    </Heading>
                  )}
                </HeadingWrapper>
              </Spacing>
            </CardName>

            {showPlanType ? (
              <planType data-testid="service-plan-type">
                <Pill
                  text={t(`${props.planType}Type`)}
                  appearance="secondary"
                  state={props.planType === 'postpaid' ? 'incomplete' : null}
                />
              </planType>
            ) : null}
          </GridRow>
        </Grid>

        <GridRow justify="between" align="center" noGutters>
          <Spacing spacingLevel={{ top: 1, bottom: 1 }}>
            <Span
              dataAttributes={{ 'data-testid': 'service-msisdn' }}
              appearance="alt2"
              weight={2}
            >
              {props.msisdn}
            </Span>
          </Spacing>
          <Span
            dataAttributes={{ 'data-testid': 'service-status' }}
            appearance="alt2"
            weight={2}
          >
            {t(props.status) || ''}
          </Span>
        </GridRow>
      </Container>
    </Card>
  );
}

ServiceCard.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  status: PropTypes.string,
  planType: PropTypes.oneOf(['postpaid', 'prepaid', 'PrePay', 'PostPay']),
  msisdn: PropTypes.string.isRequired,
  showLabel: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  isRedirectionActive: PropTypes.bool.isRequired,
  serviceURL: PropTypes.string.isRequired,
};
