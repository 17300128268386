import {
  Container,
  Divider,
  Grid,
  GridColumn,
  GridRow,
  Heading,
  Icon,
  Spacing,
  themeWS10,
} from 'r10-source-library';

import PackageService from './PackageService';
import PropTypes from 'prop-types';
import React from 'react';
import isRedirectionActive from '../utils/isRedirectionActive';
import styled from 'styled-components';

const Wrapper = styled.div`
  grid-column: 1/3;

  @media (max-width: ${themeWS10.breakpoints.md}px) {
    grid-column: 1;
  }
`;

export default function PackageCard(props) {
  return (
    <Wrapper
      className="test-package-card"
      style={{ gridRow: props.services.length < 3 ? '1' : '1/3' }}
    >
      <Container elevation>
        <Spacing spacingLevel={{ bottom: 4 }}>
          <Grid>
            <GridRow>
              <Icon
                dataAttributes={{ 'data-testid': 'package-icon' }}
                name={props.icon}
                size={3}
                group="system"
              />
              <Spacing spacingLevel={{ left: 2 }}>
                <Heading
                  dataAttributes={{ 'data-testid': 'package-name' }}
                  level={5}
                  weight={3}
                  noMargin={true}
                >
                  {props.name}
                </Heading>
              </Spacing>
            </GridRow>
          </Grid>
        </Spacing>

        <GridRow>
          {props.services?.map((service) => (
            <GridColumn key={service.name} colMd={6} col={12}>
              <PackageService
                serviceURL={props.redirection.serviceURL}
                isRedirectionActive={isRedirectionActive(
                  props.redirection,
                  service.planType,
                )}
                {...service}
              />
              <Divider />
            </GridColumn>
          ))}
        </GridRow>
      </Container>
    </Wrapper>
  );
}

PackageCard.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      planType: PropTypes.oneOf(['postpaid', 'prepaid', 'PrePay', 'PostPay']),
      msisdn: PropTypes.string.isRequired,
      showLabel: PropTypes.bool.isRequired,
      isVisible: PropTypes.bool.isRequired,
      isRedirectionActive: PropTypes.bool.isRequired,
      serviceURL: PropTypes.string.isRequired,
    }),
  ).isRequired,

  redirection: PropTypes.shape({
    prepaidService: PropTypes.bool.isRequired,
    postpaidService: PropTypes.bool.isRequired,
    serviceURL: PropTypes.string.isRequired,
  }).isRequired,
};
