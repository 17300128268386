import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ModalContent } from 'r10-common-components';

import { sendTags, updateCurrentScreen, updatePaymentMethod } from '../../actions/Actions';
import useTranslation from '../../hooks/useTranslation';
import { paymentNextButtonIsDisabled, paymentMethodSelector } from '../../selectors/selector';
import { SCREENS } from '../../appConstants';
import PAYMENT_METHODS from '../../constants/PaymentMethods';

const PaymentMethodSelection = ({
  updateCurrScreen,
  nextButtonIsDisabled,
  updatePayMethod,
  paymentMethod,
  sendTags,
}) => {
  const [selected, setSelected] = useState(paymentMethod);
  const t = useTranslation();
  const handleOnNext = useCallback(async () => {
    updateCurrScreen(SCREENS.PAYMENT_METHOD_CONFIRM);
  }, [updateCurrScreen]);

  const handleOnBack = useCallback(async () => {
    updateCurrScreen(SCREENS.CONFIRM);
  }, [updateCurrScreen]);

  const pickerItems = [
    {
      title: t(PAYMENT_METHODS.CASH),
      value: PAYMENT_METHODS.CASH,
      dataAttributes: {
        'data-testid': `picker-${PAYMENT_METHODS.CASH}`,
      },
    },
    {
      title: t(PAYMENT_METHODS.CREDIT_CARD),
      value: PAYMENT_METHODS.CREDIT_CARD,
      dataAttributes: {
        'data-testid': `picker-${PAYMENT_METHODS.CREDIT_CARD}`,
      },
    },
  ];

  const pickerChangesHandler = value => {
    setSelected(value);
    updatePayMethod(value);
    sendTags({
      taggingId: 'CUSTOMER_TOPUP_9',
      taggingType: 'link',
      taggingValue: { event_value: value },
    });
  };
  useEffect(() => {
    sendTags({
      taggingId: 'CUSTOMER_TOPUP_8',
      taggingType: 'view',
      taggingValue: {},
    });
  }, []);

  return (
    <ModalContent
      header={t('headerTopUp')}
      icon="payment"
      iconMarginBottom={4}
      title={t('paymentMethodSelectionTitle')}
      titleMarginBottom={0}
      subtitles={[
        {
          marginBottom: 4,
          regular: t('paymentMethodSelectionSubtitle'),
        },
      ]}
      picker={{
        items: pickerItems,
        selected,
        onChange: event => pickerChangesHandler(event.target.value),
      }}
      pickerMarginBottom={7}
      primaryButton={{
        onClick: handleOnNext,
        text: t('nextButton'),
        state: nextButtonIsDisabled ? 'disabled' : '',
        dataTestId: 'next-payment-selection-screen',
      }}
      secondaryButton={{
        onClick: handleOnBack,
        text: t('backButton'),
        dataTestId: 'back-button',
      }}
    />
  );
};
const mapStateToProps = state => ({
  nextButtonIsDisabled: paymentNextButtonIsDisabled(state),
  paymentMethod: paymentMethodSelector(state),
});
const mapDispatchToProps = dispatch => ({
  updateCurrScreen: value => dispatch(updateCurrentScreen(value)),
  updatePayMethod: value => dispatch(updatePaymentMethod(value)),
  sendTags: tagsObject => dispatch(sendTags(tagsObject)),
});
PaymentMethodSelection.propTypes = {
  updateCurrScreen: PropTypes.func,
  updatePayMethod: PropTypes.func,
  paymentMethod: PropTypes.string,
  nextButtonIsDisabled: PropTypes.bool,
  sendTags: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodSelection);
