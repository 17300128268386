import axios from 'axios';

const topupPhoneNumberAPI = async (baseUrl, payload) => {
  try {
    return await axios.post(baseUrl, payload);
  } catch (error) {
    throw new Error(JSON.parse(error.response?.data?.error || null)?.message);
  }
};

export default topupPhoneNumberAPI;
