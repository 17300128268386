import {
  Button,
  GridRow,
  Heading,
  Icon,
  Spacing,
  Span,
  themeWS10,
  Grid,
  useBreakpoints,
} from 'r10-source-library';
import React from 'react';
import styled from 'styled-components';

export default function PopupContent({ amount, closePopup, t }) {
  const breakpoint = useBreakpoints();
  const Wrapper = styled(Grid)`
    @media (max-width: ${themeWS10.breakpoints.md}px) {
      position: absolute;
      right: 0;
      left: 0;
      bottom: 45px;
    }
  `;

  return (
    <div data-testid="popup-content">
      <GridRow justify="center" direction={'column'}>
        <Spacing
          spacingLevel={{
            bottom: 7,
            top: 4,
          }}
        >
          <Heading size={1} justify="center" weight={3} text={t('alertMsgHeader')} />
        </Spacing>
        <GridRow justify="center">
          <Icon name="warning" size={7} group="hifiDark" />
        </GridRow>
        <Spacing
          spacingLevel={{
            bottom: 7,
            top: 8,
          }}
        >
          <Heading
            dataAttributes={{ 'data-testid': 'alertMsgTitle' }}
            size={2}
            justify="center"
            weight={3}
            text={t('alertMsgTitle')}
          />
        </Spacing>
      </GridRow>
      <GridRow justify="center">
        <Spacing
          spacingLevel={{
            bottom: 10,
            top: breakpoint.sm ? 3 : 0,
          }}
        >
          <div style={{ textAlign: 'center' }} data-testid="alertMsgContent">
            <Span display="block" weight={1}>
              {t('alertMsgContent1')}
            </Span>
            <Span weight={1}>{t('alertMsgContent2', amount)}</Span>
            <Span weight={3} dataAttributes={{ 'data-testid': 'popup-amount' }}>
              {amount}
            </Span>
          </div>
        </Spacing>
      </GridRow>

      <Wrapper maxWidth={410}>
        <Button
          dataAttributes={{ 'data-testid': 'action-button' }}
          width="full"
          text={t('alertMsgButton')}
          onClick={closePopup}
        />
      </Wrapper>
    </div>
  );
}
