import { useContext } from 'react';
import { PropsContext } from '../context/PropsContext';
import get from 'lodash.get';

/**
 *
 * @param {string} propPath the path for property of the widget you want to get
 * @param {*} defaultValue  the value that is returned
 * if it couldn't find the property using the path
 * @returns the widget properties
 */
export default function useProps(propPath, defaultValue) {
  const widgetProps = useContext(PropsContext);
  return propPath ? get(widgetProps, propPath, defaultValue) : widgetProps;
}
