import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { advancedSpacing, typography } from '@vfuk/core-mixins'

import Interaction from '@vfuk/core-interaction'
import { ListItemStyles } from '@vfuk/core-list-item'

import type { StyledLinkListProps } from './LinkList.style.types'

export const LinkList = styled.ul<StyledLinkListProps>(
  (props: StyledLinkListProps): FlattenSimpleInterpolation => css`
    display: flex;
    margin: 0;
    padding: 0;

    ${ListItemStyles.ListItem} {
      display: flex;
    }

    ${props.level === 2 &&
    css`
      background-color: ${props.topNavigationTheme.appearance[props.appearance].topBar.links.subLink.backgroundColor};
    `}
  `,
)

export const DividerWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    ${advancedSpacing('padding', [2, 0])};
  `,
)

type SecondaryLinkProps = Pick<StyledLinkListProps, 'theme' | 'appearance' | 'topNavigationTheme'>
export const SecondaryLink = styled(Interaction)<SecondaryLinkProps>(
  (props: SecondaryLinkProps): FlattenSimpleInterpolation => css`
    color: ${props.topNavigationTheme.appearance[props.appearance].topBar.links.subLink.color};
    ${advancedSpacing('padding', [2, 4])};
    ${typography.paragraph(1, false)};
  `,
)
