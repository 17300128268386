import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import Interaction from '@vfuk/core-interaction'
import { ImageStyles } from '@vfuk/core-image'
import { advancedSpacing, elevation, respondTo, spacing } from '@vfuk/core-mixins'

import type { StyledSideNavigationCardProps } from './SideNavigationCard.style.types'

import localThemes from '../themes/SideNavigationCard.theme'

type SideNavigationCardProps = Pick<StyledSideNavigationCardProps, 'theme' | 'appearance'>
export const SideNavigationCard = styled(Interaction)<SideNavigationCardProps>(
  (props: SideNavigationCardProps): FlattenSimpleInterpolation => {
    const localAppearance = localThemes(props.theme).appearance[props.appearance]
    const localSize = localThemes(props.theme).size

    return css`
      display: flex;
      background: ${localAppearance.backgroundColor};
      border-radius: 0 ${props.theme.border.radius[2]} ${props.theme.border.radius[2]} 0;
      width: auto;
      ${spacing('margin-left', -6)}
      ${elevation(true)}
      height: ${localSize.sm.height}px;

      ${respondTo.md(css`
        height: ${localSize.md.height}px;
      `)}

      ${respondTo.lg(css`
        height: ${localSize.lg.height}px;
      `)}
    `
  },
)

type TextWrapperProps = Pick<StyledSideNavigationCardProps, 'theme'>
export const TextWrapper = styled.div<TextWrapperProps>((props: TextWrapperProps): FlattenSimpleInterpolation => {
  const localSize = localThemes(props.theme).size

  return css`
    display: flex;
    align-items: center;
    ${advancedSpacing('padding', [6, 4, 6, 6])}
    width: calc(100% - ${localSize.sm.height}px);

    ${respondTo.md(css`
      width: calc(100% - ${localSize.md.height}px);
    `)}

    ${respondTo.lg(css`
      width: calc(100% - ${localSize.lg.height}px);
    `)}
  `
})

type ImageWrapperProps = Pick<StyledSideNavigationCardProps, 'theme'>
export const ImageWrapper = styled.div<ImageWrapperProps>(
  (props: ImageWrapperProps): FlattenSimpleInterpolation =>
    css`
      ${ImageStyles.Image} {
        height: 100%;
        border-radius: 0 ${props.theme.border.radius[2]} ${props.theme.border.radius[2]} 0;
        object-fit: cover;
      }
    `,
)
