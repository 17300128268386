import type { ReactElement, FC } from 'react'
import React, { useContext, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import type { InteractionEvent } from '@vfuk/core-interaction'

import Icon from '@vfuk/core-icon'
import NumberBadge from '@vfuk/core-number-badge'
import LinkWithIcon from '@vfuk/core-link-with-icon'
import Link from '@vfuk/core-link'
import { useBreakpoints } from '@vfuk/core-match-media'
import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/SideNavigationList.style'

import type { SideNavigationListProps } from './SideNavigationList.types'

import type { Link as LinkTypes } from '../../../../TopNavigation.types'

import { DataKeys } from '../../../../constants'

import TopNavigationContext from '../../../../context/TopNavigationContext'
import type { TopNavigationContextProps } from '../../../../context/TopNavigationContext.types'
import registerAriaTree from '../../../../helpers/registerAriaTree'

import SideNavigationCard from '../SideNavigationCard'

const SideNavigationList: FC<SideNavigationListProps> = ({
  links,
  onClick,
  activeLinkId,
  backLinkClick,
  navLevel,
  dataSelectorPrefix,
}): ReactElement => {
  const { t } = useTranslation()
  const { sm, mdAndAbove } = useBreakpoints()

  const { animationDuration, interactionHandler, appearance, topNavigationTheme } = useContext(
    TopNavigationContext,
  ) as TopNavigationContextProps

  const handleClick = (link: LinkTypes, event: InteractionEvent): void => {
    interactionHandler(event, getDataSelector(dataSelectorPrefix, link.id) ?? '')
    onClick(link)
  }

  const handleBackClick = (event: InteractionEvent): void => {
    interactionHandler(event, DataKeys.BackLink)
    backLinkClick()
  }

  const showBackLink = (sm && navLevel > 1) || navLevel > 2

  const { linkWithIconAppearance, inverseLink, linkAppearance, numberBadgeAppearance, iconAppearance } =
    topNavigationTheme.appearance[appearance].sideTray.level[navLevel > 1 ? 2 : 1]

  return (
    <Styled.LinksWrapper data-selector={getDataSelector(dataSelectorPrefix)}>
      <Fragment>
        {showBackLink && (
          <Styled.LinkListItem fullWidth={false}>
            <LinkWithIcon
              text={t('Back')}
              icon={{ name: 'chevron-left', justify: 'left' }}
              size={3}
              onClick={handleBackClick}
              appearance={linkWithIconAppearance}
              inverse={inverseLink}
              dataSelectorPrefix={getDataSelector(dataSelectorPrefix, DataKeys.BackLink)}
            />
          </Styled.LinkListItem>
        )}
        {links!.map((link) => {
          return (
            <Styled.LinkListItem key={link.id} fullWidth={true}>
              {navLevel === 2 && link.card ? (
                <SideNavigationCard
                  id={link.id}
                  appearance={link.card!.appearance}
                  text={link.text}
                  srText={link.srText}
                  image={link.card!.image}
                  href={link.href}
                  onClick={link.onClick}
                  customRouterProps={link.customRouterProps}
                  dataSelectorPrefix={getDataSelector(dataSelectorPrefix, `${DataKeys.SideNavigation}-${link.id}`)}
                  dataAttributes={link.dataAttributes}
                />
              ) : (
                <>
                  {mdAndAbove && navLevel > 1 ? (
                    <>
                      {link.links ? (
                        <LinkWithIcon
                          id={link.id}
                          text={link.text}
                          appearance={linkWithIconAppearance}
                          inverse={inverseLink}
                          onClick={(event: InteractionEvent): void => handleClick(link, event)}
                          customRouterProps={link.customRouterProps}
                          href={link.href}
                          icon={{
                            name: 'chevron-right',
                            justify: 'right',
                          }}
                          dataSelectorPrefix={getDataSelector(dataSelectorPrefix, `${DataKeys.SideNavigation}-${link.id}`)}
                          dataAttributes={registerAriaTree(link)}
                        />
                      ) : (
                        <Link
                          id={link.id}
                          text={link.text}
                          onClick={link.onClick}
                          customRouterProps={link.customRouterProps}
                          href={link.href}
                          appearance={linkAppearance}
                          inverse={inverseLink}
                          dataSelectorPrefix={getDataSelector(dataSelectorPrefix, `${DataKeys.SideNavigation}-${link.id}`)}
                        />
                      )}
                    </>
                  ) : (
                    <Styled.MenuLink
                      id={link.id}
                      customRouterProps={link.customRouterProps}
                      href={link.href}
                      onClick={(event: InteractionEvent): void => handleClick(link, event)}
                      data-selector={getDataSelector(dataSelectorPrefix, `${DataKeys.SideNavigation}-${link.id}`)}
                      dataAttributes={registerAriaTree(link)}
                    >
                      <Styled.MenuLinkWrapper>
                        <Styled.MenuLinkText
                          selected={activeLinkId === link.id}
                          animationDuration={animationDuration}
                          appearance={appearance}
                          topNavigationTheme={topNavigationTheme}
                          data-selector={getDataSelector(dataSelectorPrefix, `menu-link-text-${link.id}`)}
                        >
                          {link.text}
                        </Styled.MenuLinkText>
                        {link.numberBadge && (
                          <Styled.NumberBadgeWrapper>
                            <NumberBadge
                              number={link.numberBadge!}
                              appearance={numberBadgeAppearance}
                              dataSelectorPrefix={getDataSelector(dataSelectorPrefix, `number-badge-${link.id}`)}
                            />
                          </Styled.NumberBadgeWrapper>
                        )}
                      </Styled.MenuLinkWrapper>
                      {link.links && (
                        <Icon
                          name='chevron-right'
                          size={4}
                          appearance={iconAppearance}
                          dataSelectorPrefix={getDataSelector(dataSelectorPrefix, `icon-${link.id}`)}
                        />
                      )}
                    </Styled.MenuLink>
                  )}
                </>
              )}
            </Styled.LinkListItem>
          )
        })}
      </Fragment>
    </Styled.LinksWrapper>
  )
}

export default SideNavigationList
