import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'r10-source-library';
import styled from 'styled-components';
import useTranslation from '../hooks/useTranslation';
import WrapByDataAttributes from '../utils/WrapByDataAttributes';

const FiltersWrapper = styled.div`
  &:first-child {
    min-width: max-content;
  }

  /* TODO: remove it once Tag component accepts a prop to hide the x icon */
  & [class^='Iconstyle__Icon'] {
    display: none;
  }
`;

const FilterTag = ({ title, isActive, handleChange }) => {
  const t = useTranslation();
  return (
    <WrapByDataAttributes dataAttributes={{ 'data-testid': `filter-${title}` }}>
      <FiltersWrapper>
        <Tag
          text={`${t(title)}`}
          appearance={isActive ? 'secondary' : 'primary'}
          inverse={!isActive}
          onClick={handleChange}
        />
      </FiltersWrapper>
    </WrapByDataAttributes>
  );
};

FilterTag.propTypes = {
  title: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  handleChange: PropTypes.func,
};

export default FilterTag;
