import type { ReactElement, FC } from 'react'
import React, { useContext, useMemo } from 'react'

import Icon from '@vfuk/core-icon'
import NumberBadge from '@vfuk/core-number-badge'
import type { InteractionEvent } from '@vfuk/core-interaction'
import type { BaseProps } from '@vfuk/core-base-props'
import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/MobileNavTrigger.style'

import type { Link } from '../../../../TopNavigation.types'

import { DataKeys } from '../../../../constants'

import TopNavigationContext from '../../../../context/TopNavigationContext'
import type { TopNavigationContextProps } from '../../../../context/TopNavigationContext.types'

const MobileNavTrigger: FC<BaseProps> = ({ dataSelectorPrefix }: BaseProps): ReactElement => {
  const {
    primaryNav,
    mobileNavTrigger,
    navLevel,
    setNavLevel,
    setActiveNav,
    setNavTree,
    interactionHandler,
    appearance,
    topNavigationTheme,
  } = useContext(TopNavigationContext) as TopNavigationContextProps

  const getTotalNumberBadges = (): number => {
    const numberBadges = primaryNav.links.filter((navItem) => navItem.numberBadge)
    return numberBadges.reduce((n, { numberBadge }: Link) => n + numberBadge!, 0)
  }

  const totalNumberBadges = useMemo((): number => getTotalNumberBadges(), [primaryNav])

  const { iconAppearance, inverseIcon, numberBadgeAppearance } = topNavigationTheme.appearance[appearance].mainBar

  const handleClick = (event: InteractionEvent): void => {
    setNavLevel(1)
    setActiveNav({
      parentId: mobileNavTrigger.id,
      links: primaryNav.links,
    })
    setNavTree([
      {
        level: 1,
        links: primaryNav.links,
        parentId: mobileNavTrigger.id,
      },
    ])
    interactionHandler(event, DataKeys.MobileNavTrigger)
  }

  return (
    <Styled.MobileNavTrigger
      onClick={handleClick}
      id={mobileNavTrigger.id}
      data-selector={getDataSelector(dataSelectorPrefix, DataKeys.MobileNavTrigger)}
    >
      <Icon
        name='menu'
        size={4}
        appearance={iconAppearance}
        inverse={inverseIcon}
        dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'icon')}
      />
      <Styled.MobileNavTriggerText
        isActive={navLevel > 0}
        topNavigationTheme={topNavigationTheme}
        appearance={appearance}
        data-selector={getDataSelector(dataSelectorPrefix, 'text')}
      >
        {mobileNavTrigger.text}
        {totalNumberBadges > 0 && (
          <Styled.NumberBadgeWrapper>
            <NumberBadge
              number={totalNumberBadges}
              appearance={numberBadgeAppearance}
              dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'number-badge')}
            />
          </Styled.NumberBadgeWrapper>
        )}
      </Styled.MobileNavTriggerText>
    </Styled.MobileNavTrigger>
  )
}

export default MobileNavTrigger
