// TODO: should add 'satisfies Record<string, string>' in Typescript bump
export const DataKeys = {
  OpenSearch: 'open-search',
  CloseSearch: 'close-search',
  PrimaryNav: 'primary-nav',
  Logo: 'logo',
  MobileNavTrigger: 'mobile-nav-trigger',
  SkipLink: 'skip-link',
  BackLink: 'back-link',
  LinkList: 'link-list',
  SearchBar: 'search-bar',
  CloseButton: 'close-button',
  MainBar: 'main-bar',
  UserIcon: 'user-icon',
  IconNav: 'icon-nav',
  SideNavigation: 'side-navigation',
  SecondaryNav: 'secondary-nav',
  SecondarySideNavigation: 'secondary-side-navigation',
  TopBar: 'top-bar',
}
