import type { ReactElement, FC } from 'react'
import React from 'react'
import FunctionalAvatar from '@vfuk/core-functional-avatar'
import { getDataSelector } from '@vfuk/core-base-props'

import type { PhotoAvatarProps } from './PhotoAvatar.types'

const PhotoAvatar: FC<PhotoAvatarProps> = ({
  image,
  size,
  onClick,
  onMouseDown,
  onMouseUp,
  onTouchStart,
  onTouchEnd,
  href,
  id,
  dataSelectorPrefix,
  dataAttributes,
  srText,
  customRouterProps,
}: PhotoAvatarProps): ReactElement => {
  const componentName = 'PhotoAvatar'

  return (
    <FunctionalAvatar
      backgroundImage={{ sm: { src: image } }}
      size={size}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      href={href}
      id={id}
      dataSelectorPrefix={getDataSelector(dataSelectorPrefix)}
      componentName={componentName}
      dataAttributes={dataAttributes}
      srText={srText}
      customRouterProps={customRouterProps}
    />
  )
}

export default PhotoAvatar
