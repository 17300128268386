import { SET_ADDRESS_ID } from '../actions/Actions';
import initialState from './initialState';

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ADDRESS_ID: {
      return {
        ...state,
        selectedAddressId: action.selectedAddress
      };
    }
    default:
      return state;
  }
};

export default appReducer;
