import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SelectInputWithLabel as SelectInput } from 'r10-source-library';

import { sendTags, updatePhoneNumber } from '../../../actions/Actions';
import { phoneNumberSelector, phoneNumbersSelector } from '../../../selectors/selector';

const DropdownInput = ({ phoneNumber, updPhoneNumber, phoneNumbers, sendTags }) => {
  useEffect(() => {
    // initially set the selected phone number to first value in case there is no selected one
    if (!phoneNumber && phoneNumbers?.length) updPhoneNumber(phoneNumbers[0].value);
  }, [phoneNumber, phoneNumbers, updPhoneNumber]);

  const handleOnChange = useCallback(
    e => {
      let phone = e.target.value;
      updPhoneNumber(phone);
      sendTags({
        taggingId: 'CUSTOMER_TOPUP_3',
        taggingType: 'link',
        taggingValue: {
          event_value: phone,
        },
      });
    },
    [updPhoneNumber, sendTags]
  );

  return (
    <>
      {phoneNumbers?.length > 0 ? (
        <SelectInput
          fieldWrapper={{
            label: '',
            width: 'full',
          }}
          selectInput={{
            id: 'dropdown-list',
            value: phoneNumber,
            onChange: handleOnChange,
            options: phoneNumbers,
          }}
        />
      ) : null}
    </>
  );
};

const mapStateToProps = state => ({
  phoneNumber: phoneNumberSelector(state),
  phoneNumbers: phoneNumbersSelector(state),
});
const mapDispatchToProps = dispatch => ({
  updPhoneNumber: newPhoneNumber => dispatch(updatePhoneNumber(newPhoneNumber)),
  sendTags: tagsObject => dispatch(sendTags(tagsObject)),
});

DropdownInput.propTypes = {
  phoneNumber: PropTypes.string,
  updPhoneNumber: PropTypes.func,
  phoneNumbers: PropTypes.array,
  sendTags: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(DropdownInput);
