import type { FC, ReactElement } from 'react'
import React, { useState, useRef } from 'react'

import { useLocalTheme } from '@vfuk/core-themes'
import { useBreakpoints } from '@vfuk/core-match-media'
import { useVisibleScreenRect } from '@vfuk/core-helpers'
import Animate from '@vfuk/core-animate'
import Overlay from '@vfuk/core-overlay'
import SkipLink from '@vfuk/core-skip-link'
import type { InteractionEvent } from '@vfuk/core-interaction'
import { getDataSelector } from '@vfuk/core-base-props'

import setFocus from './hooks/setFocus'

import overlayClickHandler from './helpers/overlayClickHandler'

import type { TopNavigationProps, TopNavigationState } from './TopNavigation.types'

import MainBar from './components/MainBar'
import TopBar from './components/TopBar'
import SideNavigation from './components/SideNavigation'
import defaultTheme from './themes/TopNavigation.theme'

import TopNavigationContext from './context/TopNavigationContext'

import { DataKeys } from './constants'

const TopNavigation: FC<TopNavigationProps> = ({
  appearance = 'primary',
  topNav,
  logo,
  primaryNav,
  mobileNavTrigger,
  secondaryNav,
  sideNavigationCloseButton,
  skipLink,
  zIndex = 1,
  onClick,
  localTheme,
  id,
  dataSelectorPrefix,
  dataAttributes,
}: TopNavigationProps): ReactElement => {
  const componentName = 'TopNavigation'
  const topNavigationTheme = useLocalTheme(componentName, defaultTheme, localTheme)

  const topBar = useRef<HTMLDivElement>(null)
  const mainBar = useRef<HTMLDivElement>(null)
  const [showSearch, setShowSearch] = useState(false)
  const [isTransitioning, setIsTransitioning] = useState(false)
  const [navLevel, setNavLevel] = useState(0)

  // Note: activeNav is the navigation that appears
  //       in the first level of side navigation
  const [activeNav, setActiveNav] = useState<TopNavigationState['activeNav']>()
  // Note: activeSubNav is the navigation that appears
  //       in the second level of side navigation
  const [activeSubNav, setActiveSubNav] = useState<TopNavigationState['activeSubNav']>()
  // Note: navTree allows us to keep a history of activeSubNavs
  //       as the subNav changes
  const [navTree, setNavTree] = useState<TopNavigationState['navTree']>()

  const { lgAndAbove } = useBreakpoints()

  const mainBarVisibleRect = useVisibleScreenRect(mainBar.current)

  const getTopNavHeight = () => {
    if (!mainBarVisibleRect?.bottom) return
    if (mainBarVisibleRect.bottom < 0) {
      return 0
    }
    return mainBarVisibleRect.bottom
  }

  const topNavHeight = getTopNavHeight()

  const animationDuration = 400

  setFocus(
    logo,
    navLevel,
    setNavLevel,
    showSearch,
    setShowSearch,
    activeNav,
    activeSubNav,
    sideNavigationCloseButton,
    navTree,
    setNavTree,
    setIsTransitioning,
    setActiveSubNav,
    animationDuration,
    secondaryNav,
  )

  const interactionHandler = (event: InteractionEvent, trigger: string): void => {
    if (!onClick) return
    onClick(event, trigger)
  }

  return (
    <div id={id} data-component-name={componentName} data-selector={getDataSelector(dataSelectorPrefix)} {...dataAttributes}>
      <TopNavigationContext.Provider
        value={{
          zIndex,
          topNav,
          logo,
          primaryNav,
          mobileNavTrigger,
          secondaryNav,
          showSearch,
          setShowSearch,
          navLevel,
          setNavLevel,
          activeNav,
          setActiveNav,
          activeSubNav,
          setActiveSubNav,
          topNavHeight,
          animationDuration,
          sideNavigationCloseButton,
          navTree,
          setNavTree,
          isTransitioning,
          setIsTransitioning,
          interactionHandler,
          dataSelectorPrefix,
          appearance,
          topNavigationTheme,
        }}
      >
        <SkipLink
          text={skipLink.text}
          skipLinkId={skipLink.skipLinkId}
          dataSelectorPrefix={getDataSelector(dataSelectorPrefix, DataKeys.SkipLink)}
          onClick={interactionHandler}
        />
        {lgAndAbove && <TopBar ref={topBar} zIndex={zIndex} dataSelectorPrefix={getDataSelector(dataSelectorPrefix, DataKeys.TopBar)} />}
        <MainBar zIndex={zIndex} ref={mainBar} dataSelectorPrefix={getDataSelector(dataSelectorPrefix, DataKeys.MainBar)} />
        <Animate
          show={navLevel > 0}
          enter={{
            animations: ['slideFromLeft'],
            duration: animationDuration,
          }}
          exit={{
            animations: ['slideToLeft'],
            duration: animationDuration,
          }}
        >
          <SideNavigation dataSelectorPrefix={getDataSelector(dataSelectorPrefix, DataKeys.SideNavigation)} />
        </Animate>
        <Animate
          show={navLevel > 0 || showSearch}
          enter={{
            animations: ['fadeIn'],
            duration: animationDuration,
          }}
          exit={{
            animations: ['fadeOut'],
            duration: animationDuration,
          }}
        >
          <Overlay show onClick={(): void => overlayClickHandler(logo, setShowSearch, setNavLevel)} position='fixed' zIndex={zIndex - 1} />
        </Animate>
      </TopNavigationContext.Provider>
    </div>
  )
}

export default TopNavigation
