import { Grid, Modal } from 'r10-source-library';
import React, { useEffect, useState } from 'react';

import PopupContent from '../PopupContent';
import useTranslation from '../../hooks/useTranslation';

export default function Popup(props) {
  const t = useTranslation();
  const [open, setIsOpen] = useState(false);
  const closeModal = () => setIsOpen(false);
  useEffect(() => {
    props.onRef(() => {
      setIsOpen(true);
    });
  }, []);

  return (
    <Modal
      srName="test-modal"
      isOpen={open}
      size={2}
      animate
      fullScreenOnSM
      isClosable
      onCloseCb={closeModal}
    >
      <Grid>
        <PopupContent amount={props.amount} closePopup={closeModal} t={t}/>
      </Grid>
    </Modal>
  );
}
