import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import type { StyledImageProps } from './Image.style.types'

export const Image = styled.img<StyledImageProps>((props: StyledImageProps): FlattenSimpleInterpolation => {
  return css`
    ${props.aspectRatio &&
    css`
      aspect-ratio: ${props.aspectRatio};
      object-fit: cover;
    `}

    ${props.objectFit &&
    css`
      object-fit: ${props.objectFit};
    `}

    ${props.roundedCorners &&
    css`
      border-radius: ${props.theme.border.radius[2]};
    `}
  `
})
