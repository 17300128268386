import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import Interaction from '@vfuk/core-interaction'
import { spacing } from '@vfuk/core-mixins'
import { iconSizes } from '@vfuk/core-icon'

export const LogoWrapper = styled(Interaction)(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    ${spacing('margin-left', 4)};

    svg {
      ${iconSizes(5, true, 'system')}
    }
  `,
)
