import {
  phoneNumberSelector,
  propSelector,
  topUpValueSelector,
  accessTokenSelector,
  storeDetailsSelector,
  storeBucketUrlSelector,
  isOpenSelector,
  getAgentName,
} from '../selectors/selector';
import {
  topupSuccess,
  topupFailure,
  updatePhoneNumberNotFoundStatus,
  updateTopUpValue,
  updateCurrentScreen,
  setCustomerFullName,
  getBalanceDetailsRequest,
  getActivePlanDetailsRequest,
  setStoreBucket,
  updateTopupErrorSuccess,
  resetTopUp,
} from '../actions/Actions';
import { SCREENS } from '../appConstants';
import { call, debounce, put, select, takeEvery, race, take } from 'redux-saga/effects';
import {
  TOPUP_REQUEST,
  UPDATE_TOP_UP_DEFAULT_VALUE,
  VALIDATE_MSISDN_NUMBER,
  UPDATE_TOP_UP_ERROR,
  CLOSE_TOPUP,
} from '../actions/ActionType';
import { validateMSISDN } from '../helpers/APIsHelper';
import topupPhoneNumberAPI from '../services/topupAPI';
import storeBucketAPI from '../services/storeBucketAPI';
import API_RESPONSE from '../constants/APIResponse';

function* validateMSISDNNumber() {
  const msisdn = yield select(state => phoneNumberSelector(state));
  const accessToken = yield select(state => accessTokenSelector(state));
  const validationUrl = yield select(state => propSelector(state, 'validationUrl'));
  const queryParam = yield select(state => propSelector(state, 'queryParam'));
  try {
    const customerFullName = yield call(
      validateMSISDN,
      validationUrl,
      queryParam,
      msisdn,
      accessToken
    );
    yield put(setCustomerFullName(customerFullName));
    yield put(updateCurrentScreen(SCREENS.CONFIRM));
  } catch (e) {
    const { isPhoneNumberFound } = e;
    yield put(updatePhoneNumberNotFoundStatus(isPhoneNumberFound));
  }
}

function* topup() {
  const phoneNumber = yield select(phoneNumberSelector);
  const amount = yield select(topUpValueSelector);
  const storeDetails = yield select(storeDetailsSelector);
  const currencyUnit = yield select(state => propSelector(state, 'currencyUnit'));
  const baseUrl = yield select(state => propSelector(state, 'baseUrl'));
  const agentName = yield select(getAgentName);
  try {
    const payload = {
      usageType: 'MONEY',
      amount: {
        amount: Number(amount),
        units: currencyUnit,
      },
      bucket: {
        id: '',
      },
      partyAccount: {
        id: '',
      },
      product: [
        {
          id: phoneNumber,
          name: 'subscriber',
        },
      ],
      requestor: {
        role: 'username',
        '@referredType': 'Individual',
        id: agentName || '',
      },
      relatedParty: [
        {
          role: 'primaryDealerCode',
          '@referredType': 'Organization',
          id: storeDetails?.primaryDealerCode,
        },
        {
          role: 'shop',
          '@referredType': 'Organization',
          id: storeDetails?.storeId,
        },
      ],
    };
    const { res } = yield race({
      res: call(topupPhoneNumberAPI, baseUrl, payload),
      cancel: take(CLOSE_TOPUP),
    });
    if (res) {
      yield put(topupSuccess());
      yield put(getBalanceDetailsRequest());
      yield put(getActivePlanDetailsRequest());
    }
  } catch (e) {
    const isTopupModalOpen = yield select(isOpenSelector);
    if (!isTopupModalOpen) return;
    if (e?.message === API_RESPONSE.LOW_STORE_BUCKET) {
      try {
        const storeBucketURL = yield select(storeBucketUrlSelector);
        const response = yield call(
          storeBucketAPI,
          storeBucketURL.replace('{storeId}', storeDetails.storeId)
        );
        yield put(setStoreBucket(response[0]?.accountBalance[0]?.amount));
      } catch (error) {
        console.error(error);
      }
    }
    yield put(topupFailure(e?.message));
  }
}

export function* watchTopupRequest() {
  yield takeEvery(TOPUP_REQUEST, topup);
}

export function* updateTopUpDefaultValue({ payload }) {
  yield put(updateTopUpValue(payload));
}
export function* watchUpdateDefaultValue() {
  yield takeEvery(UPDATE_TOP_UP_DEFAULT_VALUE, updateTopUpDefaultValue);
}

export function* watchValidateMSISDN() {
  yield takeEvery(VALIDATE_MSISDN_NUMBER, validateMSISDNNumber);
}

export function* updateTopUpErrorSuccess({ payload }) {
  yield put(updateTopupErrorSuccess(payload));
}

export function* watchUpdateTopupError() {
  yield debounce(250, UPDATE_TOP_UP_ERROR, updateTopUpErrorSuccess);
}

export function* resetTopup() {
  yield put(resetTopUp());
}

export function* watchCloseToup() {
  yield debounce(200, CLOSE_TOPUP, resetTopup);
}

const exportedWatchers = [
  watchTopupRequest(),
  watchUpdateDefaultValue(),
  watchValidateMSISDN(),
  watchUpdateTopupError(),
  watchCloseToup(),
];

export default exportedWatchers;
