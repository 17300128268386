import { GridColumn, GridRow, Heading, InitialsAvatar, Spacing, Span } from 'r10-source-library';

import React from 'react';
import { agentInfo } from '../../selectors/selector';
import { useSelector } from 'react-redux';
import useTranslation from '../../hooks/useTranslation';

export default function LeftSection() {
  const t = useTranslation();
  const { agentName } = useSelector(agentInfo);

  return (
    <GridColumn col={12} colMd={6} marginBottom={10}>
      <GridRow>
        <InitialsAvatar initials={`${agentName?.charAt(0) || ''}`} size={4} />
        <GridColumn>
          <div data-testid="left-section">
            <Spacing spacingLevel={{ left: 4 }}>
              <GridRow marginBottom={2}>
                <Heading
                  size={1}
                  weight={3}
                  dataAttributes={{ 'data-testid': 'left-section-title' }}
                >
                  {t('agentDetails')}
                </Heading>
              </GridRow>
              <GridRow>
                <Span weight={1} dataAttributes={{ 'data-testid': 'agent-name' }}>
                  {agentName}
                </Span>
              </GridRow>
            </Spacing>
          </div>
        </GridColumn>
      </GridRow>
    </GridColumn>
  );
}
