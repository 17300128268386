import {
  COLLAPSE_TOP_UP_INPUT,
  RESET_TOP_UP,
  UPDATE_CURRENT_SCREEN,
  UPDATE_PHONE_NUMBER,
  UPDATE_PHONE_NUMBER_ERROR_STATUS,
  UPDATE_PHONE_NUMBER_NOT_FOUND_STATUS,
  UPDATE_TOP_UP_ERROR,
  UPDATE_TOP_UP_ERROR_SUCCESS,
  UPDATE_TOP_UP_VALUE,
  UPDATE_TOP_UP_MIN_VALUE,
  TOPUP_REQUEST,
  TOPUP_SUCCESS,
  TOPUP_FAILURE,
  VALIDATE_MSISDN_NUMBER,
  RESET_PHONE_NUMBER,
  SET_CUSTOMER_FULL_NAME,
  OPEN_TOPUP,
  CLOSE_TOPUP,
  UPDATE_PAYMENT_METHOD,
  GET_BALANCE_DETAILS_REQUEST,
  GET_ACTIVE_PLAN_DETAILS_REQUEST,
  GET_PROPS,
  SET_STORE_BUCKET,
  SEND_TAGS,
} from './ActionType';
import extractNumber from '../helpers/extractNumber';

export const updatePhoneNumber = phoneNumber => ({
  type: UPDATE_PHONE_NUMBER,
  payload: phoneNumber,
});

export const updateCurrentScreen = currentScreen => ({
  type: UPDATE_CURRENT_SCREEN,
  payload: currentScreen,
});

export const updatePhoneNumberErrorStatus = isError => ({
  type: UPDATE_PHONE_NUMBER_ERROR_STATUS,
  payload: isError,
});

export const updatePhoneNumberNotFoundStatus = isError => ({
  type: UPDATE_PHONE_NUMBER_NOT_FOUND_STATUS,
  payload: isError,
});

export const collapseTopUpInput = isCollapsed => ({
  type: COLLAPSE_TOP_UP_INPUT,
  payload: isCollapsed,
});

export const updateTopUpValue = topUpValue => ({
  type: UPDATE_TOP_UP_VALUE,
  payload: extractNumber(topUpValue),
});

export const updateTopUpMinValue = minValue => ({
  type: UPDATE_TOP_UP_MIN_VALUE,
  payload: extractNumber(minValue),
});

export const updateTopError = topUpHasError => ({
  type: UPDATE_TOP_UP_ERROR,
  payload: topUpHasError,
});

export const updateTopupErrorSuccess = topUpHasError => ({
  type: UPDATE_TOP_UP_ERROR_SUCCESS,
  payload: topUpHasError,
});

export const resetTopUp = () => ({
  type: RESET_TOP_UP,
});

export const resetPhoneNumber = () => ({
  type: RESET_PHONE_NUMBER,
});

export const topupSuccess = () => ({
  type: TOPUP_SUCCESS,
});

export const topupFailure = error => ({
  type: TOPUP_FAILURE,
  payload: error,
});

export const setCustomerFullName = customerFullName => ({
  type: SET_CUSTOMER_FULL_NAME,
  payload: customerFullName,
});

export const topupRequest = () => ({
  type: TOPUP_REQUEST,
});

export const validateMSISDNNumber = () => ({
  type: VALIDATE_MSISDN_NUMBER,
});

export const openTopup = () => ({
  type: OPEN_TOPUP,
});

export const closeTopup = () => ({
  type: CLOSE_TOPUP,
});

export const updatePaymentMethod = payload => ({
  type: UPDATE_PAYMENT_METHOD,
  payload,
});

export const getBalanceDetailsRequest = () => ({
  type: GET_BALANCE_DETAILS_REQUEST,
});

export const getActivePlanDetailsRequest = () => ({
  type: GET_ACTIVE_PLAN_DETAILS_REQUEST,
});

export const getProps = props => ({
  type: GET_PROPS,
  payload: { props },
});

export const setStoreBucket = payload => ({ type: SET_STORE_BUCKET, payload });

export const sendTags = payload => ({ type: SEND_TAGS, payload });
