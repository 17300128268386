import React from 'react';
import PropTypes from 'prop-types';

export const TranslationContext = React.createContext();
export const TranslationProvider = ({ t, children }) => (
  <TranslationContext.Provider value={t}>{children}</TranslationContext.Provider>
);
TranslationProvider.propTypes = {
  t: PropTypes.func,
  children: PropTypes.any,
};
