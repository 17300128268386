export const getStoresMapping = (storesInfo, primaryStoreId, selectedStore, t) => {
  const currentStore = {
    sectionTitle: t('currentStore'),
    sectionDetails: selectedStore
      ? [
          {
            addressHeader: selectedStore?.header,
            fullAddress: selectedStore?.location,
            phoneNumber: selectedStore?.phoneNumber,
            id: selectedStore?.id,
            pillTitle: selectedStore?.id === primaryStoreId ? t('primary') : undefined,
          },
        ]
      : [],
  };

  const details = storesInfo
    ?.map(
      store =>
        store.id !== selectedStore?.id && {
          addressHeader: store.header,
          fullAddress: store.location,
          phoneNumber: store.phoneNumber,
          id: store.id,
          pillTitle: store.id === primaryStoreId ? t('primary') : undefined,
        }
    )
    .filter(Boolean);

  const otherStores = {
    sectionTitle: t('otherStores'),
    sectionDetails: details,
  };
  return [currentStore, otherStores];
};
