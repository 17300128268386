import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import ListItem from '@vfuk/core-list-item'
import { InteractiveIconStyles } from '@vfuk/core-interactive-icon'

import type { StyledSearchIconProps } from './SearchIcon.style.types'

export const SearchIcon = styled(ListItem)(
  (props: StyledSearchIconProps): FlattenSimpleInterpolation => css`
    ${InteractiveIconStyles.InteractiveIcon} {
      &:hover {
        color: ${props.theme.color.primary1.hover};
      }
    }
  `,
)
