import type { TopNavigationContextProps } from '../../context/TopNavigationContext.types'

function backwardNavigation(
  navLevel: TopNavigationContextProps['navLevel'],
  navTree: TopNavigationContextProps['navTree'],
  setIsTransitioning: TopNavigationContextProps['setIsTransitioning'],
  setNavLevel: TopNavigationContextProps['setNavLevel'],
  setPreviousNav: TopNavigationContextProps['setActiveSubNav'] | TopNavigationContextProps['setActiveNav'],
  setNavTree: TopNavigationContextProps['setNavTree'],
  animationDuration: TopNavigationContextProps['animationDuration'],
): void {
  const newNavLevel = navLevel - 1
  const previousList = navTree!.find((branch) => branch.level === newNavLevel)
  const newNavTree = navTree!.slice(0, -1)

  if (!previousList) return

  setIsTransitioning(true)
  setTimeout(() => {
    setNavLevel(newNavLevel)
    setPreviousNav({
      level: newNavLevel,
      links: previousList.links!,
      parentId: previousList.parentId,
    })
    setNavTree(newNavTree)
    setIsTransitioning(false)
  }, animationDuration / 2)
}

export default backwardNavigation
