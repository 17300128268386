import React, { useEffect, useRef, useState } from 'react';
import {
  agentInfo,
  isFetching,
  isFetchingStoreBucket,
  storeBucket,
  storeDetails,
  storeId,
  error,
} from '../selectors/selector';
import { getAgentInfoRequest, getStoreBucketRequest, setProps, sendTags } from '../actions';
import { useDispatch, useSelector } from 'react-redux';

import { AddressTray } from 'r10-common-components';
import AgentInfoCard from './AgentCard/AgentInfoCard';
import Popup from './popup/Popup';
import currencyCodes from '../currencyCodes';
import { getStoresMapping } from '../utils/getTrayData';
import useTranslation from '../hooks/useTranslation';

export default function AgentBasicInfo(props) {
  const dispatch = useDispatch();
  const t = useTranslation();
  const callbackRef = useRef();
  const { primaryStoreId, storesInfo } = useSelector(agentInfo);
  const { value: bucketAmount, unit: bucketCurrency } = useSelector(storeBucket);
  const selectedStoreId = useSelector(storeId);
  const storeInfo = useSelector(storeDetails);
  const failureError = useSelector(error);

  const [selectedStore, setSelectedStore] = useState(undefined);

  const [isTrayOpen, setIsTrayOpen] = useState(false);
  const isFetchingFlag = useSelector(isFetching);
  const isFetchingStoreBucketFlag = useSelector(isFetchingStoreBucket);

  /** get Agent Data */
  useEffect(() => {
    if (primaryStoreId || isFetchingFlag) return;
    dispatch(
      getAgentInfoRequest({
        orgUrl: props.organizationUrl,
        agentPathsData: props.agentPathsData,
      })
    );
  }, []);

  useEffect(() => {
    const id = selectedStoreId || primaryStoreId;
    setSelectedStore(storesInfo?.find(store => store.id === id));
  }, [storesInfo, selectedStoreId]);

  useEffect(() => {
    if (storeInfo && selectedStore && !isFetchingStoreBucketFlag) {
      dispatch(getStoreBucketRequest());
    }
  }, [selectedStore, storeInfo]);

  const openTray = () => setIsTrayOpen(!isTrayOpen);

  const openModal = () => {
    callbackRef && callbackRef.current();
  };

  useEffect(() => {
    if (
      bucketAmount !== undefined &&
      bucketAmount !== '' &&
      selectedStore?.id &&
      bucketAmount <= props.minStoreAmount
    )
      openModal();
  }, [bucketAmount]);

  const handleAddressChange = activeAddress => {
    const activeStore = storesInfo?.find(store => store.id === activeAddress);
    setSelectedStore(activeStore);
    setIsTrayOpen(false);
    dispatch(
      setProps('storeDetails', {
        primaryDealerCode: activeStore?.primaryDealerCode,
        dealerCodes: activeStore?.dealerCodes,
        storeId: activeStore?.id,
      })
    );
  };

  useEffect(() => {
    if (failureError) {
      dispatch(
        sendTags({
          taggingId: 'AGENT_BASIC_INFO_2',
          taggingType: 'view',
          taggingValue: {
            page_error: t('errorMessage'),
          },
        })
      );
    }
  }, [failureError]);
  return (
    <div data-testid="agent-basic-info">
      <Popup
        onRef={callback => {
          callbackRef.current = callback;
        }}
        t={t}
        amount={
          bucketAmount !== undefined &&
          bucketAmount !== '' &&
          bucketAmount + currencyCodes[bucketCurrency]?.symbol
        }
      />
      <AgentInfoCard
        bucketAmount={
          bucketAmount !== undefined &&
          bucketAmount !== '' &&
          bucketAmount + currencyCodes[bucketCurrency]?.symbol
        }
        selectedStore={selectedStore}
        openTray={openTray}
        showChangeLocation={storesInfo?.length > 1}
      />
      <AddressTray
        isOpen={isTrayOpen}
        setIsOpen={setIsTrayOpen}
        data={getStoresMapping(storesInfo, primaryStoreId, selectedStore, t)}
        heading={t('selectLocation')}
        handleChange={activeAddress => handleAddressChange(activeAddress)}
        selectedRowId={selectedStore?.id}
      />
    </div>
  );
}
