import type { FC, ReactElement } from 'react'
import React, { useContext } from 'react'

import InitialsAvatar from '@vfuk/core-initials-avatar'
import PhotoAvatar from '@vfuk/core-photo-avatar'
import Icon from '@vfuk/core-icon'
import type { BaseProps } from '@vfuk/core-base-props'
import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/UserIcon.style'

import TopNavigationContext from '../../../../../../context/TopNavigationContext'
import type { TopNavigationContextProps } from '../../../../../../context/TopNavigationContext.types'
import type { Link } from '../../../../../../TopNavigation.types'

import { DataKeys } from '../../../../../../constants'

const UserIcon: FC<BaseProps> = ({ dataSelectorPrefix }): ReactElement | null => {
  const { secondaryNav, setNavLevel, setActiveNav, setNavTree, appearance, topNavigationTheme } = useContext(
    TopNavigationContext,
  ) as TopNavigationContextProps

  if (!secondaryNav?.user) return null

  const { user } = secondaryNav

  const userLinks = (): Link[] => {
    const userLinksArray: Link[] = []

    if (user.links) {
      userLinksArray.push(...user.links)
    }

    if (user.data) {
      userLinksArray.push(user.logout)
    }

    if (!user.data) {
      userLinksArray.push(user.login!)
    }

    return userLinksArray
  }

  const handleClick = (): void => {
    setNavLevel(1)
    setActiveNav({
      parentId: user.id,
      links: userLinks(),
    })
    setNavTree([
      {
        level: 1,
        parentId: user.id,
      },
    ])
  }

  const { iconAppearance, inverseIcon } = topNavigationTheme.appearance[appearance].mainBar

  return (
    <Styled.UserIcon data-selector={getDataSelector(dataSelectorPrefix)}>
      <Styled.UserLink
        onClick={handleClick}
        id={user.id}
        label={user.srText}
        data-selector={getDataSelector(dataSelectorPrefix, DataKeys.UserIcon)}
      >
        {user.data ? (
          <>
            {user.data.avatar ? (
              <PhotoAvatar image={user.data!.avatar!} dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'photo-avatar')} />
            ) : (
              <InitialsAvatar initials={user.data!.initials!} dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'initials-avatar')} />
            )}
          </>
        ) : (
          <Icon
            name='admin'
            isResponsive
            size={4}
            appearance={iconAppearance}
            inverse={inverseIcon}
            dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'icon')}
          />
        )}
      </Styled.UserLink>
    </Styled.UserIcon>
  )
}

export default UserIcon
