import React, { useEffect, useState } from 'react';

import PackageCard from './PackageCard';
import PropTypes from 'prop-types';
import ServiceCard from './ServiceCard';
import isRedirectionActive from '../utils/isRedirectionActive';
import styled from 'styled-components';
import { themeWS10 } from 'r10-source-library';

/**
 * Render the cards according to the maximum configured value
 *
 * @param {Bundle[]} bundles
 * @param {Service[]} services
 * @param {number} max
 * @param {function} cb
 */
function renderCardsAccordingMax(bundles, services, max, cb) {
  const allCards = [...bundles, ...services];

  if (allCards.length > max) {
    allCards.splice(0, allCards.length - max);

    const limitCards = allCards.reduce(
      (acc, item) => {
        if (item.isBundle) acc.bundles.push(item);
        else acc.services.push(item);

        return acc;
      },
      { bundles: [], services: [] },
    );

    cb(limitCards);
  } else cb({ bundles, services });
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1em;
  align-items: start;

  @media (max-width: ${themeWS10.breakpoints.lg}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  //TODO handle breakpoint from source library
  @media (max-width: ${themeWS10.breakpoints.md - 1}px) {
    grid-template-columns: 1fr;
  }
`;

export default function CardsWrapper(props) {
  const [cards, setCards] = useState({
    bundles: props.bundles,
    services: props.services,
  });
  /**
   * Check if the service should be shown
   * @param {string} type
   * @returns {boolean}
   */
  const serviceShouldVisible = (type) =>
    props.selectedFilters.some((filter) => filter === type || filter === 'all');

  const showPackage =
    props.selectedFilters.includes('packages') ||
    props.selectedFilters.includes('all');

  useEffect(() => {
    renderCardsAccordingMax(
      props.bundles,
      props.services,
      props.maxCardsShouldAppear,
      setCards,
    );
  }, [props.bundles, props.services, props.maxCardsShouldAppear]);

  return (
    <Wrapper>
      {showPackage &&
        cards.bundles.map((bundle) => (
          <PackageCard
            key={`bundleCard-${bundle.id}`}
            redirection={props.redirection}
            {...bundle}
          />
        ))}

      {cards.services.map(
        (service) =>
          serviceShouldVisible(service.planType) && (
            <ServiceCard
              key={`service-card-${service.id}`}
              isRedirectionActive={isRedirectionActive(
                props.redirection,
                service.planType,
              )}
              serviceURL={props.redirection.serviceURL}
              showLabel={props.showServiceLabel}
              {...service}
            />
          ),
      )}
    </Wrapper>
  );
}

CardsWrapper.propTypes = {
  maxCardsShouldAppear: PropTypes.number.isRequired,
  showServiceLabel: PropTypes.bool.isRequired,
  selectedFilters: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,

  redirection: PropTypes.shape({
    prepaidService: PropTypes.bool.isRequired,
    postpaidService: PropTypes.bool.isRequired,
    serviceURL: PropTypes.string.isRequired,
  }).isRequired,

  bundles: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      isVisible: PropTypes.bool.isRequired,
      services: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          icon: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
          planType: PropTypes.oneOf(['postpaid', 'prepaid']),
          msisdn: PropTypes.string.isRequired,
          showLabel: PropTypes.bool.isRequired,
          isVisible: PropTypes.bool.isRequired,
          isRedirectionActive: PropTypes.bool.isRequired,
          serviceURL: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,

  services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      planType: PropTypes.oneOf(['postpaid', 'prepaid', 'PrePay', 'PostPay']),
      msisdn: PropTypes.string.isRequired,
      isVisible: PropTypes.bool.isRequired,
    }),
  ).isRequired,
};
