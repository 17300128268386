import type { FC, ReactElement } from 'react'
import React, { useContext } from 'react'

import { getDataSelector } from '@vfuk/core-base-props'

import { useTheme } from 'styled-components'

import type { Theme } from '@vfuk/core-themes'
import type { InteractionEvent } from '@vfuk/core-interaction'
import type { BaseProps } from '@vfuk/core-base-props'

import * as Styled from './styles/Logo.style'

import { DataKeys } from '../../../../constants'

import TopNavigationContext from '../../../../context/TopNavigationContext'
import type { TopNavigationContextProps } from '../../../../context/TopNavigationContext.types'

const Logo: FC<BaseProps> = ({ dataSelectorPrefix }: BaseProps): ReactElement => {
  const theme = useTheme() as Theme

  const { logo, interactionHandler, appearance, topNavigationTheme } = useContext(TopNavigationContext) as TopNavigationContextProps

  const { inverseLogo } = topNavigationTheme.appearance[appearance].mainBar

  const { inverseBrandLogo, brandLogo } = theme

  const clickHandler = (event: InteractionEvent): void => {
    interactionHandler(event, DataKeys.Logo)
    if (!logo.onClick) return
    logo.onClick()
  }

  return (
    <Styled.LogoWrapper
      id={logo.id}
      label={logo.text}
      href={logo.href}
      customRouterProps={logo.customRouterProps}
      onClick={clickHandler}
      onMouseDown={logo.onMouseDown}
      onMouseUp={logo.onMouseUp}
      onTouchStart={logo.onTouchStart}
      onTouchEnd={logo.onTouchEnd}
      dataAttributes={logo.dataAttributes}
      data-selector={getDataSelector(dataSelectorPrefix)}
    >
      {inverseLogo ? inverseBrandLogo() : brandLogo()}
    </Styled.LogoWrapper>
  )
}

export default Logo
