import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { advancedSpacing, srOnly, typography } from '@vfuk/core-mixins'

import Interaction from '@vfuk/core-interaction'

import type { StyledSkipLinkProps } from './SkipLink.style.types'

export const SkipLink = styled(Interaction)(
  (props: StyledSkipLinkProps): FlattenSimpleInterpolation => css`
    ${srOnly()};

    &:focus,
    &:active {
      background-color: ${props.skipLinkTheme.appearance[props.appearance].backgroundColor};
      color: ${props.skipLinkTheme.appearance[props.appearance].color};
      ${typography.paragraph(1, false)};
      font-weight: ${props.theme.fontWeight[3]};
      clip: unset;
      clip-path: unset;
      top: 0;
      left: 0;
      width: 190px;
      height: auto;
      overflow: auto;
      ${advancedSpacing('padding', [2, 4])};
      text-align: center;
    }
  `,
)
