import { pkgName } from '../appConstants';

export const UPDATE_PHONE_NUMBER = `@${pkgName}/UPDATE_PHONE_NUMBER`;

export const UPDATE_PHONE_NUMBER_ERROR_STATUS = `@${pkgName}/UPDATE_NUMBER_ERROR_STATIS`;

export const COLLAPSE_TOP_UP_INPUT = `@${pkgName}/COLLAPSE_TOP_UP_INPUT`;

export const UPDATE_TOP_UP_VALUE = `@${pkgName}/UPDATE_TOP_UP_VALUE`;

export const UPDATE_TOP_UP_ERROR = `@${pkgName}/UPDATE_TOP_UP_ERROR`;

export const UPDATE_TOP_UP_ERROR_SUCCESS = `@${pkgName}/UPDATE_TOP_UP_ERROR_SUCCESS`;

export const UPDATE_CURRENT_SCREEN = `@${pkgName}/UPDATE_CURRENT_SCREEN`;

export const RESET_TOP_UP = `@${pkgName}/RESET_TOP_UP`;

export const RESET_PHONE_NUMBER = `@${pkgName}/RESET_PHONE_NUMBER`;

export const TOPUP_REQUEST = `@${pkgName}/TOPUP_REQUEST`;

export const TOPUP_SUCCESS = `@${pkgName}/TOPUP_SUCCESS`;

export const TOPUP_FAILURE = `@${pkgName}/TOPUP_FAILURE`;

export const UPDATE_TOP_UP_DEFAULT_VALUE = `@${pkgName}/UPDATE_TOP_UP_DEFAULT_VALUE`;

export const UPDATE_TOP_UP_MIN_VALUE = `@${pkgName}/UPDATE_TOP_UP_MIN_VALUE`;

export const VALIDATE_MSISDN_NUMBER = `@${pkgName}/VALIDATE_MSISDN_NUMBER`;

export const UPDATE_PHONE_NUMBER_NOT_FOUND_STATUS = `@${pkgName}/UPDATE_PHONE_NUMBER_NOT_FOUND_STATUS`;

export const SET_CUSTOMER_FULL_NAME = `@${pkgName}/SET_CUSTOMER_FULL_NAME`;

export const OPEN_TOPUP = `@${pkgName}/OPEN_TOPUP`;

export const CLOSE_TOPUP = `@${pkgName}/CLOSE_TOPUP`;

export const UPDATE_PAYMENT_METHOD = `@${pkgName}/UPDATE_PAYMENT_METHOD`;

export const SET_STORE_BUCKET = `@${pkgName}/SET_STORE_BUCKET`

export const GET_BALANCE_DETAILS_REQUEST = `@generic-actions/GET_BALANCE_DETAILS_REQUEST`;
export const GET_ACTIVE_PLAN_DETAILS_REQUEST=`@plans-card/GET_ACTIVE_PLAN_DETAILS_REQUEST`

export const GET_PROPS = `GET_PROPS`;

export const SEND_TAGS="SEND_TAGS";