import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { advancedSpacing, spacing } from '@vfuk/core-mixins'

export const SearchBar = styled.div(
  (): FlattenSimpleInterpolation => css`
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  `,
)

export const Search = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    align-items: center;
    ${spacing('gap', 3)};
    ${advancedSpacing('padding', [0, 4])};
    width: 100%;
  `,
)

export const SearchInputWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    flex-grow: 1;
  `,
)

export const CloseButtonWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    flex-shrink: 0;
    display: flex;
    align-items: center;
  `,
)
