import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import Interaction from '@vfuk/core-interaction'
import ListGroup from '@vfuk/core-list-group'
import ListItem from '@vfuk/core-list-item'
import { LinkWithIconStyles } from '@vfuk/core-link-with-icon'
import { advancedSpacing, spacing, typography } from '@vfuk/core-mixins'

import type { StyledSideNavigationListProps } from './SideNavigationList.style.types'

export const LinksWrapper = styled(ListGroup)(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    ${spacing('gap', 4)};
    width: 100%;
  `,
)

type ListItemProps = Pick<StyledSideNavigationListProps, 'fullWidth'>
export const LinkListItem = styled(ListItem)(
  (props: ListItemProps): FlattenSimpleInterpolation => css`
    ${props.fullWidth &&
    css`
      ${LinkWithIconStyles.LinkWithIcon} {
        width: 100%;
        justify-content: space-between;
      }
    `}
  `,
)

export const MenuLinkWrapper = styled.span(
  (): FlattenSimpleInterpolation => css`
    position: relative;
    ${typography.heading(2, false)};
  `,
)

type MenuLinkTextProps = Pick<
  StyledSideNavigationListProps,
  'theme' | 'selected' | 'animationDuration' | 'appearance' | 'topNavigationTheme'
>
export const MenuLinkText = styled.span<MenuLinkTextProps>(
  (props: MenuLinkTextProps): FlattenSimpleInterpolation => css`
    display: block;
    position: relative;
    overflow: hidden;
    ${advancedSpacing('padding', [1, 0])};
    color: ${props.topNavigationTheme.appearance[props.appearance].sideTray.level[1].textColor};

    &::before {
      transition: ${props.animationDuration / 1000}s all ease;
      content: '';
      width: 100%;
      height: ${props.theme.border.width[2]};
      background: ${props.topNavigationTheme.appearance[props.appearance].sideTray.activeBorderColor};
      display: block;
      position: absolute;
      top: calc(100% - ${props.theme.border.width[2]});
      left: 0;
      transform: translateX(-100%);
    }

    ${props.selected &&
    css`
      &::before {
        transform: translateX(0);
      }

      font-weight: ${props.theme.fontWeight[3]};
    `}
  `,
)

export const MenuLink = styled(Interaction)(
  (props: Pick<StyledSideNavigationListProps, 'theme' | 'selected'>): FlattenSimpleInterpolation => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${LinkWithIconStyles.LinkWithIcon} {
      svg {
        stroke: ${props.theme.color.monochrome6.default};
      }
    }
  `,
)

export const NumberBadgeWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    position: absolute;
    left: 100%;
    bottom: 100%;
    transform: translate(0%, 50%);
  `,
)
