import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import Interaction from '@vfuk/core-interaction'
import { advancedSpacing, spacing, typography } from '@vfuk/core-mixins'

import type { StyledTopLinkProps } from './TopLink.style.types'

import type { TopBarLink } from '../../../../../themes/TopNavigation.theme.types'

export const TopLink = styled(Interaction)<StyledTopLinkProps>((props: StyledTopLinkProps): FlattenSimpleInterpolation => {
  let topLinkAppearance = props.topNavigationTheme.appearance[props.appearance].topBar.links
  if (props.active) {
    topLinkAppearance = topLinkAppearance.active as TopBarLink
  }

  const { color, backgroundColor } = topLinkAppearance

  return css`
    color: ${color};
    background-color: ${backgroundColor};
    ${advancedSpacing('padding', [2, 4])};
    ${typography.paragraph(1, false)};
    display: flex;
    align-items: center;
    ${spacing('gap', 2)};

    ${props.active &&
    css`
      font-weight: ${props.theme.fontWeight[3]};
    `}
  `
})
