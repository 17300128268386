import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Picker } from 'r10-source-library';

import {
  collapseTopUpInput,
  sendTags,
  updateTopError,
  updateTopUpValue,
} from '../../../../actions/Actions';
import useTranslation from '../../../../hooks/useTranslation';
import {
  minTopUpValueSelector,
  propSelector,
  topUpValueSelector,
} from '../../../../selectors/selector';

const roundUpSelectedValue = (selectedValue, step) =>
  selectedValue % step === 0 ? selectedValue : step * Math.ceil(selectedValue / step);

const AmountButtons = ({
  commonTopUpValues,
  currency,
  updTopUpValue,
  topUpValue,
  updateIsCollapsed,
  defaultTopUpValue,
  removeTopUpValueErrorStatus,
  isFastTopupFlow,
  step,
  minTopUpValue,
  minValue,
  sendTags,
}) => {
  const t = useTranslation();
  const [selected, setSelected] = useState();
  const actualValue = roundUpSelectedValue(topUpValue, step);

  const onClickButton = value => {
    setSelected(value);

    if (value) {
      updTopUpValue(value);
      updateIsCollapsed(true);
      removeTopUpValueErrorStatus();
    } else {
      updTopUpValue(0);
      updateIsCollapsed(false);
    }
  };

  useEffect(() => {
    if (topUpValue) {
      if (commonTopUpValues.includes(actualValue)) {
        onClickButton(actualValue, false);
      } else {
        updateIsCollapsed(false);
        setSelected(0);
        updTopUpValue(actualValue);
      }
    } else {
      if (minValue && commonTopUpValues.includes(minValue)) {
        onClickButton(minValue, false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minTopUpValue]);

  const pickerItems = commonTopUpValues
    .map(value => ({ title: `${value}${currency}`, value }))
    .concat({ title: t('bodyTopupButtonsOther'), value: 0 });

  return (
    <Picker
      columns={{ sm: 2 }}
      items={pickerItems}
      selected={selected}
      dataSelectorPrefix="amount-button"
      dataAttributes={{
        'data-testid': 'amount-button',
      }}
      onChange={event => onClickButton(+event.target.value)}
    />
  );
};
const mapStateToProps = state => ({
  topUpValue: topUpValueSelector(state),
  defaultTopUpValue: propSelector(state, 'defaultTopUpValue'),
  minTopUpValue: minTopUpValueSelector(state),
});
const mapDispatchToProps = dispatch => ({
  updTopUpValue: value => dispatch(updateTopUpValue(value)),
  updateIsCollapsed: isCollapsed => dispatch(collapseTopUpInput(isCollapsed)),
  removeTopUpValueErrorStatus: () => dispatch(updateTopError(null)),
  sendTags: tagsObject => dispatch(sendTags(tagsObject)),
});

AmountButtons.propTypes = {
  commonTopUpValues: PropTypes.array,
  currency: PropTypes.string,
  updTopUpValue: PropTypes.func,
  topUpValue: PropTypes.number,
  updateIsCollapsed: PropTypes.func,
  removeTopUpValueErrorStatus: PropTypes.func,
  defaultTopUpValue: PropTypes.number,
  minTopUpValue: PropTypes.number,
  step: PropTypes.number,
  isFastTopupFlow: PropTypes.bool,
  isOtherValueInputOpen: PropTypes.bool,
  minValue: PropTypes.number,
  sendTags: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(AmountButtons);
