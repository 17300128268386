import focusOnElement from '../focusOnElement'

import type { TopNavigationContextProps } from '../../context/TopNavigationContext.types'

function overlayClickHandler(
  logo: TopNavigationContextProps['logo'],
  setShowSearch: TopNavigationContextProps['setShowSearch'],
  setNavLevel: TopNavigationContextProps['setNavLevel'],
): void {
  setShowSearch(false)
  setNavLevel(0)
  focusOnElement(logo.id)
}

export default overlayClickHandler
