import type { InteractionProps } from '@vfuk/core-interaction'

import type { Link } from '../../TopNavigation.types'

function registerAriaTree(link: Link): InteractionProps['dataAttributes'] {
  if (link.links) {
    return {
      'aria-haspopup': 'tree',
      ...link.dataAttributes,
    }
  }

  return link.dataAttributes
}

export default registerAriaTree
