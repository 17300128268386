import type { FC, ReactElement, ReactNode } from 'react'
import React, { useMemo } from 'react'
import { useTheme } from 'styled-components'

import type { Theme } from '@vfuk/core-themes'
import MatchMedia from '@vfuk/core-match-media'
import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/Image.style'

import type { ImageProps, Breakpoint } from './Image.types'

import getBreakpointFallback from './helpers/getBreakpointFallback'
import getFallbackImage from './helpers/getFallbackImage'
import getSource from './helpers/getSource'
import { breakpoints } from './utils/breakpoints'

const Image: FC<ImageProps> = (props: ImageProps): ReactElement => {
  const componentName = 'Image'
  const theme = useTheme() as Theme
  const fallbackImageObj = useMemo(() => getFallbackImage(props), [props])

  return (
    <picture
      {...props.dataAttributes}
      data-selector={getDataSelector(props.dataSelectorPrefix)}
      id={props.id}
      data-component-name={componentName}
      onLoad={props.onLoad}
      onError={props.onError}
    >
      {breakpoints.map((breakpoint: Breakpoint, index: number): ReactNode => {
        const srcSet = getSource(breakpoint, props)
        if (!srcSet) return

        return <source media={`(min-width: ${theme.breakpoints[breakpoint]}px)`} srcSet={srcSet} key={index} />
      })}

      {/* fallbackImageObj can be an object OR undefined (based on the function that grabs it) */}
      {fallbackImageObj &&
        breakpoints.map((breakpoint: Breakpoint, index: number): ReactNode => {
          return (
            <MatchMedia key={index} breakpoint={breakpoint}>
              <Styled.Image
                roundedCorners={props.roundedCorners}
                aspectRatio={props.aspectRatio}
                objectFit={props.objectFit}
                title={props.title}
                alt={props.alt}
                {...getBreakpointFallback(breakpoint, props)}
                data-selector={getDataSelector(props.dataSelectorPrefix, 'image')}
              />
            </MatchMedia>
          )
        })}
    </picture>
  )
}

export default Image
