import type { FC } from 'react'
import React from 'react'
import { useTheme } from 'styled-components'

import type { Theme } from '@vfuk/core-themes'
import Paragraph from '@vfuk/core-paragraph'
import Image from '@vfuk/core-image'
import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/SideNavigationCard.style'

import type { SideNavigationCardProps } from './SideNavigationCard.types'

import localThemes from './themes/SideNavigationCard.theme'

const SideNavigationCard: FC<SideNavigationCardProps> = ({
  text,
  appearance = 'primary',
  image,
  id,
  srText,
  href,
  onClick,
  customRouterProps,
  dataAttributes,
  dataSelectorPrefix,
}) => {
  const theme = useTheme() as Theme
  const isTextInverse = localThemes(theme).appearance[appearance].inverseText

  return (
    <Styled.SideNavigationCard
      id={id}
      href={href}
      onClick={onClick}
      label={srText}
      customRouterProps={customRouterProps}
      dataAttributes={dataAttributes}
      appearance={appearance}
      data-selector={getDataSelector(dataSelectorPrefix)}
    >
      <Styled.TextWrapper>
        <Paragraph inverse={isTextInverse} size={1} dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'paragraph')}>
          {text}
        </Paragraph>
      </Styled.TextWrapper>
      <Styled.ImageWrapper>
        <Image {...image} aspectRatio='1/1' dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'image')} />
      </Styled.ImageWrapper>
    </Styled.SideNavigationCard>
  )
}

export default SideNavigationCard
